<template>
  <div class="grid grid-cols-2 gap-10 pb-10" data-cy="email-templates-tiles">
    <email-templates-tile
      v-for="template in data"
      :key="template.id"
      :template="template"
      :select="select"
      :selected-ids="selectedIds"
      @tile:select="handleTileSelect"
    />
  </div>
</template>

<script setup lang="ts">
import type { EmailTemplate } from "@/models/clients";
import type { PropType } from "vue";

import EmailTemplatesTile from "@/components/emailTemplates/EmailTemplatesTile.vue";

const emit = defineEmits<{
  (
    eventName: "update:selected-ids",
    newSelectedIds: EmailTemplate["id"][]
  ): void;
}>();

const props = defineProps({
  data: {
    type: Array as PropType<EmailTemplate[]>,
    required: true
  },
  select: {
    type: Boolean,
    default: false
  },
  selectedIds: {
    type: Array as PropType<EmailTemplate["id"][]>,
    default: () => []
  }
});

const handleTileSelect = (id: EmailTemplate["id"]) => {
  const selected = [...props.selectedIds];
  if (selected.includes(id)) {
    selected.splice(selected.indexOf(id), 1);
  } else {
    selected.push(id);
  }
  emit("update:selected-ids", selected);
};
</script>

import { defineStore } from "pinia";
import contractBuilderService from "@/services/modules/contractBuilder";
import type {
  ContractBuilderState,
  ContractTemplate,
  ContractRecipient,
  GetContractTemplateParams,
  ContractBuilderViewParams,
  ContractFieldWithName
} from "@/models/contractBuilder";
import type { IResponseLinks, IResponseMeta } from "@/models/common";

const getDefaultState = (): ContractBuilderState => ({
  placeholders: [],
  gettingPlaceholders: false,
  gettingTemplates: false,
  templates: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeTemplate: null
});

export const useContractBuilderStore = defineStore("contractBuilder", {
  state: getDefaultState,
  actions: {
    async getPlaceholders() {
      if (this.gettingPlaceholders) {
        return;
      }
      try {
        this.gettingPlaceholders = true;
        this.placeholders = await contractBuilderService.getPlaceholders();
      } finally {
        this.gettingPlaceholders = false;
      }
    },

    async deleteContract(id: string) {
      await contractBuilderService.deleteContract(id);
    },

    async createContract(payload: Partial<ContractTemplate>) {
      return await contractBuilderService.createContract(payload);
    },

    async updateContract(payload: Partial<ContractTemplate>) {
      return await contractBuilderService.updateContract(payload);
    },

    async getContractTemplates(payload: ContractBuilderViewParams) {
      if (this.gettingTemplates) {
        return;
      }
      const dataPayload: ContractBuilderViewParams = {
        page: 1,
        per_page: 100,
        ...payload
      };

      try {
        this.gettingTemplates = true;
        this.templates =
          await contractBuilderService.getContractTemplates(dataPayload);
      } finally {
        this.gettingTemplates = false;
      }
    },

    async getContractTemplate(id: string, params: GetContractTemplateParams) {
      const template = await contractBuilderService.getContractTemplate(
        id,
        params
      );
      this.activeTemplate = template;
      return template;
    },

    async createContractTemplate(payload: Partial<ContractTemplate>) {
      this.activeTemplate =
        await contractBuilderService.createContractTemplate(payload);
    },

    async updateContractTemplate(payload: Partial<ContractTemplate>) {
      await contractBuilderService.updateContractTemplate(payload);
    },
    updateContractTemplateFields(payload: ContractFieldWithName) {
      if (!this.activeTemplate) {
        return;
      }
      const recipient = this.activeTemplate.content?.recipients.find(
        (recipient) => recipient.id === payload.role?.id
      );
      if (!recipient) {
        return;
      }
      const fieldIndex = recipient.fields.findIndex(
        (field) => field.id === payload.id
      );
      if (fieldIndex === -1) {
        recipient.fields.push(payload);
      } else {
        recipient.fields[fieldIndex] = payload;
      }
    },
    updateContractTemplateRecipients(payload: ContractRecipient[]) {
      if (!this.activeTemplate?.content?.recipients) {
        return;
      }
      this.activeTemplate.content.recipients = payload;
    },
    resetContractTemplates() {
      this.templates = getDefaultState().templates;
    }
  }
});

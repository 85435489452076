import type { EmailTemplate } from "@/models/clients";

const attributesToRemove = [
  "data-denotation-char",
  "data-index",
  "data-name",
  "data-tag",
  "data-value",
  "data-background-color"
];

const cleanTag = (tag: Element) => {
  const shouldCleanTag =
    tag.tagName.toLowerCase() === "span" &&
    tag.hasAttribute("data-denotation-char");
  if (shouldCleanTag) {
    attributesToRemove.forEach((attr) => tag.removeAttribute(attr));
  }
};

export const removeDenotation = (denotationElement: Element) =>
  denotationElement.remove();

export const removeAttributesFromPayload = (htmlString: string | undefined) => {
  if (!htmlString) {
    return "";
  }
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  Array.from(doc.body.getElementsByTagName("*")).forEach((el) => {
    if (el.classList.contains("ql-mention-denotation-char")) {
      removeDenotation(el);
      return;
    }
    cleanTag(el);
  });

  return doc.body.innerHTML;
};

export const removeExtraPropertiesFromPayload = (
  payload: Partial<Omit<EmailTemplate, "user" | "client">> & {
    client_id?: string;
    dynamic_field_dropdown?: string;
    search?: string;
  }
) => {
  if (payload.dynamic_field_dropdown || payload.dynamic_field_dropdown === "") {
    delete payload.dynamic_field_dropdown;
  }

  if (payload.search || payload.search === "") {
    delete payload.search;
  }
};

export const zeroWidthSpaceCharRegex = /[\u200B-\u200D\uFEFF]/g;

import capitalize from "lodash/capitalize";

export const SMS_ACTIVITY_STATUSES = [
  "delivered",
  "failed",
  "received"
] as const;

export const smsActivityStatusOptions = SMS_ACTIVITY_STATUSES.reduce<
  Record<string, string>
>((result, status) => {
  result[status] = capitalize(status);
  return result;
}, {});

export const SMS_ACTIVITY_TYPES = ["outgoing", "incoming"] as const;

export const smsActivityTypeOptions = SMS_ACTIVITY_TYPES.reduce<
  Record<string, string>
>((result, status) => {
  result[status] = capitalize(status);
  return result;
}, {});

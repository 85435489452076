<template>
  <email-filter
    :options="emailActivityTypeOptions"
    :title="t('COMMON.TYPE')"
    placeholder-key="ACTIVITY.FILTERS.TYPE"
    :render-component="EmailTypeOption"
    query-key="is_outgoing"
  />
</template>
<script setup lang="ts">
import EmailFilter from "@/components/communicationLogs/filters/emailFilters/EmailFilter.vue";
import EmailTypeOption from "@/components/communicationLogs/filters/emailFilters/EmailTypeOption.vue";
import { useI18n } from "vue-i18n";
import { emailActivityTypeOptions } from "@/helpers/constants/emails";

const { t } = useI18n();
</script>

<template>
  <path
    d="M11.9226 3.44922L4.17383 12.417"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
  />
  <circle
    cx="8"
    cy="8"
    r="6"
    stroke="currentColor"
    stroke-width="2"
    fill="none"
  />
</template>

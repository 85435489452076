<template>
  <div class="flex w-full justify-between items-center">
    <span class="text-headline">
      {{ emailAutomationOptions[modelValue] }}
    </span>
    <icon-base v-if="isSelected" :icon="IconSent" class="text-blue-500" />
  </div>
</template>
<script setup lang="ts">
import IconSent from "@/components/icons/email/IconSent.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed } from "vue";
import { emailAutomationOptions } from "@/helpers/constants/emails";

interface Props {
  modelValue: "0" | "1";
  selected: string[];
}

const props = defineProps<Props>();

const isSelected = computed(() => !!props.selected?.includes(props.modelValue));
</script>

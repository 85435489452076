<template>
  <path
    d="M1.75 5.30773L5.31066 1.74707H10.6893L14.25 5.30773V10.6864L10.6893 14.2471H5.31066L1.75 10.6864V5.30773Z"
    stroke="currentColor"
    stroke-width="1.5"
    fill="none"
  />
  <path
    d="M7 4.99707L7 8.5C7 9.05228 7.44771 9.5 8 9.5C8.55228 9.5 9 9.05229 9 8.5L9 4.99707C9 4.44479 8.55228 3.99707 8 3.99707C7.44772 3.99707 7 4.44479 7 4.99707Z"
    fill="currentColor"
  />
  <path
    d="M9 10.9971C9 10.4448 8.55228 9.99707 8 9.99707C7.44772 9.99707 7 10.4448 7 10.9971C7 11.5494 7.44772 11.9971 8 11.9971C8.55228 11.9971 9 11.5494 9 10.9971Z"
    fill="currentColor"
  />
</template>

<template>
  <form
    :class="{ 'p-4 overflow-y-auto max-h-[calc(100%-53px)]': isModal }"
    @submit="submitForm"
  >
    <loader :is-loading="loading" />
    <div class="space-y-6">
      <lf-input
        data-cy="description-input"
        :placeholder="$t('ACTIVITY.TASK.TASK_DESCRIPTION')"
        name="description"
      />
      <lf-dropdown-with-api-search
        v-if="isActivityHubPage"
        :placeholder="$t('ACTIVITY.TASK.TO_DEAL')"
        :get-paginated-options="getApplications"
        name="application_id"
        accessor="business.business_legal_name"
        full-width
        show-clear-filter
        data-cy="deal-dropdown"
      >
        <template #option="{ option, options, isSelected }">
          <filter-by-deals-option
            class="px-2"
            :deal="getDeal(option, options)"
            :is-selected="isSelected"
          />
        </template>
      </lf-dropdown-with-api-search>
      <date-time-input
        data-cy="dueAt-input"
        class="datetime h-11-5"
        name="due_at"
        :placeholder="$t('ACTIVITY.TASK.DATE_AND_TIME')"
      />
      <lf-priority-selection v-model="selectedPriority" />
      <lf-user-selection
        :placeholder="$t('COMMON.ASSIGNEE')"
        name="assignee_id"
        :get-paginated-options="getUsersByRole"
      />
      <primary-button
        data-cy="submit-button"
        type="submit"
        class="w-full"
        :disabled="isSubmitting || formNotFilledIn"
      >
        {{ $t("ACTIVITY.TASK.ADD_TASK") }}
      </primary-button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useForm } from "vee-validate";
import { useRoute } from "vue-router";
import isEmpty from "lodash/isEmpty";
import { useClients } from "@/hooks/clients";
import { usePromiseWrapper } from "@/hooks/common";
import { useCommunicationStore } from "@/stores/communication";
import applicationsApiService from "@/services/modules/applications";
import usersApiService from "@/services/modules/users";
import LfDropdownWithApiSearch from "@/components/ui/inputs/LfDropdownWithApiSearch.vue";
import LfUserSelection from "@/components/ui/inputs/LfUserSelection.vue";
import LfPrioritySelection from "@/components/ui/LfPrioritySelection.vue";
import { TaskPriorityLevels } from "@/enums/communicationLogs";
import { useRouteQuery } from "@vueuse/router";
import FilterByDealsOption from "@/components/communicationLogs/filters/FilterByDealsOption.vue";
import { useDeals } from "@/hooks/deals";
import type {
  CommunicationFilters,
  CreateTaskPayload
} from "@/models/communicationLogs";
import type { IApplication } from "@/models/applications";
import { useCommunicationLogs } from "@/hooks/communicationLogs";

defineProps<{
  isModal?: boolean;
}>();

const { createTask, getCommunicationList } = useCommunicationStore();
const { authClientSettings: client } = useClients();
const { activeDeal } = useDeals();
const route = useRoute();
const { isActivityHubPage } = useCommunicationLogs();
const typeQuery = useRouteQuery("type");

const selectedPriority = ref(TaskPriorityLevels.medium);

const { handleSubmit, isSubmitting, resetForm, values } =
  useForm<CreateTaskPayload>({
    initialValues: {
      description: "",
      due_at: "",
      assignee_id: "",
      application_id: "",
      priority_level: selectedPriority.value
    }
  });

const formNotFilledIn = computed(() => {
  return (
    !values.description ||
    !values.due_at ||
    (!values.application_id && isActivityHubPage.value)
  );
});

const isDataApplication = (data: unknown[]): data is IApplication[] => {
  return (
    !!data.length &&
    (data[0] as IApplication)?.client_tracking_token !== undefined
  );
};

const getDeal = (option: string, options: unknown[]) => {
  if (isDataApplication(options)) {
    return options.find((o) => o.business?.business_legal_name === option);
  }
  return null;
};

const getApplications = (params: Record<string, unknown> = {}) =>
  applicationsApiService.getApplications({ ...params, basics_only: 1 });

const getUsersByRole = (params: Record<string, string | number> = {}) => {
  const isAClient = !isEmpty(client.value);
  if (isAClient) {
    return usersApiService.getClientUsers(client.value.id, {
      ...params,
      with_preset: 0
    });
  }
  return usersApiService.getEmployees({ ...params, with_preset: 0 });
};

const submit = handleSubmit(async (values) => {
  values.priority_level = selectedPriority.value;
  if (!isActivityHubPage.value) {
    values.application_id = activeDeal.value.id;
  }

  if (values.due_at) {
    values.due_at = new Date(values.due_at).toISOString();
  }

  try {
    await createTask(values);
    if (typeQuery.value === "task") {
      await getCommunicationList(route.query as Partial<CommunicationFilters>);
    } else {
      typeQuery.value = "task";
    }
    selectedPriority.value = TaskPriorityLevels.medium;
    resetForm();
  } catch (error) {
    return error;
  }
});

const { fetchWrapper: submitForm, loading } = usePromiseWrapper(submit);
</script>

<style>
.datetime > input[type="date"]::-webkit-calendar-picker-indicator {
  @apply opacity-40;
}
.datetime input[type="date"]::-webkit-calendar-picker-indicator,
.datetime > input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  @apply opacity-40;
}

.icon-highest {
  @apply text-red-500;
}

.icon-high {
  @apply text-orange-500;
}

.icon-medium {
  @apply text-yellow-500;
}

.icon-low {
  @apply text-primary;
}

.icon-lowest {
  @apply text-blue-500;
}
</style>

<template>
  <email-filter
    :options="filterProps.options"
    :title="t('EMAIL_INTEGRATION.FILTERS.ACTIVITY_STATUS')"
    placeholder-key="ACTIVITY.FILTERS.STATUS"
    :render-component="filterProps.renderComponent"
    :query-key="filterProps.queryKey"
    :is-multiple="filterProps.isMultiple"
  />
</template>
<script setup lang="ts">
import { computed } from "vue";
import EmailFilter from "@/components/communicationLogs/filters/emailFilters/EmailFilter.vue";
import EmailStatusOption from "@/components/communicationLogs/filters/emailFilters/EmailStatusOption.vue";
import SmsStatusOption from "@/components/communicationLogs/filters/smsFilters/SmsStatusOption.vue";

import { useI18n } from "vue-i18n";
import { emailActivityStatusOptions } from "@/helpers/constants/emails";
import { smsActivityStatusOptions } from "@/helpers/constants/sms";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    mode?: "email" | "sms";
  }>(),
  {
    mode: "email"
  }
);

const filterProps = computed(() => {
  if (props.mode === "sms") {
    return {
      options: smsActivityStatusOptions,
      renderComponent: SmsStatusOption,
      queryKey: "sms_statuses[]",
      isMultiple: true
    };
  }
  return {
    options: emailActivityStatusOptions,
    renderComponent: EmailStatusOption,
    queryKey: "activity_status",
    isMultiple: false
  };
});
</script>

import store from "@/store";
import { formatString } from "@/helpers/formatting";
import type { IFile, IFilePayload, IPaginatedResponse } from "@/models/common";
import type {
  IAdvancedCfaPayload,
  IApplication,
  IApplicationDealMode,
  IApplicationMetrics,
  IApplicationTemporaryLinks,
  IBankAccount,
  IBusiness,
  ICommercialData,
  IDataOrchestrationLog,
  IDiscoveryCallAnswer,
  IDiscoveryCallQuestion,
  IDocumentVerification,
  IEquipmentRentalApproval,
  IFilterSort,
  IFilterVisibility,
  ISendStipsToFunderPayload,
  IStip,
  ITransaction,
  ITransactionPayload,
  ISmartFilter,
  ISystemPreferences,
  IDealVisibilityUpdate,
  IBankData,
  IApplicationAttribute,
  WidgetInfo,
  UpdateUserRolesPayload,
  UpdateDealModePayload,
  StipPayload,
  ApplicationStatusPayload,
  UpdateServiceJsonPayload,
  WorkflowSnapshot,
  PhoneValidationResponse
} from "@/models/applications";
import type { PlaidData } from "@/models/applicationData/plaid";
import type {
  OcrolusAdvancedAnalyticsData,
  OcrolusAdvancedAnalyticsMeta
} from "@/models/commercialData/Ocrolus";
import type { IOwner } from "@/models/users";
import type {
  IEquipmentOffer,
  ILenderPlacement,
  IOffer
} from "@/models/funders";
import type { IOrchestrationTemplate } from "@/models/orchestration";
import type { IClientPreference } from "@/models/clients";
import { axiosClient } from "../client";
import endpoints from "../endpoints";
import { ScorecardType } from "@/enums/scorecards";
import type { IScorecardResult } from "@/models/scorecards";
import { sendRequestWithCancellation } from "@/helpers/deals";
import type { OfferNote } from "@/models/offers";
import type { ContractTemplateV2 } from "@/models/contractBuilder";

class ApplicationsApiService {
  async getApplications(queryParams: Record<string, unknown> = {}) {
    const response = await sendRequestWithCancellation<
      IPaginatedResponse<IApplication>
    >({
      httpMethod: "get",
      baseUrl: endpoints.APPLICATIONS.GET_APPLICATIONS_INDEXED,
      requestConfig: { params: queryParams }
    });

    return response;
  }

  async getApplication(appId: string) {
    const response = await sendRequestWithCancellation<{
      data: IApplication;
    }>(
      {
        httpMethod: "get",
        baseUrl: endpoints.APPLICATIONS.SINGLE_APPLICATION,
        urlModifiers: [appId],
        requestConfig: { params: { with_underwriting: 0 } }
      },
      appId
    );

    return response.data;
  }

  async assignUser(appId: string, data: Record<string, number | null>) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.UPDATE_APP_INFO, appId),
      data
    );
  }

  async enrichApp(appId: string, payload: Record<string, unknown>) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.ENRICH_APP, appId),
      payload
    );
  }

  async enrichBCApp(appId: string, payload: Record<string, unknown>) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.ENRICH_BUSINESS_CREDIT_APP, appId),
      payload
    );
  }

  async validatePhoneNumbers(
    appId: string,
    payload: { numbers: string[]; service: string }
  ) {
    await axiosClient.post(
      formatString(endpoints.APPLICATIONS.VALIDATE_PHONES, appId),
      payload
    );
  }

  async getValidatedPhoneNumbers(appId: string) {
    const response = await axiosClient.get<{
      data: PhoneValidationResponse[];
    }>(formatString(endpoints.APPLICATIONS.VALIDATE_PHONES, appId));
    return response.data;
  }

  async deadApplication(
    applicationId: string,
    payload: Record<string, unknown>
  ) {
    const data = payload.sub_reason
      ? { ...payload }
      : {
          reason: payload.reason,
          notes: payload.notes,
          send_email: !!payload.send_email
        };
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.DEAD, applicationId),
      data
    );
  }

  async sendDeadEmail(applicationId: string) {
    const response = await axiosClient.post<{ data: { sent: boolean } }>(
      formatString(endpoints.APPLICATIONS.SEND_DEAD_EMAIL, applicationId)
    );
    return response.data.data;
  }

  async reviveApplication(applicationId: string) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.REVIVE, applicationId)
    );
  }

  async updateAppInfo(data: Record<string, unknown>, applicationId: string) {
    const response = await axiosClient.put<{ data: IApplication }>(
      formatString(endpoints.APPLICATIONS.UPDATE_APP_INFO, applicationId),
      data
    );
    return response.data.data;
  }

  async updateBusinessInfo(data: Partial<IBusiness>, applicationId: string) {
    const response = await axiosClient.put<{ data: IBusiness }>(
      formatString(endpoints.APPLICATIONS.UPDATE_BUSINESS_INFO, applicationId),
      data
    );
    return response.data.data;
  }

  async updateBusinessInfoForDeal(
    data: Partial<IApplication>,
    applicationId: string
  ) {
    const response = await axiosClient.put<{ data: IBusiness }>(
      formatString(endpoints.APPLICATIONS.UPDATE_BUSINESS_INFO, applicationId),
      data
    );
    return response.data.data;
  }

  async updateEquipmentShareUse(
    applicationId: string,
    data: Partial<IEquipmentRentalApproval>
  ) {
    const response = await axiosClient.put<{ data: IEquipmentRentalApproval }>(
      formatString(
        endpoints.APPLICATIONS.EQUIPMENT_RENTAL_APPROVAL,
        applicationId
      ),
      data
    );
    return response.data.data;
  }

  async uploadApplicationFile(
    applicationId: string,
    data: IFilePayload,
    additionalConfig: Record<string, unknown> = {}
  ) {
    const formData = new FormData();
    Object.keys(data).forEach((key) =>
      formData.append(key, data[key as keyof IFilePayload] as string | Blob)
    );

    const response = await axiosClient.post<{ data: IFile }>(
      formatString(endpoints.APPLICATIONS.FILES.SINGLE, applicationId),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },

        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async uploadMultipleApplicationFiles(
    applicationId: string,
    data: IFilePayload[],
    additionalConfig: Record<string, unknown> = {}
  ) {
    const formData = new FormData();

    data.forEach((file, i) => {
      Object.keys(file).forEach((key) =>
        formData.append(
          `files[${i}][${key}]`,
          file[key as keyof IFilePayload] as string | Blob
        )
      );
    });

    const response = await axiosClient.post<{ data: IFile[] }>(
      formatString(endpoints.APPLICATIONS.FILES.MULTIPLE, applicationId),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },

        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async updatePersonalInfo(
    data: Partial<IOwner>,
    applicationId: string,
    ownerId: number
  ) {
    const response = await axiosClient.put<{ data: IOwner }>(
      formatString(
        endpoints.APPLICATIONS.UPDATE_PERSONAL_INFO,
        applicationId,
        ownerId
      ),
      data
    );
    return response.data.data;
  }

  async getOffers(applicationId: string) {
    const response = await axiosClient.get<{ data: IOffer[] }>(
      formatString(endpoints.APPLICATIONS.APP_OFFERS, applicationId)
    );
    return response.data.data;
  }

  async getOffer(offerId: string) {
    const response = await axiosClient.get<{ data: IOffer }>(
      formatString(endpoints.OFFERS.SINGLE_OFFER, offerId)
    );
    return response.data.data;
  }

  async saveOffer(data: Partial<IOffer>, applicationId: string) {
    const response = await axiosClient.post<{ data: IOffer }>(
      formatString(endpoints.APPLICATIONS.APP_OFFERS, applicationId),
      data
    );
    return response.data.data;
  }

  async saveSelfFundedOffer(data: Partial<IOffer>, applicationId: string) {
    const response = await axiosClient.post<{ data: IOffer }>(
      formatString(endpoints.OFFERS.CLIENT_FUNDED_OFFER, applicationId),
      data
    );
    return response.data.data;
  }

  async updateOffer(data: Partial<IOffer>, applicationId: string) {
    const response = await axiosClient.put<{ data: IOffer }>(
      formatString(
        endpoints.APPLICATIONS.UPDATE_OFFERS,
        applicationId,
        data.id ?? ""
      ),
      data
    );
    return response.data.data;
  }

  async deleteOffer(applicationId: string, id: string) {
    await axiosClient.delete(
      formatString(endpoints.OFFERS.DELETE_OFFER, applicationId, id)
    );
  }

  async updateDiscoveryCallAnswers(id: string, data: unknown) {
    const response = await axiosClient.put<{ data: IDiscoveryCallAnswer[] }>(
      formatString(endpoints.APPLICATIONS.UPDATE_DISCOVERY_CALL_ANSWERS, id),
      data
    );
    return response.data.data;
  }

  async createDiscoveryCallQuestions(
    appId: string,
    data: IDiscoveryCallQuestion
  ) {
    const response = await axiosClient.post(
      formatString(endpoints.APPLICATIONS.DISCOVERY_CALL_QUESTION, appId),
      data
    );
    return response.data.data;
  }

  async deleteDiscoveryCallQuestion(appId: string, questionId: string) {
    await axiosClient.delete(
      formatString(
        endpoints.APPLICATIONS.UPDATE_DISCOVERY_CALL_QUESTION,
        appId,
        questionId
      )
    );
  }

  async getCommercialData(id: string) {
    const response = await sendRequestWithCancellation<{
      data: ICommercialData;
    }>(
      {
        httpMethod: "get",
        baseUrl: endpoints.APPLICATIONS.COMMERCIAL_DATA,
        urlModifiers: [id]
      },
      id
    );
    return response.data;
  }

  async generateDocuSignLink(
    appId: string,
    payload: Partial<IApplicationTemporaryLinks>
  ) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      consent: true,
      notify: true,
      ...payload
    });
    return response.data.data.consent_link;
  }

  async getBankStatementLink(appID: string) {
    const response = await axiosClient.get<{
      data: { bank_statements_link: string };
    }>(formatString(endpoints.APPLICATIONS.GET_LINK, appID), {
      params: { bank_statements: 1 }
    });
    return response.data.data.bank_statements_link;
  }

  async generateApplicantInfoWithDocuSignLink(
    appId: string,
    payload: Partial<IApplicationTemporaryLinks>
  ) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      applicant_consent: true,
      ...payload
    });
    return response.data.data.applicant_consent_link;
  }

  async generatePlaidLink(appId: string) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      plaid: true,
      notify: true
    });
    return response.data.data.plaid_link;
  }

  async generateRailzWidgetLink(appId: string) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      railz: true
    });
    return response.data.data.railz_link;
  }

  async generateCodatWidgetLink(appId: string) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      codat: true
    });
    return response.data.data.codat_link;
  }

  async generatePersonalInfoLink(appId: string, ownerId: number) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      personal_information: true,
      owner: ownerId
    });
    return response.data.data.personal_information_link;
  }

  async generateBorrowerPlatformDealLink(appId: string, ownerId: number) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      borrower_platform: true,
      owner: ownerId
    });
    return response.data.data.borrower_platform;
  }

  async deleteApplication(id: string) {
    await axiosClient.delete(
      formatString(endpoints.APPLICATIONS.SINGLE_APPLICATION, id)
    );
  }

  async updateAppPiis(appId: string, data: { tax_exempt: number }) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.UPDATE_PIIS, appId),
      data
    );
  }

  async updateBankAccountsVisibility(appId: string, data: IBankAccount[]) {
    await axiosClient.put(
      formatString(
        endpoints.APPLICATIONS.BANK_ACCOUNTS.UPDATE_VISIBILITY,
        appId
      ),
      data
    );
  }

  async getDealMetrics(appId: string) {
    const response = await axiosClient.get<{ data: IApplicationMetrics }>(
      formatString(endpoints.APPLICATIONS.METRICS, appId)
    );
    return response.data.data;
  }

  async deleteFile(id: number) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.FILES.SINGLE, id)
    );
    return response;
  }

  async deleteFiles(params: { ids: number[] }) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.FILES.ALL),
      { params }
    );
    return response;
  }

  async updateFile(id: number, data: Partial<IFile>) {
    const response = await axiosClient.put<{ data: IFile }>(
      formatString(endpoints.FILES.SINGLE, id),
      data
    );
    return response.data.data;
  }

  async getPlacements(applicationId: string) {
    const response = await axiosClient.get<{ data: ILenderPlacement[] }>(
      formatString(endpoints.APPLICATIONS.PLACEMENTS, applicationId)
    );
    return response.data.data;
  }

  async createPlacement(
    applicationId: string,
    data: Partial<ILenderPlacement>
  ) {
    const response = await axiosClient.post<{ data: { placed: boolean } }>(
      formatString(endpoints.APPLICATIONS.PLACEMENTS, applicationId),
      data
    );
    return response.data.data;
  }

  async updatePlacementStatus(applicationId: string, data: ILenderPlacement) {
    const response = await axiosClient.put<{ data: ILenderPlacement }>(
      formatString(endpoints.APPLICATIONS.PLACEMENT_STATUS, applicationId),
      data
    );
    return response.data.data;
  }

  async sendStipsToFunder(
    offerId: string,
    emailData: ISendStipsToFunderPayload
  ) {
    const response = await axiosClient.post(
      formatString(endpoints.OFFERS.SEND_STIPS_TO_FUNDER, offerId),
      emailData
    );
    return response.data.data;
  }

  async updateOfferStips(offerId: string, stips: StipPayload[]) {
    const response = await axiosClient.put<{ data: IOffer }>(
      formatString(endpoints.OFFERS.UPDATE_OFFER_STIPS, offerId),
      { stips }
    );
    return response.data.data;
  }

  async updateApplicationStips(applicationId: string, stips: StipPayload[]) {
    const response = await axiosClient.put<{ data: IStip[] }>(
      formatString(
        endpoints.APPLICATIONS.UPDATE_APPLICATION_STIPS,
        applicationId
      ),
      { stips }
    );
    return response.data.data;
  }

  async updateOfferStatus(offerId: string, status: string, reason?: string) {
    await axiosClient.put(
      formatString(endpoints.OFFERS.UPDATE_OFFER_STATUS, offerId),
      { status, declined_reason: reason }
    );
  }

  async updateClosingStatus(appId: string, status: string) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.UPDATE_CLOSING_STATUS, appId),
      { status }
    );
  }

  async sendContractByEmail(appID: IApplication["id"]): Promise<void> {
    await axiosClient.post(
      formatString(endpoints.APPLICATIONS.SEND_CONTRACT_BY_EMAIL, appID)
    );
  }

  async sendFundingContract(
    appID: IApplication["id"],
    contractTemplateId?: ContractTemplateV2["id"]
  ): Promise<void> {
    await axiosClient.post(
      formatString(endpoints.APPLICATIONS.SEND_FUNDING_CONTRACT, appID),
      { contract_template_id: contractTemplateId }
    );
  }

  async sendToLoanProvider(
    offerID: string,
    servicer: string,
    payload: Record<string, string>
  ) {
    await axiosClient.post(
      formatString(endpoints.OFFERS.SEND_TO_LOAN_PROVIDER, offerID, servicer),
      { ...payload }
    );
  }

  async confirmOffer(offerId: string, payload: Partial<IOffer>) {
    await axiosClient.put(
      formatString(endpoints.OFFERS.CONFIRM_OFFER, offerId),
      payload
    );
  }

  async getStipsCollectionLink(offerId: string) {
    const response = await axiosClient.get<{ data: { link: string } }>(
      formatString(endpoints.OFFERS.STIPS_COLLECTION_LINK, offerId)
    );

    return response.data.data.link;
  }

  async getApplicationStipsCollectionLink(applicationId: string) {
    const response = await axiosClient.get<{ data: { link: string } }>(
      formatString(endpoints.APPLICATIONS.STIPS_COLLECTION_LINK, applicationId)
    );

    return response.data.data.link;
  }

  async sendOffersEmail(applicationId: string, offerIds: string[] | null) {
    const payload = offerIds ? { offers: offerIds } : {};
    const response = await axiosClient.post<{ data: { sent: boolean } }>(
      formatString(endpoints.OFFERS.SEND_OFFERS_EMAIL, applicationId),
      payload
    );

    return response.data;
  }

  async createOfferNote(
    offerId: IOffer["id"],
    payload: { note: OfferNote["note"] }
  ) {
    const response = await axiosClient.post<{ data: OfferNote }>(
      formatString(endpoints.OFFERS.OFFER_NOTE, offerId),
      payload
    );
    return response.data.data;
  }

  async updateOfferNote(
    offerId: IOffer["id"],
    payload: { note: OfferNote["note"] }
  ) {
    const response = await axiosClient.put<{ data: OfferNote }>(
      formatString(endpoints.OFFERS.OFFER_NOTE, offerId),
      payload
    );
    return response.data.data;
  }

  async deleteOfferNote(offerId: IOffer["id"]) {
    return await axiosClient.delete(
      formatString(endpoints.OFFERS.OFFER_NOTE, offerId)
    );
  }

  async generateBankDocuments(accounts: IBankAccount[], appID: string) {
    const response = await axiosClient.post<{ data: { processing: boolean } }>(
      formatString(
        endpoints.APPLICATIONS.GENERATE_BANK_DOCUMENT_REPORTS,
        appID
      ),
      {
        bank_accounts: accounts
      }
    );
    store.commit(
      "setGlobalMessage",
      { title: "Documents Generating Initialized", type: "success" },
      { root: true }
    );
    return response.data.data;
  }

  async getPlacementsForDeals(dealIds: string[]) {
    const response = await axiosClient.post(
      endpoints.APPLICATIONS.PLACEMENTS_FOR_DEALS,
      { application_ids: dealIds }
    );
    return response.data.data;
  }

  async getOffersForDeals(dealIds: string[]) {
    const response = await axiosClient.post(
      endpoints.APPLICATIONS.OFFERS_FOR_DEALS,
      { application_ids: dealIds }
    );
    return response.data.data;
  }

  async getResumeAppLink(
    appID: string,
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    const response = await axiosClient.get<{ data: { resume_link: string } }>(
      formatString(endpoints.APPLICATIONS.GET_LINK, appID),
      { params: { resume: 1, ...payload } }
    );
    return response.data.data.resume_link;
  }

  async getLivenessLink(
    appID: string,
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    const response = await axiosClient.get<{ data: { docusign_link: string } }>(
      formatString(endpoints.APPLICATIONS.GET_LINK, appID),
      { params: { docusign: 1, idv: 1, ...payload } }
    );
    return response.data.data.docusign_link;
  }

  async getAdvancedCFA(
    applicationId: string,
    book: number,
    queryParams: IAdvancedCfaPayload = {}
  ) {
    if (!queryParams.search?.trim().length) {
      delete queryParams.search;
    }

    if (queryParams.from === "N/A") {
      delete queryParams.from;
    }

    if (queryParams.through === "N/A") {
      delete queryParams.through;
    }

    const response = await axiosClient.get<{
      data: OcrolusAdvancedAnalyticsData;
      meta: OcrolusAdvancedAnalyticsMeta;
    }>(
      formatString(
        endpoints.APPLICATIONS.ADVANCED_OCROLUS_CFA,
        applicationId,
        book
      ),
      {
        params: queryParams
      }
    );
    return response.data;
  }

  async getMoneythumbAnalytics(
    applicationId: string,
    book: number,
    bank_accounts?: string[]
  ) {
    const response = await axiosClient.get<
      IPaginatedResponse<Record<string, string>>
    >(
      formatString(
        endpoints.APPLICATIONS.MONEYTHUMB_ANALYTICS,
        applicationId,
        book
      ),
      {
        params: { bank_accounts }
      }
    );
    return response.data;
  }

  async saveCFAPreferences(
    clientId: string,
    queryParams: Record<string, string | (string | number)[]> = {}
  ) {
    const response = await axiosClient.post<{ data: IClientPreference }>(
      formatString(endpoints.APPLICATIONS.CLIENT_PREFERENCES, clientId),
      queryParams
    );
    return response.data.data;
  }

  async createDocVerification(
    id: string,
    params: FormData,
    additionalConfig: Record<string, unknown>
  ) {
    const response = await axiosClient.post<{ data: IDocumentVerification }>(
      formatString(endpoints.DOC_VERIFICATION, id),
      params,
      {
        headers: {
          "content-type": "multipart/form-data"
        },

        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async getApplicationFilters() {
    const response = await axiosClient.get<{
      data: ISmartFilter[];
    }>(endpoints.FILTERS.ALL);
    return response.data.data;
  }

  async deleteApplicationFilter(id: number) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.FILTERS.SINGLE, id)
    );
    return response;
  }

  async createApplicationFilter(data: Partial<ISmartFilter>) {
    const response = await axiosClient.post<ISmartFilter>(
      endpoints.FILTERS.CREATE,
      data
    );
    return response.data;
  }

  async updateApplicationFilter(id: number, data: ISmartFilter) {
    const response = await axiosClient.put<ISmartFilter>(
      formatString(endpoints.FILTERS.SINGLE, id),
      data
    );
    return response.data;
  }

  async updateApplicationFilterSortOrder(data: IFilterSort) {
    const response = await axiosClient.put<ISmartFilter>(
      endpoints.FILTERS.SORT,
      data
    );
    return response.data;
  }

  async updateApplicationFilterVisibility(data: IFilterVisibility) {
    const response = await axiosClient.put<ISmartFilter>(
      endpoints.FILTERS.VISIBILITY,
      data
    );
    return response.data;
  }

  async updateContactedVia(
    appId: string,
    { offer_ids, type }: { offer_ids: string[]; type: string }
  ) {
    const response = await axiosClient.post<{ data: { success: boolean } }>(
      formatString(endpoints.APPLICATIONS.CONTACTED_VIA, appId),
      { offer_ids, type }
    );

    return response.data.data;
  }

  async createEquipmentRentalOffer(
    appId: string,
    data: Record<string, string>
  ) {
    const response = await axiosClient.post<{ data: IEquipmentOffer }>(
      formatString(endpoints.OFFERS.CREATE_EQUIPMENT_RENTAL_OFFER, appId),
      data
    );
    return response.data;
  }

  async editEquipmentRentalOffer(
    appId: string,
    offerId: string,
    data: Record<string, string>
  ) {
    const response = await axiosClient.put<{ data: IEquipmentOffer }>(
      formatString(
        endpoints.OFFERS.UPDATE_EQUIPMENT_RENTAL_OFFER,
        appId,
        offerId
      ),
      data
    );
    return response.data;
  }

  async executeDataOrchestrationTemplate(appId: string, template_id: string) {
    const response = await axiosClient.post<{ data: IDataOrchestrationLog }>(
      formatString(endpoints.APPLICATIONS.DATA_ORCHESTRATION_EXECUTE, appId),
      { template_id }
    );
    return response.data.data;
  }

  async runDoTemplateAgainstDeals(
    applicationIds: string[],
    template_id: string
  ) {
    const response = await axiosClient.post<{ data: IDataOrchestrationLog[] }>(
      endpoints.APPLICATIONS.DATA_ORCHESTRATION_BATCH_EXECUTE,
      { template_id, applications: applicationIds }
    );
    return response.data.data;
  }

  async updateApplicationDealMode(appId: string, data: { deal_mode: number }) {
    const response = await axiosClient.put<{ data: IApplicationDealMode }>(
      formatString(endpoints.APPLICATIONS.DEAL_MODE, appId),
      data
    );
    return response.data.data;
  }

  async getFundingTransactions(offerId: string) {
    const response = await axiosClient.get<{
      data: ITransaction[];
    }>(formatString(endpoints.OFFERS.FUNDING_TRANSACTIONS, offerId));
    return response.data.data;
  }

  async addFundingTransaction(offerId: string, payload: ITransactionPayload) {
    const response = await axiosClient.post<{ data: ITransaction }>(
      formatString(endpoints.OFFERS.FUNDING_TRANSACTIONS, offerId),
      payload
    );
    return response.data.data;
  }

  async updateFundingTransaction(
    offerId: string,
    transactionId: string,
    data: Partial<ITransactionPayload>
  ) {
    const response = await axiosClient.put<{ data: ITransaction }>(
      formatString(
        endpoints.OFFERS.SINGLE_FUNDING_TRANSACTION,
        offerId,
        transactionId
      ),
      data
    );
    return response.data.data;
  }

  async deleteFundingTransaction(offerId: string, transactionId: string) {
    await axiosClient.delete(
      formatString(
        endpoints.OFFERS.SINGLE_FUNDING_TRANSACTION,
        offerId,
        transactionId
      )
    );
  }

  async uploadTransactionFile(
    offerId: string,
    transactionId: string,
    data: IFilePayload,
    additionalConfig: Record<string, unknown> = {}
  ) {
    const formData = new FormData();
    Object.keys(data).forEach((key) =>
      formData.append(key, data[key as keyof IFilePayload] as string | Blob)
    );

    const response = await axiosClient.post<{ data: IFile }>(
      formatString(
        endpoints.OFFERS.UPLOAD_TRANSACTION_FILE,
        offerId,
        transactionId
      ),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },

        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async updateDataOrchestrationMasterStatus(
    appId: string,
    logId: string,
    master_status: number
  ) {
    const response = await axiosClient.put<{ data: IDataOrchestrationLog }>(
      formatString(
        endpoints.APPLICATIONS.DATA_ORCHESTRATION_MASTER_STATUS,
        appId,
        logId
      ),
      { master_status }
    );
    return response.data.data;
  }

  async getSystemPreferences() {
    const response = await axiosClient.get<{
      data: ISystemPreferences;
    }>(endpoints.SYSTEM_PREFERENCES);

    return response.data.data;
  }

  async updateDealVisibility(payload: IDealVisibilityUpdate) {
    await axiosClient.put(endpoints.DEAL_VISIBILITY, payload);
    // The response from the API is success/fail, so no need to return anything
  }

  async getDataOrchestrationLog(appId: string, logId: string) {
    const response = await sendRequestWithCancellation<{
      data: IDataOrchestrationLog;
    }>(
      {
        httpMethod: "get",
        baseUrl: endpoints.APPLICATIONS.SINGLE_DATA_ORCHESTRATION_LOG,
        urlModifiers: [appId, logId]
      },
      appId
    );

    return response.data;
  }

  async getDataOrchestrationLogs(appId: string) {
    const response = await sendRequestWithCancellation<{
      data: IDataOrchestrationLog[];
    }>(
      {
        httpMethod: "get",
        baseUrl: endpoints.APPLICATIONS.DATA_ORCHESTRATION_LOGS,
        urlModifiers: [appId]
      },
      appId
    );
    return response.data;
  }

  async getBankAccounts(appId: string, providers: number[]) {
    const response = await axiosClient.get<{ data: IBankAccount[] }>(
      formatString(
        endpoints.APPLICATIONS.BANK_ACCOUNTS.GET_BANK_ACCOUNTS,
        appId
      ),
      { params: { providers } }
    );
    return response.data.data;
  }

  async getBankData(appId: string) {
    const response = await axiosClient.get<{
      data: IBankData;
    }>(formatString(endpoints.APPLICATIONS.BANK_TRANSACTIONS, appId));

    return response.data?.data ?? ({ bank_accounts: [] } as IBankData);
  }

  async getPlaidData(appId: string) {
    const response = await axiosClient.get<{
      data: PlaidData;
    }>(formatString(endpoints.APPLICATIONS.PLAID_DATA, appId));

    return (
      response.data.data ?? ({ bank_accounts: [] } as unknown as PlaidData)
    );
  }

  async setDiscoveryStatus(appId: string, status: number) {
    const response = await axiosClient.put(
      formatString(endpoints.APPLICATIONS.SINGLE_APPLICATION, appId),
      { is_discovery_complete: !!status }
    );
    return response.data?.data;
  }

  async getApplicationAttributes(appId: string) {
    const response = await axiosClient.get<{ data: IApplicationAttribute[] }>(
      formatString(endpoints.APPLICATIONS.APPLICATION_ATTRIBUTES, appId)
    );
    return response.data.data;
  }
  async updateApplicationAttributeValue(attributeInfo: IApplicationAttribute) {
    const response = await axiosClient.put<{ data: IApplicationAttribute }>(
      formatString(
        endpoints.APPLICATIONS.APPLICATION_ATTRIBUTES_ID,
        attributeInfo.application_id,
        attributeInfo.attribute.id
      ),
      { value: attributeInfo.value }
    );
    return response.data.data;
  }

  async duplicateDeal(appId: string, data: Record<string, string>) {
    const response = await axiosClient.post(
      formatString(endpoints.APPLICATIONS.DUPLCATE, appId),
      data
    );
    return response.data.data;
  }

  async setPrimaryScorecard(
    id: string,
    applicationId: string,
    stage_id: string,
    type: ScorecardType
  ) {
    const SCORECARDS_SET_PRIMARY_ENDPOINTS = {
      [ScorecardType.CARD]: endpoints.SCORECARDS.PRIMARY,
      [ScorecardType.GROUP]: endpoints.SCORECARD_GROUPS.PRIMARY
    };
    return await axiosClient.put(
      formatString(SCORECARDS_SET_PRIMARY_ENDPOINTS[type], applicationId, id),
      { stage_id }
    );
  }

  async getOrchestrationTemplates(
    applicationId: string,
    params: Record<string, unknown> = {}
  ) {
    const response = await axiosClient.get<
      IPaginatedResponse<IOrchestrationTemplate>
    >(
      formatString(
        endpoints.APPLICATIONS.DATA_ORCHESTRATION_TEMPLATES,
        applicationId
      ),
      { params }
    );
    return response.data;
  }

  async getWidgetInfo(appId: string) {
    const response = await axiosClient.get<{
      data: WidgetInfo;
    }>(formatString(endpoints.APPLICATIONS.WIDGET_INFO, appId));

    return response.data?.data;
  }

  async batchUpdateUserRoles(data: UpdateUserRolesPayload) {
    await axiosClient.put(endpoints.BATCH_ACTIONS.ROLES, data);
  }

  async batchUpdateDealMode(data: UpdateDealModePayload) {
    await axiosClient.put(endpoints.BATCH_ACTIONS.DEAL_MODE, data);
  }

  async generateTaxDetailsDocuSignPlaidLink(
    appId: string,
    payload: Partial<IApplicationTemporaryLinks>
  ) {
    const response = await axiosClient.post<{
      data: IApplicationTemporaryLinks;
    }>(formatString(endpoints.APPLICATIONS.GENERATE_TEMPORARY_LINKS, appId), {
      ...payload,
      tax_details_consent_bank_statements: true
    });
    return response.data.data;
  }

  async sendEmail(appId: string) {
    const response = await axiosClient.post<{ data: IApplication[] }>(
      formatString(endpoints.APPLICATIONS.SEND_EMAIL, appId)
    );
    return response.data.data;
  }

  async getScorecardsResults(payload: { id: string; funderIds: string[] }) {
    const response = await axiosClient.post<{ data: IScorecardResult[] }>(
      formatString(endpoints.APPLICATIONS.SCORECARD_RESULTS, payload.id),
      { funder_ids: payload.funderIds }
    );

    return response.data.data;
  }

  async getFiles(appId: string) {
    const response = await axiosClient.get<{ data: IFile[] }>(
      formatString(endpoints.APPLICATIONS.APPLICATION_FILES, appId)
    );
    return response.data.data;
  }

  async toggleShowOffer(offerId: string) {
    await axiosClient.put<{ data: IOffer }>(
      formatString(endpoints.OFFERS.TOGGLE_SHOW_OFFER, offerId)
    );
  }

  async updateWorkflowStatus(appId: string, status: ApplicationStatusPayload) {
    const response = await axiosClient.put<{ data: WorkflowSnapshot }>(
      formatString(endpoints.WORKFLOW_TEMPLATES.WORKFLOW_STATUS, appId),
      status
    );
    return response.data.data;
  }

  async updateServiceJson(appId: string, payload: UpdateServiceJsonPayload) {
    await axiosClient.put(
      formatString(endpoints.APPLICATIONS.UPDATE_SERVICE_JSON, appId),
      payload
    );
  }

  async getServicesOptions() {
    const response = await axiosClient.get<{
      data: { [service: string]: Record<string, unknown> };
    }>(endpoints.APPLICATIONS.SERVICES_OPTIONS);

    return response.data.data;
  }
}

const applicationsApiService = new ApplicationsApiService();

export default applicationsApiService;

<template>
  <div class="break-long-words dynamic-field relative">
    <lf-text-editor
      ref="textEditorComponentRef"
      :class="{ 'editor-with-counter': hasLengthCounter }"
      content-type="html"
      :value="modelValue"
      :name="name"
      :modules="modules"
      :placeholder="placeholder"
      @update:content="handleValueUpdate"
      @selection:change="handleSelectionChange"
      @editor:ready="handlePreparedEditor"
    >
      <template v-slot:customToolbar v-if="showToolbar">
        <span v-if="isDynamicToolbar" class="ql-formats">
          <select class="ql-header">
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
            <option value="6"></option>
            <option value="false" selected></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-underline"></button>
          <button class="ql-italic"></button>
          <button v-if="isDynamicToolbar" class="ql-strike"></button>
        </span>
        <span v-if="isDynamicToolbar" class="ql-formats">
          <select class="ql-color"></select>
          <button class="ql-script" value="sub"></button>
          <button class="ql-script" value="super"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>
        <span v-if="isDynamicToolbar" class="ql-formats">
          <select class="ql-lineheight" value="ordered">
            <option
              v-for="option in WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS"
              :value="option"
              :key="option"
            ></option>
          </select>
          <select class="ql-marginTop" value="ordered">
            <option
              v-for="option in PARAGRAPH_MARGIN_OPTIONS"
              :value="option"
              :key="option"
            ></option>
          </select>
          <select class="ql-marginBottom" value="ordered">
            <option
              v-for="option in PARAGRAPH_MARGIN_OPTIONS"
              :value="option"
              :key="option"
            ></option>
          </select>
        </span>
        <span class="ql-formats" v-if="canUploadImage">
          <button class="ql-image" />
        </span>
        <span class="ql-formats">
          <lf-dropdown
            bold-placeholder
            :custom-button-classnames="{
              widthClassname: '!w-32',
              headlineTextClassname: 'text-headline'
            }"
            custom-z-index="z-[101]"
            hide-placeholder-when-value
            is-stateless
            name="dynamic_field_dropdown"
            :options="dynamicFieldsDropdownOptions"
            :placeholder="t('COMMON.DYNAMIC_FIELDS')"
            ref="dynamicDropdownRef"
            search-enabled
            :teleport-options="{ enabled: true }"
            @change="insertDynamicField"
          ></lf-dropdown>
        </span>
      </template>
    </lf-text-editor>
    <dynamic-field-link-modal
      v-if="showLinkEditor"
      ref="modalRef"
      :value="linkEditorValue"
      :position="linkModalPosition"
      @value:save="saveLink"
      @modal:close="closeLinkModal"
      @link:remove="removeLink"
    />
    <div
      v-if="hasLengthCounter"
      class="mx-2 pb-2 border rounded-b border-t-0 px-4 counter"
    >
      <div class="w-2/5 max-w-50 relative">
        <circle-progress
          :value="cleanContent.length % maxLength"
          :max-value="maxLength"
          :custom-color="UTIL_COLORS.PRIMARY"
          :size="20"
          :border-width="3"
          :border-bg-width="3"
          :custom-value="valueLengthText"
          text-small
          text-styles="font-semibold -ml-8"
        />
        <icon-base
          :icon="IconInfo"
          v-tooltip="t('ACTIVITY.SMS.MESSAGE_LENGTH_TOOLTIP')"
          class="text-label absolute top-0-25 left-[125px]"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, type Ref } from "vue";
import { UTIL_COLORS } from "@/helpers/constants";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  removeAttributesFromPayload,
  removeDenotation,
  zeroWidthSpaceCharRegex
} from "@/helpers/textEditor";
import { onClickOutside } from "@vueuse/core";
import compact from "lodash/compact";
import { stripHTMLTagsFromString } from "@/helpers/common";
import { CommunicationType } from "@/enums/communicationLogs";
import { getDropdownOptions } from "@/helpers/common";
import {
  WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS,
  PARAGRAPH_MARGIN_OPTIONS
} from "@/helpers/constants";

import Mention from "quill-mention";
import ImageUploader from "quill-image-uploader";
import LfTextEditor from "@/components/ui/LfTextEditor.vue";
import DynamicFieldLinkModal from "@/components/DynamicFieldLinkModal.vue";
import CircleProgress from "@/components/ui/CircleProgress.vue";
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import IconInfo from "@/components/icons/IconInfo.vue";

import type { MaybeElement } from "@vueuse/core";
import type { EmailTemplateDynamicField } from "@/models/options";
import type { Delta } from "@vueup/vue-quill";

type ToolbarType = "simple" | "dynamic";

const {
  modelValue,
  placeholder = "",
  showToolbar = false,
  name,
  canUploadImage = false,
  hasLengthCounter = false,
  maxLength = 0,
  type = CommunicationType.email,
  toolbarType = "simple"
} = defineProps<{
  modelValue: string;
  placeholder?: string;
  showToolbar?: boolean;
  name: string;
  canUploadImage?: boolean;
  hasLengthCounter?: boolean;
  maxLength?: number;
  type?: CommunicationType;
  toolbarType?: ToolbarType;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string];
  "file:upload": [File];
  "clean-content:update": [value: string];
}>();

const { t } = useI18n();
const { getters } = useStore();

const modalRef = ref<InstanceType<typeof DynamicFieldLinkModal> | null>(null);

const MAX_TAGS_DROPDOWN_ELEMENTS = 13;
const DROPDOWN_TAG_HEIGHT = 30;
const maxTagsDropdownHeight = MAX_TAGS_DROPDOWN_ELEMENTS * DROPDOWN_TAG_HEIGHT;

const textEditorComponentRef = ref<InstanceType<typeof LfTextEditor> | null>(
  null
);

const dynamicDropdownRef = ref<InstanceType<typeof LfDropdown> | null>(null);

const typedTag = ref("");
const hasRenderedTagList = ref(false);
const dropdownElement = ref<HTMLElement | null>(null);
const linkWord = ref<{ index: number; length: number } | null>(null);
const showLinkEditor = ref(false);
const linkEditorValue = ref("");
const linkModalPosition = ref({
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  height: 0
});
const cleanContent = ref("");

const valueLengthText = computed(() => {
  if (type === CommunicationType.sms) {
    const numberOfSms = Math.ceil(cleanContent.value.length / maxLength);
    return `${cleanContent.value.length} / ${maxLength} - ${numberOfSms} ${t(
      "COMMON.SMS"
    )}`;
  }

  return "";
});

const handleSelectionChange = () => {
  const editorHasFocus =
    textEditorComponentRef.value?.editorInstance.hasFocus();

  if (showLinkEditor.value || !editorHasFocus) {
    return;
  }

  const editorInstance = textEditorComponentRef.value?.editorInstance;
  const selection = editorInstance.getSelection();
  const cursorPosition = selection?.index;
  const selectionFormat = editorInstance.getFormat();

  if (!selectionFormat?.link) {
    if (!selection.length) {
      editorInstance.setSelection(cursorPosition);
    }

    return;
  }

  const contents: Delta = editorInstance.getContents();

  const content = contents?.ops.find(
    (content) =>
      content.attributes?.link &&
      content.attributes?.link === selectionFormat.link
  );

  if (!content) {
    return;
  }

  editorInstance.setSelection(cursorPosition);

  const text = editorInstance.getText();

  let linkWordStart = text?.indexOf(content.insert);

  while (linkWordStart !== -1) {
    if (
      (cursorPosition >= linkWordStart &&
        cursorPosition <= linkWordStart + content.insert?.toString()?.length) ||
      0
    ) {
      break;
    }
    linkWordStart = text?.indexOf(content.insert, linkWordStart + 1);
  }

  if (linkWordStart === -1) {
    return;
  }

  linkWord.value = {
    index: linkWordStart,
    length: content.insert?.toString()?.length || 0
  };

  linkEditorValue.value = selectionFormat.link;
  showLinkEditor.value = true;
};

const dynamicFields = computed<EmailTemplateDynamicField[] | undefined>(
  () => getters["options/dynamicFields"]?.fields || undefined
);

const dynamicFieldsDropdownOptions = computed(() => {
  if (!dynamicFields.value) {
    return [];
  }

  return getDropdownOptions(
    dynamicFields.value.map((option) => ({
      id: option.name,
      name: option.name
    }))
  );
});

const modules = computed(() =>
  compact([
    {
      name: "mention",
      module: Mention,
      blotName: "styledMention",
      options: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["["],
        dataAttributes: ["tag", "name"],
        blotName: "styledMention",
        source: function (
          searchTerm: string,
          renderList: (a: EmailTemplateDynamicField[], b: string) => void,
          dynamicChar: string
        ) {
          if (dynamicChar !== "[") {
            return;
          }

          const values = dynamicFields.value?.map((field) => ({
            ...field,
            value: field.name
          }));

          if (!values?.length) {
            return;
          }

          typedTag.value = searchTerm;

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
            dropdownElement.value = document.querySelector(
              ".ql-mention-list"
            ) as HTMLElement;
            if (dropdownElement.value) {
              dropdownElement.value.style.height = maxTagsDropdownHeight + "px";
            }
            // has to be rendered the second time on the very first load
            if (!hasRenderedTagList.value) {
              renderList(values, searchTerm);
            }
            return;
          }

          const matches = values.filter((field) =>
            field.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (dropdownElement.value) {
            dropdownElement.value.style.height =
              matches.length < 13
                ? matches.length * 30 + "px"
                : maxTagsDropdownHeight + "px";
          }

          renderList(matches, searchTerm);
        },
        onSelect: function (item: EmailTemplateDynamicField) {
          if (!textEditorComponentRef.value?.editorInstance) {
            return;
          }
          const editorInstance = textEditorComponentRef.value.editorInstance;
          const cursorPosition = editorInstance.getSelection()?.index;

          if (cursorPosition !== 0 && !cursorPosition) {
            return;
          }

          editorInstance.deleteText(
            cursorPosition - typedTag.value.length - 1,
            typedTag.value.length + 1
          );
          handleDynamicFieldInsertion(
            item,
            cursorPosition - typedTag.value.length - 1
          );
          editorInstance.setSelection(cursorPosition);
        }
      }
    },
    canUploadImage && {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file: File) => {
          emit("file:upload", file);
        }
      }
    }
  ])
);

const isDynamicToolbar = computed(() => toolbarType === "dynamic");

const closeLinkModal = () => {
  showLinkEditor.value = false;
  linkEditorValue.value = "";
};

onClickOutside(modalRef as Ref<MaybeElement>, closeLinkModal);

const resetLinkState = () => {
  linkEditorValue.value = "";
  showLinkEditor.value = false;
  linkWord.value = null;
};

const removeLink = () => {
  if (!linkWord.value) {
    return;
  }
  textEditorComponentRef.value?.editorInstance.removeFormat(
    linkWord.value.index,
    linkWord.value.length
  );

  resetLinkState();
};

const saveLink = (link: string) => {
  linkEditorValue.value = link;

  if (!linkWord.value) {
    resetLinkState();
    return;
  }

  const linkWordLastLetterIndex = linkWord.value.index + linkWord.value.length;
  const editorInstance = textEditorComponentRef.value?.editorInstance;

  if (!link.length) {
    editorInstance.setSelection(linkWordLastLetterIndex);
    resetLinkState();
    return;
  }

  const word = editorInstance.getText(
    linkWord.value.index,
    linkWord.value.length
  );

  editorInstance.insertText(
    linkWord.value.index,
    word,
    "link",
    linkEditorValue.value
  );

  editorInstance.setSelection(linkWordLastLetterIndex);

  editorInstance.deleteText(linkWordLastLetterIndex, word.length);

  resetLinkState();
};

const handleDynamicFieldInsertion = (
  item: EmailTemplateDynamicField,
  position: number
) => {
  const editorInstance = textEditorComponentRef.value?.editorInstance;

  if (!editorInstance) {
    return;
  }

  editorInstance.insertEmbed(position, "styledMention", item);
  handleDenotationTags();
};

const insertDynamicField = (text: string | number) => {
  const item = dynamicFields.value?.find((field) => field.name === text);
  if (typeof text !== "string" || !text.length || !item) {
    return;
  }

  const editorInstance = textEditorComponentRef.value?.editorInstance;
  editorInstance.focus();
  const cursorPosition = editorInstance.getSelection()?.index;
  handleDynamicFieldInsertion(item, cursorPosition);
  editorInstance.setSelection(cursorPosition + 1);

  clearDynamicDropdownSearchTerm();
};

const clearDynamicDropdownSearchTerm = () => {
  if (!dynamicDropdownRef.value?.search) {
    return;
  }

  dynamicDropdownRef.value.search = "";
};

const handleDenotationTags = () => {
  const denotationCharNodes = document.querySelectorAll(
    ".ql-mention-denotation-char"
  );
  if (denotationCharNodes.length) {
    denotationCharNodes.forEach((denotationCharNode) =>
      removeDenotation(denotationCharNode)
    );
  }
};

const handleValueUpdate = (value: string) => {
  const contents: Delta =
    textEditorComponentRef.value?.editorInstance.getContents();
  const hasContent = contents?.ops.some(
    (content) => content.insert !== "\n" && content.insert !== ""
  );
  if (hasContent) {
    updateCleanContent(value);
    emit("update:modelValue", removeAttributesFromPayload(value));
    return;
  }
  updateCleanContent("");
  emit("update:modelValue", "");
};

const updateCleanContent = (value: string) => {
  cleanContent.value = stripHTMLTagsFromString(
    value,
    true,
    type === CommunicationType.sms ? true : false
  ).replace(zeroWidthSpaceCharRegex, "");

  emit("clean-content:update", cleanContent.value);
};

const handlePreparedEditor = () => {
  const visibleLength = 35;
  const dynamicFieldsElements = document.querySelectorAll(
    ".ql-dynamicfields .ql-picker-item"
  );

  dynamicFieldsElements.forEach((element) => {
    const contentLength = window.getComputedStyle(element, "::before").content
      .length;

    if (contentLength > visibleLength) {
      element.classList.add("overflow");
    }
  });
};

const handleClickLink = () => {
  const editorInstance = textEditorComponentRef.value?.editorInstance;
  linkWord.value = editorInstance?.getSelection();
  linkModalPosition.value = editorInstance.getBounds(linkWord.value);
  if (!linkWord.value?.length) {
    return;
  }
  showLinkEditor.value = true;
};

onMounted(() => {
  textEditorComponentRef.value?.editorInstance
    .getModule("toolbar")
    ?.addHandler("link", handleClickLink);
});

defineExpose({ clearDynamicDropdownSearchTerm });
</script>
<style>
.ql-picker.ql-dynamicfields {
  width: 150px;
}

.ql-picker.ql-lineheight,
.ql-picker.ql-marginTop,
.ql-picker.ql-marginBottom {
  width: 24px;
  @apply relative;
}

.ql-picker.ql-lineheight svg,
.ql-picker.ql-marginTop svg,
.ql-picker.ql-marginBottom svg {
  width: 0 !important;
}

.ql-picker.ql-lineheight::before {
  width: 22px;
  height: 27.5px;
  background: url("/icons/LineHeight.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}

.ql-picker.ql-marginTop::before {
  width: 18px;
  height: 23.5px;
  background: url("/icons/MarginTop.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}
.ql-picker.ql-marginBottom::before {
  width: 18px;
  height: 23.5px;
  background: url("/icons/MarginBottom.svg") no-repeat;
  content: "";
  @apply absolute top-0 right-0 cursor-pointer z-3 pointer-events-none;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item::before,
.ql-snow .ql-picker.ql-marginTop .ql-picker-item::before,
.ql-snow .ql-picker.ql-marginBottom .ql-picker-item::before {
  content: attr(data-value);
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item:first-of-type::before,
.ql-snow .ql-picker.ql-marginTop .ql-picker-item:first-of-type::before,
.ql-snow .ql-picker.ql-marginBottom .ql-picker-item:first-of-type::before {
  content: "reset";
}

.ql-picker-options {
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 50 !important;
}

.ql-mention-list {
  @apply overflow-y-auto shadow-lg bg-white z-100 relative;
}

.ql-mention-list-item {
  @apply hover:bg-gray-50 text-headline text-sm py-1 px-2;
}

.ql-mention-list-item.selected {
  @apply bg-gray-50;
}

.quill-tag-business {
  background-color: rgba(255, 165, 0, 0.1);
}
.quill-tag-contact {
  background-color: rgba(0, 128, 0, 0.1);
}
.quill-tag-integration_partner {
  background-color: rgba(0, 255, 0, 0.1);
}
.quill-tag-personal_information {
  background-color: rgba(0, 191, 255, 0.1);
}

.quill-tag-other {
  background-color: rgba(255, 255, 0, 0.1);
}

.email-templates-edit .ql-container {
  height: auto;
  min-height: 100%;
  max-height: unset;
}
.email-templates-edit .ql-container .ql-editor {
  max-height: unset;
  height: inherit;
}

.dynamic-field.sms-headless {
  @apply flex flex-col;
  height: calc(100% - 53px);
}

.email-headless .ql-toolbar,
.sms-headless .ql-toolbar {
  display: none;
}

.sms-template .ql-toolbar {
  @apply border-b-0;
}

.dynamic-field.sms-headless .ql-container,
.dynamic-field.sms-template .ql-container {
  border-radius: 0.375rem;
  border-top: 1px solid rgb(229, 231, 235) !important;
  @apply pl-1 py-2-5 pr-7 mx-2 border-b-0 rounded-b-none max-h-none;
}

.dynamic-field.sms-template .ql-container,
.dynamic-field.sms-template .counter {
  @apply mx-0 rounded-none;
}

.dynamic-field.sms-headless .ql-editor,
.dynamic-field.sms-template .ql-editor {
  min-height: 45px;
  max-height: unset;
  height: inherit;
}

.dynamic-field .editor-with-counter {
  @apply h-full;
}

.dynamic-field.email-headless .ql-container {
  border-radius: 0.375rem;
  border-color: rgb(229, 231, 235);
  border-top: 1px solid rgb(229, 231, 235) !important;
  min-height: 45px !important;
  @apply pl-2-5 py-2-5 pr-7;
}

.email-headless.email-headless__xs.ql-container {
  min-height: 15px !important;
  @apply pl-1-5 py-1-5 pr-3;
}

.dynamic-field.email-headless .ql-container .ql-editor {
  padding: 0;
  min-height: unset;
  @apply text-headline text-sm;
}

.email-headless .ql-editor.ql-blank::before {
  font-style: normal;
  opacity: 0.6;
  left: 9px;
}

.dynamic-field__body .ql-tooltip:has(.ql-preview) {
  display: none;
}

.sms-template .text-red-700 {
  @apply border-x pb-2 pt-0 px-4;
}
</style>

<template>
  <div class="space-y-2">
    <div class="flex flex-col w-full rounded-md bg-white shadow-md h-[96px]">
      <div class="flex justify-between items-start px-6 py-4">
        <div class="flex flex-col space-y-5">
          <p class="line-clamp-3 text-gray-400 break-all">
            {{
              template.body ??
              $t("ORGANIZATION.COMMUNICATION_TEMPLATES.NO_CONTENT_ADDED")
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex space-x-2 items-center">
      <input
        v-if="select"
        :id="`checkbox-${template.id}`"
        type="checkbox"
        :checked="selectedIds.includes(template.id)"
        class="cursor-pointer"
        @input="handleCheckboxInput"
      />
      <label
        :for="`checkbox-${template.id}`"
        class="text font-semibold"
        :class="{ 'cursor-pointer': select }"
      >
        {{ template.name }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EmailTemplate } from "@/models/clients";
import { type PropType } from "vue";

const emit = defineEmits<{
  (eventName: "tile:select", id: EmailTemplate["id"]): void;
}>();

const props = defineProps({
  template: {
    type: Object as PropType<EmailTemplate>,
    required: true
  },
  select: {
    type: Boolean,
    default: false
  },
  selectedIds: {
    type: Array as PropType<EmailTemplate["id"][]>,
    default: () => []
  }
});

const handleCheckboxInput = () => {
  if (!props.select) {
    return;
  }
  emit("tile:select", props.template.id);
};
</script>

import type { IStatisticsData } from "@/models/funders";
import { calcAvg } from "./calculations";
import { fixTo, formatMoney, formatPercentage } from "./formatting";

export const getTrend = (data: IStatisticsData[]) => {
  let trend = 0;
  let dataLength = data.length;
  if (dataLength > 30) {
    dataLength = 30;
    data = data.slice(1).slice(-30);
  }
  const dataHalfLength = Math.ceil(dataLength / 2);

  const firstHalfAvg = calcAvg(
    data.splice(0, dataHalfLength) as IStatisticsData[],
    (day: { total: number }) => day.total
  );
  const secondHalfAvg = calcAvg(
    data.splice(-dataHalfLength) as IStatisticsData[],
    (day) => day.total
  );

  if (dataLength > 1) {
    if (secondHalfAvg > firstHalfAvg) {
      trend = 1;
    } else if (secondHalfAvg < firstHalfAvg) {
      trend = -1;
    }
  }
  return trend;
};

export const FUNDER_STATISTICS = {
  FUNDER_METRICS: {
    submitted_applications: {
      title: "Apps Submitted"
    },
    recent_submissions: {
      title: "Submissions in Past 30 Days"
    },
    total_offers: {
      title: "Total offers received"
    },
    funded_businesses: {
      title: "Funded Businesses"
    },
    average_funding_amount: {
      title: "Avg Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    },
    renewals: {
      title: "Renewals"
    },
    offer_revoked: {
      title: "Number Of Offers Revoked"
    },
    total_funding_amount: {
      title: "Total Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    }
  },
  PRODUCT_METRICS: {
    advance_rate: {
      title: "Avg Advance Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    term_length: {
      title: "Avg Term Length",
      suffix: "month",
      formatter: (v: string | number) => fixTo(v)
    },
    "30_day_discount_rate": {
      title: "Avg 30 Day Discount Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    "10_day_discount_rate_after_initial_30_days": {
      title: "Avg 10 Day Discount Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    monthly_interest: {
      title: "Monthly Interest",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    max_sell_factor_rate: {
      title: "Max Sell Factor Rate"
    },
    min_buy_factor_rate: {
      title: "Min Buy Factor Rate"
    },
    max_sell_interest_rate: {
      title: "Max Sell Interest Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    min_buy_interest_rate: {
      title: "Min Buy Interest Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    funding_amount: {
      title: "Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    }
  },
  CONVERSION_RATES: {
    offer_received_app_submitted: {
      title: "Offers Received / Apps Submitted",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    offer_received_deals_funded: {
      title: "Deals Funded / Offers Received",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    app_submitted_deals_funded: {
      title: "Deals Funded / Apps Submitted",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    renewal_percentage: {
      title: "Renewal Percentage",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    }
  }
};

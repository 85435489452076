<template>
  <div class="flex flex-col max-w-screen-md">
    <lf-card
      v-if="userId && userNotifications"
      class="pb-6"
      data-testid="userNotifications"
    >
      <template #header>
        <lf-h3 class="mt-7">
          {{ $t("NOTIFICATIONS.MY_NOTIFICATIONS") }}
        </lf-h3>
      </template>
      <loader :is-loading="userNotificationsSubmitting" />

      <!-- Client notifications -->
      <div>
        <lf-switch
          name="selectAll"
          :model-value="selectedNotifications[CLIENT_NOTIFICATIONS]?.length"
          class="px-6 pb-7"
          :class="{
            'border-b': !selectedNotifications[CLIENT_NOTIFICATIONS]?.length
          }"
          :disabled="isFundingAdvisor"
          content-class="pr-10"
          @update:model-value="selectAll[CLIENT_NOTIFICATIONS] = $event"
          @toggle-changed="handleClientNotificationsToggle"
        >
          <lf-h4>
            {{ $t("NOTIFICATIONS.WIDGET_PROCESS") }}
          </lf-h4>
          <p class="mt-1.5">
            {{ $t("NOTIFICATIONS.WIDGET_PROCESS_DESCRIPTION") }}
          </p>
        </lf-switch>
        <div
          v-if="selectedNotifications[CLIENT_NOTIFICATIONS]?.length"
          class="grid grid-rows-2 bg-disabled px-6 py-2 -mx-6 border-t border-b shadow-inner grid-flow-col gap-2"
        >
          <lf-checkbox
            v-for="notification in notificationTypes[CLIENT_NOTIFICATIONS]"
            :key="notification.id"
            v-model="selectedNotifications[CLIENT_NOTIFICATIONS]"
            class="my-2"
            no-margin
            :name="notification.name"
            :value="notification.id"
            :disabled="isFundingAdvisor"
          >
            <span class="place-self-center ml-2">{{ notification.name }}</span>
          </lf-checkbox>
        </div>
      </div>

      <div>
        <!-- Orchestration notifications -->
        <div v-if="auth && client?.can_manage_data_orchestration">
          <lf-switch
            name="selectAll"
            :model-value="showDataOrchestrationNotifications"
            class="px-6 py-7"
            :class="{
              'border-b': !showDataOrchestrationNotifications
            }"
            :disabled="isFundingAdvisor"
            @update:model-value="
              selectAll[DATA_ORCHESTRATION_NOTIFICATIONS] = $event
            "
            @toggle-changed="handleDataOrchestrationToggle"
          >
            <lf-h4>
              {{ $t("NOTIFICATIONS.NOTIFICATIONS_DO") }}
            </lf-h4>
          </lf-switch>
          <div
            v-if="showDataOrchestrationNotifications"
            class="grid grid-cols-4 bg-disabled px-6 py-2 -mx-6 border-t border-b shadow-inner"
          >
            <lf-checkbox
              v-for="notification in notificationTypes[
                DATA_ORCHESTRATION_NOTIFICATIONS
              ]"
              :key="notification.id"
              v-model="selectedNotifications[DATA_ORCHESTRATION_NOTIFICATIONS]"
              class="my-2"
              :name="notification.name"
              :value="notification.id"
              :disabled="isFundingAdvisor"
            >
              {{ notification.name }}
            </lf-checkbox>
          </div>
        </div>
        <access-control :roles="NOT_FA_ROLE_GROUP">
          <div class="flex justify-end items-center min-w-full pt-6 pr-5">
            <primary-button
              type="button"
              :disabled="userNotificationsSubmitting"
              @click="submitUserNotifications"
            >
              {{ $t("COMMON.SAVE_CHANGES") }}
            </primary-button>
          </div>
        </access-control>
      </div>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import type { IUserNotification } from "@/models/common";
import { NOT_FA_ROLE_GROUP } from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import type { INotificationTypes } from "@/models/options";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import type { IClient } from "@/models/clients";
import { useUsersStore } from "@/stores/users";
import Loader from "@/components/ui/Loader.vue";
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import LfCard from "@/components/ui/LfCard.vue";

const CLIENT_NOTIFICATIONS: NotificationID = 1;
const DATA_ORCHESTRATION_NOTIFICATIONS: NotificationID = 4;

type NotificationID = 1 | 4;

const props = defineProps({
  clientId: {
    type: [String, Number],
    required: true
  },
  clientNotifications: {
    type: Array as PropType<IUserNotification[]>,
    required: true
  },
  auth: {
    type: Boolean,
    default: false
  },
  companyName: {
    type: String,
    required: true
  },
  userNotifications: {
    type: Array as PropType<IUserNotification[]>,
    default: () => []
  },
  userId: {
    type: Number,
    default: null
  }
});

const { t } = useI18n();
const { getters } = useStore();
const { showMessage } = useNotification();
const usersStore = useUsersStore();

const userNotificationsSubmitting = ref(false);
const { isFundingAdvisor } = useAuth();

const selectAll = ref<Record<string, boolean>>({
  [CLIENT_NOTIFICATIONS]: false,
  [DATA_ORCHESTRATION_NOTIFICATIONS]: false
});

const client = computed<IClient | null>(() =>
  props.auth ? getters["auth/authClientSettings"] : null
);

const notificationTypes = computed<INotificationTypes>(
  () => getters["options/notificationTypes"]
);

const getInitialValue = (notifications: IUserNotification[], groupId: number) =>
  notifications?.reduce((acc: number[], current: IUserNotification) => {
    if (current.group === groupId) {
      return [...acc, current.id];
    }
    return acc;
  }, []) || [];

const selectedNotifications = ref({
  1: getInitialValue(props.userNotifications, 1),
  4: getInitialValue(props.userNotifications, 4)
});

const showDataOrchestrationNotifications = computed(
  () => !!selectedNotifications.value[DATA_ORCHESTRATION_NOTIFICATIONS].length
);

const triggerSwitch = (type: NotificationID) => {
  const allNotifications = notificationTypes.value[type].map(
    (notification) => notification.id
  );
  selectAll.value[type] = allNotifications.every((notification) =>
    selectedNotifications.value[type]?.includes(notification)
  );
};

const handleSelectAllClick = (value: boolean, type: NotificationID) => {
  if (!value) {
    selectedNotifications.value[type] = [];
    return;
  }
  selectedNotifications.value[type] = notificationTypes.value[type].map(
    (notification) => notification.id
  );
};

const handleClientNotificationsToggle = (value: boolean) => {
  handleSelectAllClick(value, CLIENT_NOTIFICATIONS);
};

const handleDataOrchestrationToggle = (value: boolean) => {
  handleSelectAllClick(value, DATA_ORCHESTRATION_NOTIFICATIONS);
};

const submitUserNotifications = async () => {
  userNotificationsSubmitting.value = true;
  let notifications: Record<string, number[]> = {
    [CLIENT_NOTIFICATIONS]: selectedNotifications.value[CLIENT_NOTIFICATIONS]
  };

  if (props.auth && client.value?.can_manage_data_orchestration) {
    notifications = {
      ...notifications,
      [DATA_ORCHESTRATION_NOTIFICATIONS]:
        selectedNotifications.value[DATA_ORCHESTRATION_NOTIFICATIONS]
    };
  }

  const payload = {
    userId: props.userId,
    auth: props.auth,
    data: {
      notifications
    }
  };

  try {
    await usersStore.setUserNotifications(payload);
    showMessage(t("NOTIFICATIONS.SAVED"));
  } catch (error) {
    showMessage(t("COMMON.ERROR_OCCURRED"), "error");
  } finally {
    userNotificationsSubmitting.value = false;
  }
};

triggerSwitch(CLIENT_NOTIFICATIONS);
triggerSwitch(DATA_ORCHESTRATION_NOTIFICATIONS);

watch(
  () => selectedNotifications.value[CLIENT_NOTIFICATIONS],
  () => triggerSwitch(CLIENT_NOTIFICATIONS)
);

watch(
  () => selectedNotifications.value[DATA_ORCHESTRATION_NOTIFICATIONS],
  () => triggerSwitch(DATA_ORCHESTRATION_NOTIFICATIONS)
);
</script>

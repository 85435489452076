<template>
  <lf-checkbox-dropdown
    ref="checkboxDropdown"
    :get-paginated-options="getDeals"
    :options="appNameMap"
    :placeholder="
      $t('ACTIVITY.FILTERS.DEAL.PLACEHOLDER', appIdsValue?.length || 1)
    "
    api-query-param="search"
    class="min-w-full z-30 deals-filter"
    enable-search
    maintain-search-on-close
    name="application_ids"
    :show-clear-filter="showClearFilter"
    width-full
    dropdown-width-full
    no-margin
    :lazy-load="lazyLoad"
    :render-component="renderComponent?.instance"
    :render-component-options="renderComponent?.props"
    :filter-icon="filterIcon"
    :toggle-from-parent="filterIcon ? toggleFromParent : undefined"
    :model-value="(appIdsValue || []) as unknown[]"
    @menu:closed="$emit('menu:closed')"
    @update:model-value="$emit('value:update', $event as string[])"
    @loaded-more="$emit('options:update', $event as IApplication[], false)"
    @options-search="$emit('options:update', $event as IApplication[], true)"
  >
    <template v-if="filterIcon" v-slot:default>
      <slot v-if="filterIcon" name="trigger" />
    </template>
    <template #header>
      <slot name="header" />
    </template>
    <template v-if="showTopClear" #pre-search>
      <div class="flex items-center justify-between px-4 py-2">
        <lf-h4>{{ $t("COMMON.DEAL") }}</lf-h4>
        <button class="text-error" @click="$emit('value:update', [])">
          {{ $t("COMMON.CLEAR") }}
        </button>
      </div>
    </template>
    <template v-slot:list="{ filteredList, invertedOptions }">
      <slot
        name="options-list"
        :inverted-options="invertedOptions"
        :filtered-list="filteredList"
        :input-info="inputInfo"
        :app-by-id="appById"
      />
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </lf-checkbox-dropdown>
</template>

<script setup lang="ts">
import { ref, type Component } from "vue";
import type { IApplication } from "@/models/applications";
import applicationsApiService from "@/services/modules/applications";
import LfCheckboxDropdown from "@/components/ui/inputs/LfCheckboxDropdown.vue";

defineEmits<{
  "value:update": [string[]];
  "options:update": [IApplication[], boolean];
  "menu:closed": [];
}>();

withDefaults(
  defineProps<{
    appById: Record<string, IApplication>;
    appNameMap: Record<string, string>;
    appIdsValue: string | string[] | null;
    filterIcon: boolean;
    toggleFromParent?: boolean;
    inputInfo?: {
      type: string;
      class: string;
    };
    lazyLoad?: boolean;
    showClearFilter?: boolean;
    showTopClear?: boolean;
    renderComponent?: { instance: Component; props?: Record<string, unknown> };
  }>(),
  {
    filterIcon: false,
    toggleFromParent: false,
    inputInfo: () => ({
      type: "checkbox",
      class: "hidden -z-1 absolute"
    }),
    lazyLoad: true
  }
);

const checkboxDropdown = ref<InstanceType<typeof LfCheckboxDropdown> | null>(
  null
);

const closeDropdown = () => {
  if (checkboxDropdown.value) {
    checkboxDropdown.value.showMenu = false;
  }
};

const getDeals = (params: Record<string, unknown>) =>
  applicationsApiService.getApplications({ ...params, basics_only: 1 });

defineExpose({
  closeDropdown
});
</script>

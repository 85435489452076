export const autoLink = (
  text: string,
  options?: { target?: "_self" | "_blank" | "_parent" | "_top"; rel?: string }
) => {
  const target = options?.target || "_blank";
  const rel = options?.rel || "noopener noreferrer";
  // capture url patterns for either markdown style links or plain text urls
  const urlPattern =
    /(?:\[(.*?)\]\((.*?)\))|(?:^|\s)((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;
  return text.replace(urlPattern, (match, p1, p2, p3) => {
    const url = p3 || p2;
    const label = p1 || url;
    return `${
      p3 ? " " : ""
    }<a href="${url}" target="${target}" rel="${rel}">${label}</a>`;
  });
};

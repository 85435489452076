<template>
  <g clip-path="url(#email)">
    <path
      d="M14.3333 1.33301H1.66667C0.748 1.33301 0 2.08101 0 2.99967V12.9997C0 13.9183 0.748 14.6663 1.66667 14.6663H14.3333C15.252 14.6663 16 13.9183 16 12.9997V2.99967C16 2.08101 15.252 1.33301 14.3333 1.33301ZM14.6667 6.27301L8.92667 9.27301C8.64 9.41967 8.32 9.49967 8 9.49967C7.68 9.49967 7.36667 9.41967 7.07333 9.27301L1.33333 6.27301V4.76634L7.68667 8.08634C7.88 8.18634 8.12 8.18634 8.31333 8.08634L14.6667 4.76634V6.27301Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="email">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>

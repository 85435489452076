<template>
  <log-quick-submit
    v-model="comment"
    :placeholder="$t('ACTIVITY.COMMENTS.COMMENT_PLACEHOLDER')"
    :return-placeholder="$t('ACTIVITY.COMMENTS.RETURN_PLACEHOLDER')"
    :loading="loading"
    @submit="submitComment"
  >
    <template #actionBar>
      <avatar-with-name
        :user="currentUser || {}"
        data-cy="log-comment-author"
      />
    </template>
  </log-quick-submit>
</template>

<script lang="ts">
import { inject, ref } from "vue";
import { usePromiseWrapper } from "@/hooks/common";
import { useAuth } from "@/hooks/auth";
import { useCommunicationStore } from "@/stores/communication";
import { CommunicationLogContextKey } from "@/helpers/symbols";

import LogQuickSubmit from "@/components/communicationLogs/forms/LogQuickSubmit.vue";
import AvatarWithName from "@/components/ui/AvatarWithName.vue";
</script>

<script setup lang="ts">
const { currentUser } = useAuth();
const { createComment } = useCommunicationStore();

const context = inject(CommunicationLogContextKey, {
  type: null,
  logId: null
});

const comment = ref("");

const { loading, fetchWrapper: submitComment } = usePromiseWrapper(async () => {
  if (!comment.value || !context.type || !context.logId) {
    return;
  }
  await createComment({
    comment: comment.value,
    type: context.type,
    logId: context.logId
  });
  comment.value = "";
});
</script>

<template>
  <view-wrapper class="space-y-6">
    <page-title
      :title="
        t('ORGANIZATION.COMMUNICATION_TEMPLATES.EMAIL.TITLE', { count: 2 })
      "
      :description="t('ORGANIZATION.COMMUNICATION_TEMPLATES.EMAIL.SUBTITLE')"
    >
      <template #action>
        <div class="flex space-x-4 h-10">
          <div>
            <search-input v-model="filters.search" no-padding />
          </div>
          <view-switch v-model="activeView" />
          <primary-button @click="handleNewClick">
            {{ $t("COMMON.NEW_TEMPLATE") }}
          </primary-button>
        </div>
      </template>
    </page-title>
    <div>
      <loader :is-loading="loading" />
      <div class="relative">
        <lf-table
          v-if="activeView === VIEW_TYPE_LIST"
          :columns="columns"
          :data="emailTemplates.data || []"
          :loading="loading"
          :select-all="showSelectAll"
          :has-select-mode-option="false"
          :is-select-mode-enabled="!showSelectAll"
          :show-multiselect-controls="showSelectAll"
          row-is-clickable
          @row-clicked="openTemplate"
          @multiselect:change="handleMultiselectChange"
          @selected-rows="handleSelectedRows"
        />
        <email-templates-tiles
          v-else
          :data="emailTemplates.data"
          :selected-ids="selectedIds"
          @update:selected-ids="handleSelectedRows"
        />
        <lf-pagination
          v-if="emailTemplates.meta"
          :metadata="emailTemplates.meta"
          show-per-page
          @change-page="changePage"
          @change-items-per-page="changePerPage"
        />
      </div>
    </div>
  </view-wrapper>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { usePromiseWrapper } from "@/hooks/common";
import useCustomizationStore from "@/stores/customization";
import { storeToRefs } from "pinia";
import {
  type VIEW_TYPE_TILE,
  VIEW_TYPE_LIST,
  DEFAULT_PER_PAGE,
  type TEMPLATE_STATUSES
} from "@/helpers/constants";
import {
  templateModes,
  COMMUNICATION_TEMPLATE_STATUSES,
  COMMUNICATION_TEMPLATE_STATUS_COLOR_CODES
} from "@/helpers/constants/communicationTemplates";
import { truncateWithEllipsis } from "@/helpers/formatting";
import PageTitle from "@/components/ui/PageTitle.vue";
import ViewSwitch from "@/components/ui/buttons/ViewSwitch.vue";
import LfPagination from "@/components/ui/table/LfPagination.vue";
import DateAndTimeDisplay from "@/components/ui/text/DateAndTimeDisplay.vue";
import TemplateActionMenu from "@/components/communicationTemplates/TemplateActionMenu.vue";
import EmailTemplatesTiles from "@/components/emailTemplates/EmailTemplatesTiles.vue";
import LfTable from "@/components/ui/table/LfTable.vue";
import type { TemplateViewParams, EmailTemplate } from "@/models/clients";
import { CommunicationType } from "@/enums/communicationLogs";
import {
  ROUTE_EMAIL_TEMPLATES_EDIT,
  ROUTE_EMAIL_TEMPLATES_NEW
} from "@/router/routes";
import { stripHTMLTagsFromString } from "@/helpers/common";
import TemplateUser from "@/components/communicationTemplates/TemplateUser.vue";
import TemplateName from "@/components/communicationTemplates/TemplateName.vue";
import ClickToCopyIdColumn from "@/components/ui/ClickToCopyIdColumn.vue";
import TemplateOrganizationBadge from "@/components/communicationTemplates/TemplateOrganizationBadge.vue";
import TemplateStatusDropdown from "@/components/ui/TemplateStatusDropdown.vue";
import { useStore } from "vuex";
import type { DynamicFieldOptions } from "@/models/options";
import isEmpty from "lodash/isEmpty";
import customizationApiService from "@/services/modules/customization";
import IconStatusFail from "@/components/icons/status/IconStatusFail.vue";
import IconStatusWarning from "@/components/icons/status/IconStatusWarning.vue";
import IconStatusSuccess from "@/components/icons/status/IconStatusSuccess.vue";
import TemplateStatusView from "@/views/profile/components/TemplateStatusView.vue";
import type { IDealsColumn } from "@/models/applications";
import compact from "lodash/compact";
import { useAuth } from "@/hooks/auth";

const MAX_TEXT_LENGTH = 50;

const router = useRouter();
const { t } = useI18n();
const store = useCustomizationStore();
const { getters, dispatch } = useStore();
const { isLendflowUser } = useAuth();

// select mode option in the design, just not part of v1
const showSelectAll = ref(false);
const selectedIds = ref<string[]>([]);
const activeView = ref<typeof VIEW_TYPE_TILE | typeof VIEW_TYPE_LIST>(
  VIEW_TYPE_LIST
);
const filters = ref<TemplateViewParams>({
  page: 1,
  per_page: DEFAULT_PER_PAGE,
  search: ""
});
const { emailTemplates } = storeToRefs(store);

const truncatedFormatter = (value: string) => {
  return truncateWithEllipsis(
    stripHTMLTagsFromString(value, false),
    MAX_TEXT_LENGTH
  );
};

const refreshEmailTemplates = async () => {
  await getEmailTemplates(filters.value);
};

const { fetchWrapper: updateTemplateStatus } = usePromiseWrapper(
  async (
    status: keyof typeof TEMPLATE_STATUSES,
    templateId: string | undefined
  ) => {
    if (!templateId) {
      return;
    }
    const template = emailTemplates.value.data.find(
      (template) => template.id === templateId
    );

    if (!template) {
      return;
    }

    await customizationApiService.updateEmailTemplate({
      ...template,
      approval_status: status
    });
    refreshEmailTemplates();
  }
);

const STATUS_ICONS = [IconStatusWarning, IconStatusSuccess, IconStatusFail];

const columns: IDealsColumn[] = compact([
  {
    label: t("COMMON.TEMPLATE_NAME"),
    key: "name",
    component: TemplateName,
    props: {
      type: CommunicationType.email
    }
  },
  {
    label: t("COMMON.DESCRIPTION"),
    key: "description",
    class: "max-w-52 line-clamp-2",
    formatter: truncatedFormatter
  },
  {
    label: t("COMMON.TEMPLATE_ID"),
    key: "id",
    component: ClickToCopyIdColumn,
    formatModel: true,
    formatter: (row) => String(row.id).substring(0, 5)
  },
  {
    label: t("COMMON.STATUS"),
    key: "status",
    component: TemplateStatusView,
    props: {
      statuses: templateModes
    }
  },
  isLendflowUser && {
    label: t("ORGANIZATION.TITLE"),
    key: "client.name",
    component: TemplateOrganizationBadge
  },
  {
    label: t("WORKFLOW.TABLE_HEADERS.APPROVAL_STATUS"),
    key: "approval_status",
    options: "workflow_templates.statuses",
    component: TemplateStatusDropdown,
    props: {
      handler: updateTemplateStatus,
      statusField: "approval_status",
      options: COMMUNICATION_TEMPLATE_STATUSES,
      colorCodes: COMMUNICATION_TEMPLATE_STATUS_COLOR_CODES,
      statusIcons: STATUS_ICONS,
      adminStatuses: ["0", "1", "2"],
      approveStatus: "1"
    }
  },
  {
    label: t("COMMON.CREATED_BY"),
    key: "user",
    component: TemplateUser
  },
  {
    label: t("COMMON.DATE_UPDATED"),
    key: "updated_at",
    component: DateAndTimeDisplay
  },
  {
    label: t("COMMON.ACTIONS"),
    key: "",
    component: TemplateActionMenu,
    props: {
      type: CommunicationType.email
    },
    eventHandlers: {
      "template:deleted": refreshEmailTemplates,
      "template:duplicated": refreshEmailTemplates,
      "template:updated": refreshEmailTemplates
    }
  }
]);

const {
  fetchWrapper: getEmailTemplates,
  loading,
  changePage,
  changePerPage
} = usePromiseWrapper(
  async (newFilters) => {
    const payload = { ...newFilters };
    if (payload.search === "") {
      delete payload.search;
    }
    await store.getEmailTemplates(payload);
  },
  { filters }
);

const dynamicFields = computed<DynamicFieldOptions | null>(
  () => getters["options/dynamicFields"]
);

const handleMultiselectChange = (value: boolean) => {
  showSelectAll.value = !value;
  selectedIds.value = [];
};

const handleSelectedRows = (selectedRows: EmailTemplate["id"][]) => {
  selectedIds.value = selectedRows;
};

const handleNewClick = () => {
  router.push({ name: ROUTE_EMAIL_TEMPLATES_NEW });
};

const openTemplate = async (id: string) => {
  if (!id) {
    return;
  }
  const pushPayload = {
    name: ROUTE_EMAIL_TEMPLATES_EDIT,
    params: {
      templateId: id
    }
  };

  await router.push(pushPayload);
};

watch(
  () => filters.value,
  (newValue) => {
    getEmailTemplates(newValue);
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  if (isEmpty(dynamicFields.value)) {
    dispatch("options/getDynamicFields");
  }
});

onUnmounted(() => {
  store.unsetEmailTemplates();
});
</script>

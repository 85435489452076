import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { LENDFLOW_ROLE_GROUP, PUBLIC_PAGES } from "@/helpers/constants";
import {
  ROUTE_ACTIVITY_HUB,
  ROUTE_DEAL_ACTIVITY,
  routes
} from "@/router/routes";
import * as Sentry from "@sentry/vue";
import {
  CATEGORY_NAVIGATION,
  MESSAGE_NAVIGATION
} from "@/helpers/constants/sentry";
import { updatePageTitle } from "@/helpers/common";
import type { AxiosError } from "axios";
import { routeAccessCheck } from "@/router/validations";
import { useCommunicationStore } from "@/stores/communication";

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  updatePageTitle(to.meta?.title);
  let user = store.state.auth.user;
  const isVerifyingOrResetting =
    !from.name &&
    ["CreatePassword", "ResetPassword"].includes(to.name as string);

  if (isVerifyingOrResetting) {
    await store.dispatch("auth/logout");
    return next();
  }

  const authRequired = !PUBLIC_PAGES.includes(to.name as string);
  const isAuthenticated = !!store.state.auth.accessToken;
  const isFundingPartnersPath = to.fullPath.includes("funding-partners");

  if (authRequired && !isAuthenticated) {
    if (isFundingPartnersPath) {
      return next("/funding-partners");
    }
    return next("/login");
  }

  if (isAuthenticated) {
    // Authenticated users should not be allowed to access public pages other than ClosingDocs
    if (!authRequired && !to.path.includes("/closing-docs")) {
      return next("/");
    }

    if (!user) {
      store.commit("setGlobalLoader", true);
      try {
        await store.dispatch("auth/enrichUserData");
        user = store.state.auth.user;
      } catch (error) {
        const err = error as AxiosError;
        store.commit("setGlobalMessage", {
          title: err.config?.data?.message || "Unauthenticated",
          type: "error"
        });
      } finally {
        store.commit("setGlobalLoader", false);
      }
    }

    const { acl } = to.meta || {};

    if (acl && !user?.roles?.some((role) => acl.includes(role))) {
      store.commit("setGlobalMessage", {
        title: "You don't have permission to do this action",
        type: "error"
      });
      return next(from.path);
    }

    type ActivityHubRoute =
      | typeof ROUTE_ACTIVITY_HUB
      | typeof ROUTE_DEAL_ACTIVITY;

    if (
      [ROUTE_ACTIVITY_HUB, ROUTE_DEAL_ACTIVITY].includes(
        to.name as ActivityHubRoute
      )
    ) {
      if (
        to.name !== from.name &&
        to.name === ROUTE_DEAL_ACTIVITY &&
        !user?.roles.some((role) => LENDFLOW_ROLE_GROUP.includes(role))
      ) {
        const communicationStore = useCommunicationStore();
        await communicationStore.getActivityHubInfo(to.params.id as string);
      }
      const { canUserRedirectToActivityHub } = routeAccessCheck(to);
      if (!canUserRedirectToActivityHub) {
        store.commit("setGlobalMessage", {
          title: "You don't have permission to do this action",
          type: "error"
        });
        return next(from.path);
      }
    }
  }

  if (from.matched.length && from.name !== "Login") {
    const { query, name, path } = from;
    store.commit("setPreviousFullRoute", { query, name, path });
  }

  const options = to?.meta?.options;

  if (options) {
    if (from.path === to.path) {
      return next();
    }

    // The route switch delay is most noticeable when the user navigates to a deal page (~800 - ~1500ms).
    // To prevent undesired behaviour with the loader, let's show it only when going to deal page.

    if (!from.path.includes("/deals/") && to.path.includes("/deals/")) {
      store.commit("setGlobalLoader", true);
    }

    try {
      if (options.includes("application")) {
        await store.dispatch("options/getApplicationOptions");
      }
      if (options.includes("workflow_templates")) {
        await store.dispatch("options/getWorkflowTemplatesOptions");
      }
      if (options.includes("email_templates")) {
        await store.dispatch("options/getDynamicFields");
      }
    } catch {
      store.commit("setGlobalLoader", false);
    }
  }

  return next();
});

router.afterEach((to, from) => {
  if (store.state.globalLoader) {
    store.commit("setGlobalLoader", false);
  }

  Sentry.addBreadcrumb({
    category: CATEGORY_NAVIGATION,
    message: MESSAGE_NAVIGATION.replace(":from", from.path).replace(
      ":to",
      to.path
    )
  });
});
export default router;

<template>
  <email-filter
    :options="smsActivityTypeOptions"
    :title="t('COMMON.TYPE')"
    placeholder-key="ACTIVITY.FILTERS.TYPE"
    :render-component="SmsTypeOption"
    query-key="sms_types[]"
    is-multiple
  />
</template>
<script setup lang="ts">
import EmailFilter from "@/components/communicationLogs/filters/emailFilters/EmailFilter.vue";
import SmsTypeOption from "@/components/communicationLogs/filters/smsFilters/SmsTypeOption.vue";
import { useI18n } from "vue-i18n";
import { smsActivityTypeOptions } from "@/helpers/constants/sms";

const { t } = useI18n();
</script>

<template>
  <lf-tag
    v-if="value"
    v-tooltip="$t('COMMON.CLICK_TO_COPY')"
    class="bg-white border border-divider cursor-pointer"
    @click.stop.prevent="copyToClipboard(modelValue)"
  >
    <div class="line-clamp-1 min-w-max">
      {{ value }}
    </div>
  </lf-tag>
</template>

<script setup lang="ts" generic="T extends { id: string | number }">
import type { PropType } from "vue";
import { useClipboardWithMessage } from "@/hooks/clipboard";
import LfTag from "@/components/ui/text/LfTag.vue";
defineProps({
  // use the value from the formatter to show short ID if desired
  // use the modelValue to copy the full ID
  value: {
    type: String,
    default: ""
  },
  modelValue: {
    type: String,
    default: ""
  },
  model: {
    type: Object as PropType<T>,
    required: true
  }
});

const copyToClipboard = useClipboardWithMessage();
</script>

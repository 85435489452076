<template>
  <div class="flex w-full justify-between items-center">
    <div class="flex items-center space-x-2">
      <icon-base :icon="iconInfo.icon" :class="iconInfo.color" />
      <span class="text-headline">
        {{ smsActivityStatusOptions[modelValue] }}
      </span>
    </div>
    <icon-base v-if="isSelected" :icon="IconSent" class="text-blue-500" />
  </div>
</template>
<script setup lang="ts">
import type { SMS_ACTIVITY_STATUSES } from "@/helpers/constants/sms";

import IconBounced from "@/components/icons/email/IconBounced.vue";
import IconSent from "@/components/icons/email/IconSent.vue";
import IconDelivered from "@/components/icons/email/IconDelivered.vue";
import IconIncomingArrow from "@/components/icons/IconIncomingArrow.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed } from "vue";
import { smsActivityStatusOptions } from "@/helpers/constants/sms";
import type { Component } from "vue";

interface Props {
  modelValue: (typeof SMS_ACTIVITY_STATUSES)[number];
  selected: string[];
}

const options: Record<
  (typeof SMS_ACTIVITY_STATUSES)[number],
  { icon: Component; color?: string }
> = {
  delivered: {
    icon: IconDelivered,
    color: "text-blue-500"
  },
  failed: {
    icon: IconBounced,
    color: "text-error"
  },
  received: {
    icon: IconIncomingArrow,
    color: "text-green-500"
  }
};

const props = defineProps<Props>();

const isSelected = computed(() => !!props.selected?.includes(props.modelValue));

const iconInfo = computed(() => options[props.modelValue]);
</script>

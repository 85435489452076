import i18n from "@/i18n";

export const templateModes = {
  0: i18n.global.t("COMMON.DRAFT").toUpperCase(),
  1: i18n.global.t("COMMON.PUBLISHED").toUpperCase()
} as const;

export const COMMUNICATION_TEMPLATE_STATUSES = {
  0: "Awaiting Approval",
  1: "Approved",
  2: "Declined"
} as const;

export const COMMUNICATION_TEMPLATE_STATUS_COLOR_CODES = {
  0: 5,
  1: 0,
  2: 2
} as const;

export const MAX_SMS_MESSAGE_LENGTH = 160 as const;

import store from "@/store";

function formatResponseErrors(errors) {
  const formattedErrors = {};

  Object.keys(errors || {}).forEach((key) => {
    formattedErrors[key] = Array.isArray(errors[key])
      ? errors[key][0]
      : errors[key];
  });

  return formattedErrors;
}

const dispatchAction = async (
  values,
  actions,
  actionName,
  vueStore = store
) => {
  try {
    return await vueStore.dispatch(actionName, values, {
      root: true
    });
  } catch (error) {
    const formattedErrors = formatResponseErrors(error.response?.data?.errors);
    actions.setErrors(formattedErrors);

    console.error(error);
    const err = new Error(error);
    err.formattedErrors = formattedErrors;
    err.message = error.response?.data?.message || "";
    err.request = error.request;
    throw err;
  }
};

const callStoreAction = async (values, actions, actionName, store) => {
  try {
    return await store[actionName](values);
  } catch (error) {
    const formattedErrors = formatResponseErrors(error.response.data.errors);
    actions.setErrors(formattedErrors);

    console.error(error);
    const err = new Error(error);
    err.formattedErrors = formattedErrors;
    throw err;
  }
};

export { callStoreAction, dispatchAction, formatResponseErrors };

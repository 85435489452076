<template>
  <g clip-path="url(#clip0_4303_142447)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.207688 11.7914C0.086278 11.6814 0.00681977 11.5266 0.00681978 11.3497L4.94281e-07 6.34609C5.23225e-07 6.015 0.266126 5.74681 0.595816 5.74738C0.912377 5.74792 1.18011 6.0176 1.44247 6.28188C1.45286 6.29234 1.46324 6.3028 1.47362 6.31323L2.73219 7.57873L9.96903 0.33348C10.4115 -0.110503 11.1304 -0.111256 11.5738 0.331798L11.6676 0.425589C12.1118 0.869423 12.1106 1.58969 11.665 2.03205L4.41011 9.26605L5.66869 10.5315C5.67413 10.537 5.67981 10.5427 5.68571 10.5486C5.85871 10.7217 6.21797 11.0811 6.21852 11.4013C6.21852 11.7324 5.95183 12.0006 5.6227 12L0.64687 11.9932C0.470966 11.9926 0.31718 11.9133 0.207688 11.7914Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_4303_142447">
      <rect width="12" height="12" fill="currentColor" />
    </clipPath>
  </defs>
</template>

<template>
  <div
    class="w-full my-1 text-sm text-gray-600 cursor-pointer"
    :class="editingNote ? 'highlight' : 'group'"
  >
    <loader :is-loading="loading" />
    <div class="flex space-x-3">
      <note-type-icon :note="note" />
      <div class="w-full bg-white shadow-main rounded note-content">
        <div class="justify-between flex px-6 pt-4">
          <div class="flex items-center w-full space-x-2 justify-between">
            <span
              v-if="showStatusDescription"
              v-tooltip="{
                content: statusDescription,
                placement: 'left',
                onShow: () => statusDescription.length > 30
              }"
              class="whitespace-nowrap max-w-3/4 text-ellipsis overflow-hidden rounded-full text-xxs uppercase py-1 px-2-5"
              :style="getPillStyle({ color: COLORS[colorCode] })"
            >
              {{ statusDescription }}
            </span>
            <action-menu-wrapper
              v-if="menuItems.length"
              :items="menuItems"
              class="action-menu"
              :teleport="dealNotesOptions.state !== NoteState.Draggable"
              :offset="offset"
            />
          </div>
        </div>
        <div
          class="note-body w-full break-long-words text-gray-700 px-6 pb-4 pt-3"
          v-html="autoLink(adaptedNote.comment)"
        />
        <div
          class="note-footer text-xs flex items-center text-gray-400 border-t px-6 py-2"
        >
          <avatar-with-name :user="adaptedNote.user" />

          <span class="px-1 separator">•</span>
          <span class="updated-date">
            {{ formatDate(adaptedNote.created_at) }}
          </span>
          <div class="flex items-center space-x-2">
            <seen-note-button
              :seen-by="note.read_notes || []"
              class="ml-0.5 seen-note-button"
              data-testid="seen-notes-button"
            />
            <icon-base
              v-tooltip="tooltipText"
              height="12"
              width="12"
              view-box="0 0 16 16"
              :icon="buttonIcon"
              icon-color="gray"
              class="-mt-2"
              :data-testid="buttonIcon"
            />

            <icon-base
              v-if="!isApplicationNote && showVisibilityIcon"
              height="20"
              width="20"
              view-box="0 0 20 14"
              icon="show-pass-no-bg"
              class="-mt-1 text-gray-500"
              data-testid="visibility-icon"
            />
            <icon-base
              v-if="isApplicationNote && adaptedNote.visible_in_deal_list"
              v-tooltip="$t('DEALS.DEAL_NOTES.VISIBLE_IN_DEALS_LIST')"
              data-cy="visible-in-deals-list"
              :icon="IconListView"
              view-box="0 0 24 24"
              class="-mt-1-5 text-gray-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  NOTE_VISIBILITY_SCOPE,
  ALL_NOTE_VISIBILITY_TYPES,
  NOTE_VISIBILITY_SCOPES,
  STATUS_COLOR_CODES,
  COLORS
} from "@/helpers/constants";
import { useStore } from "vuex";
import { ref, computed, toRef } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useApplicationsStore from "@/stores/applications";
import { useClipboardWithMessage } from "@/hooks/clipboard";
import { useAuth } from "@/hooks/auth";
import { WORKFLOW_TO_APP_STATUS } from "@/helpers/constants/workflow";
import { getPillStyle } from "@/helpers/UI";
import { autoLink } from "@/helpers/elements";

import type { PropType } from "vue";
import type { ApplicationStatus } from "@/models/applications";
import type { IFlowRecord, IWorkflowStage } from "@/models/workflows";
import type { primaryVisibilityScope } from "@/models/common";
import type { Note as NoteType } from "@/models/notes";
import { NotableType, NoteState } from "@/enums/notes";
import notesService from "@/services/modules/notes";
import { DEFAULT_DEAL_NOTES_OPTIONS } from "@/helpers/constants/deals";
import { useLocalStorageSetting } from "@/hooks/options";
</script>

<script setup lang="ts">
import { formatDate } from "@/helpers/formatting";
import { storeToRefs } from "pinia";
import SeenNoteButton from "@/views/deals/components/dealNotes/SeenNoteButton.vue";
import ActionMenuWrapper, {
  type MenuItem
} from "@/components/ui/ActionMenuWrapperV2.vue";
import IconPeople from "@/components/icons/IconPeople.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconStar from "@/components/icons/IconStar.vue";
import IconListView from "@/components/icons/IconListView.vue";
import NoteTypeIcon from "@/views/deals/components/dealNotes/NoteTypeIcon.vue";
import AvatarWithName from "@/components/ui/AvatarWithName.vue";
import type { Component } from "vue";

const iconMap: Record<string, Record<string, Component>> = {
  people: IconPeople,
  user: IconUser,
  star: IconStar
};

const props = defineProps({
  note: {
    type: Object as PropType<NoteType>,
    required: true
  },
  editingNote: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  "edit-note:set": [note: NoteType];
  "edit-note:unset": [];
  "note:deleted": [];
}>();

const offset = { left: -73, top: 43 };

const { getters } = useStore();
const { isSuperAdmin, isAdmin } = useAuth();
const route = useRoute();
const { t } = useI18n();
const applicationsStore = useApplicationsStore();
const copyToClipboard = useClipboardWithMessage();
const dealNotesOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const loading = ref(false);
const adaptedNote = toRef(props, "note");
const { canSeeNotes } = storeToRefs(applicationsStore);

const VISIBILITY_SCOPES_WITH_BUTTONS = [1, 3];
const OTHER_VISIBILITY_SCOPE_INDX = 99;

const flows = computed<IFlowRecord[]>(
  () => getters["workflows/activeTemplateFlow"]
);

const stages = computed<IWorkflowStage[]>(
  () => getters["workflows/activeTemplateStages"]
);

const callDispositions = computed<Record<string, string>>(
  () => getters["options/callDispositions"]
);

const isApplicationNote = computed(() => noteType.value === "application");

const isCurrentUserNote = computed(
  () => getters["auth/user"]?.id === adaptedNote.value.user?.id
);

const noteFlow = computed(() =>
  flows.value.find((flow) => flow.id === props.note.stage)
);
const noteStage = computed(() =>
  stages.value.find((stage) => stage.id === noteFlow.value?.stage_id)
);
const noteType = computed(() => NotableType[props.note.notable_type]);

const colorCode = computed(() => {
  if (noteType.value === "call") {
    return 0;
  }
  if (noteType.value === "business") {
    return 1;
  }
  const stageType = noteStage.value?.type;
  if (stageType === undefined) {
    return 0;
  }
  const statusCode =
    WORKFLOW_TO_APP_STATUS[stageType as keyof typeof WORKFLOW_TO_APP_STATUS];
  return STATUS_COLOR_CODES[statusCode as ApplicationStatus];
});

const statusDescription = computed(() => {
  if (noteType.value === "application") {
    return noteFlow.value?.name || "";
  }
  if (noteType.value === "business") {
    return t("DEALS.NOTES.BUSINESS_NOTE");
  }
  if (noteType.value === "call" && props.note.call_disposition) {
    return callDispositions.value[props.note.call_disposition] || "";
  }
  return "";
});

const menuItems = computed<MenuItem[]>(() => {
  const items = [
    isCurrentUserNote.value || (isAdmin && noteType.value === "business")
      ? {
          label: t("COMMON.EDIT"),
          handler: editNote
        }
      : null,
    adaptedNote.value.id
      ? {
          label: t("COMMON.COPY_LINK"),
          handler: () => copyNoteLink(adaptedNote.value.id as number)
        }
      : null,
    isCurrentUserNote.value ||
    isSuperAdmin ||
    (isAdmin && noteType.value === "business")
      ? {
          label: t("COMMON.DELETE"),
          handler: deleteNote
        }
      : null
  ];

  if (props.editingNote) {
    items.shift();
    items.unshift({
      label: t("COMMON.CANCEL"),
      handler: () => emit("edit-note:unset")
    });
  }

  return items.filter(Boolean) as MenuItem[];
});

const showVisibilityIcon = computed(
  () =>
    canSeeNotes.value &&
    adaptedNote.value?.visibility_scope !== NOTE_VISIBILITY_SCOPE.ALL
);

const visibilityIconIndx = computed<primaryVisibilityScope>(() => {
  if (VISIBILITY_SCOPES_WITH_BUTTONS.includes(props.note.visibility_scope)) {
    return props.note.visibility_scope as primaryVisibilityScope;
  }
  return OTHER_VISIBILITY_SCOPE_INDX;
});

const buttonIcon = computed(
  () => iconMap[NOTE_VISIBILITY_SCOPES[visibilityIconIndx.value].icon]
);

const tooltipText = computed(
  () => `visibility: ${ALL_NOTE_VISIBILITY_TYPES[props.note.visibility_scope]}`
);

const showStatusDescription = computed(() => !!statusDescription.value);

const deleteNote = async () => {
  loading.value = true;
  await notesService.deleteNote(props.note.id);
  loading.value = false;
  emit("note:deleted");
};

const copyNoteLink = (id: number) => {
  const origin = location?.origin || "";
  copyToClipboard(`${origin}${route.path}?note=${id}`);
};

const editNote = async () => {
  emit("edit-note:set", props.note);
};
</script>

<style scoped>
.note-body :deep() {
  a {
    @apply text-primary underline;
  }
}
.note-content {
  container-type: inline-size;
}

.highlight .note-content {
  @apply bg-surface-user-blue border border-primary;
}

@container (max-width: 300px) {
  .note-footer {
    @apply flex-col space-y-1 items-start flex-wrap;
    .space-x-2 {
      @apply flex-shrink space-x-3;
    }
    .seen-note-button {
      @apply ml-0;
    }
  }
  .note-footer .separator {
    display: none;
  }
}

:deep() {
  .dropdown {
    @apply left-auto right-0 top-full;
  }
}
</style>

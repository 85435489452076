<template>
  <g clip-path="url(#clip0_1097_6783)">
    <circle cx="12" cy="12" r="12" fill="#EBEEF5" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12ZM10.3996 13.6001C7.74864 13.6001 5.59961 15.7492 5.59961 18.4001C5.59961 19.2838 6.31595 20.0001 7.19961 20.0001H16.7996C17.6833 20.0001 18.3996 19.2838 18.3996 18.4001C18.3996 15.7492 16.2506 13.6001 13.5996 13.6001H10.3996Z"
      fill="#868D9C"
    />
  </g>
  <defs>
    <clipPath id="clip0_1097_6783">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</template>

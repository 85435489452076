import type { ClearAdverseMediaReportSectionResults } from "@/models/commercialData/clear/ClearAdverseMediaReport";
import type {
  SearchRecord,
  SearchRecordAttribute
} from "@/models/commercialData/clear/ClearConfirmPersonSearch";
import type { SearchRecordAttribute as BusinessSearchRecordAttribute } from "@/models/commercialData/clear/ClearIdConfirmBusiness";
import type {
  BusinessProfileRecord,
  FederalEmployerIDRecord,
  PhoneRecord
} from "@/models/commercialData/clear/ClearIdConfirmBusiness";
import {
  CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_LOW,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES
} from "@/helpers/constants/clear";
import i18n from "@/i18n";

export const getSectionData = (
  data: ClearAdverseMediaReportSectionResults[] | undefined,
  sectionName: string
): ClearAdverseMediaReportSectionResults =>
  Object.values(data || {}).find(
    (value) => value.SectionName === sectionName
  ) || {};

export const getColorIndexForRelevance = (
  relevanceScore: number | string
): number =>
  Number(relevanceScore) >= CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH
    ? CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
        CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH
      ]
    : Number(relevanceScore) >= CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM
      ? CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
          CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM
        ]
      : CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
          CLEAR_ADVERSE_MEDIA_RELEVANCE_LOW
        ];

export const getAttributeText = (
  value:
    | string
    | SearchRecordAttribute
    | BusinessSearchRecordAttribute
    | undefined
) => {
  if (typeof value === "string") {
    return value;
  }
  return value?.["@text"] || "-";
};

export const getFullNameFromSearchRecord = (record: SearchRecord) => {
  const { FirstName, LastName } = record;
  const types = [FirstName, LastName].map((name) => typeof name);
  if (types.every((type) => type === "string")) {
    return `${FirstName} ${LastName}`;
  }
  if (types.every((type) => type === "object")) {
    return `${(FirstName as SearchRecordAttribute)["@text"]} ${
      (LastName as SearchRecordAttribute)["@text"]
    }`;
  }
  if (types[0] === "string" && types[1] === "object") {
    return `${FirstName} ${(LastName as SearchRecordAttribute)["@text"]}`;
  }
  return `${(FirstName as SearchRecordAttribute)["@text"]} ${LastName}`;
};

export const getSubmittedDataValue = (value: string | string[] | undefined) => {
  if (!value) {
    return "-";
  }
  if (Array.isArray(value)) {
    return value.filter(Boolean).join(", ") || "-";
  }
  return value || "-";
};

export const prepareBusinessProfileRecord = (data: BusinessProfileRecord) => {
  return {
    title: i18n.global.t(
      "DEALS.CLEAR_DOCUMENT_RETRIEVAL.BUSINESS_PROFILE_RECORD"
    ),
    data: [
      {
        title: i18n.global.t("APPLICATION_STAGES.BUSINESS_INFORMATION"),
        data: [
          {
            label: i18n.global.t("COMMON.BUSINESS_NAME"),
            value: data?.BusinessInfo?.BusinessName || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("COMMON.STREET"),
            value: data?.BusinessInfo?.Address?.Street || "-"
          },
          {
            label: i18n.global.t("COMMON.CITY"),
            value: data?.BusinessInfo?.Address?.City || "-"
          },
          {
            label: i18n.global.t("COMMON.STATE"),
            value: data?.BusinessInfo?.Address?.State || "-"
          },
          {
            label: i18n.global.t("COMMON.ZIP"),
            value: data?.BusinessInfo?.Address?.ZipCode || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t(
              "DEALS.CLEAR_DOCUMENT_RETRIEVAL.PRIMARY_ISO_COUNTRY_CODE"
            ),
            value: data?.BusinessInfo?.PrimaryISOCountryCode || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("COMMON.PHONE"),
            value: data?.BusinessInfo?.PhoneInfo?.PhoneNumber || "-"
          },
          {
            label: i18n.global.t("COMMON.WEBSITE"),
            value: data?.BusinessInfo?.WebAddress || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("COMMON.TITLE"),
            value: data?.BusinessInfo?.ContactInfo?.Title || "-"
          },
          {
            label: i18n.global.t("COMMON.CONTACT"),
            value: data?.BusinessInfo?.ContactInfo?.Contact || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t(
              "DEALS.CLEAR_DOCUMENT_RETRIEVAL.EXECUTIVE_TITLE"
            ),
            value: data?.BusinessInfo?.ExecInfo?.ExecutiveTitle || "-"
          },
          {
            label: i18n.global.t(
              "DEALS.CLEAR_DOCUMENT_RETRIEVAL.EXECUTIVE_NAME"
            ),
            value: data?.BusinessInfo?.ExecInfo?.ExecutiveName || "-"
          }
        ]
      },
      {
        title: i18n.global.t("DEALS.CLEAR_KYB.SIC_AND_NAICS"),
        data: [
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.PRIMARY_SIC_CODE"),
            value: data?.SICAndNAICS?.PrimarySIC?.SICCode || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.PRIMARY_SIC"),
            value: data?.SICAndNAICS?.PrimarySIC?.SICDesc || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SECONDARY_SIC_CODE"),
            value: data?.SICAndNAICS?.SecondarySIC?.SICCode || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SECONDARY_SIC"),
            value: data?.SICAndNAICS?.SecondarySIC?.SICDesc || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.PRIMARY_NAICS_CODE"),
            value: data?.SICAndNAICS?.PrimaryNAICS?.NAICSCode || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.PRIMARY_NAICS"),
            value: data?.SICAndNAICS?.PrimaryNAICS?.NAICSDesc || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SECONDARY_NAICS_CODE"),
            value: data?.SICAndNAICS?.SecondaryNAICS?.NAICSCode || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SECONDARY_NAICS"),
            value: data?.SICAndNAICS?.SecondaryNAICS?.NAICSDesc || "-"
          }
        ]
      },
      {
        title: i18n.global.t(
          "APPLICATION_STAGES.ADDTIONAL_BUSINESS_INFORMATION"
        ),
        data: [
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.YEAR_ESTABLISHED"),
            value: data?.AdditionalBusinessInfo?.YearEstablished || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.ACTUAL_NUMBER_EMPLOYEES"),
            value: data?.AdditionalBusinessInfo?.ActualNumberEmployees || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.TOTAL_SALES"),
            value: data?.AdditionalBusinessInfo?.TotalSales || "-"
          },
          {
            label: i18n.global.t(
              "DEALS.CLEAR_KYB.BUSINESS_PROFILE_RECORD_UPDATE_DATE"
            ),
            value: data?.AdditionalBusinessInfo?.RecordUpdateDate || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.CORPORATE_FILING_NUMBER"),
            value: data?.AdditionalBusinessInfo?.CorporateFilingNumber || "-",
            class: "border-b pb-4"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE"),
            value: data?.Source || "-"
          },
          {
            label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE_DOCUMENT_GUID"),
            value: data?.SourceDocumentGuid || "-"
          }
        ]
      }
    ]
  };
};
export const preparePhoneRecord = (data: PhoneRecord) => {
  return {
    title: i18n.global.t("DEALS.CLEAR_KYB.PHONE_RECORD"),
    data: [
      {
        label: i18n.global.t("COMMON.NAME"),
        value: data?.Name || "-",
        class: "border-b pb-4"
      },
      {
        label: i18n.global.t("COMMON.PHONE"),
        value: data?.PhoneNumber || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.PHONE_TYPE"),
        value: data?.PhoneType || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.RECORD_TYPE"),
        value: data?.RecordType || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.FIRST_REPORTED_DATE"),
        value: data?.FirstReportedDate || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.LAST_REPORTED_DATE"),
        value: data?.LastReportedDate || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.ORIGINAL_SERVICE_PROVIDER"),
        value: data?.OriginalServiceProvider || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.PHONE_CONFIDENCE_SCORE"),
        value: data?.PhoneConfidenceScore || "-",
        class: "border-b pb-4"
      },
      {
        label: i18n.global.t("COMMON.STREET"),
        value: data?.Address?.Street || "-"
      },
      {
        label: i18n.global.t("COMMON.CITY"),
        value: data?.Address?.City || "-"
      },
      {
        label: i18n.global.t("COMMON.STATE"),
        value: data?.Address?.State || "-"
      },
      {
        label: i18n.global.t("COMMON.ZIP"),
        value: data?.Address?.ZipCode || "-"
      },
      {
        label: i18n.global.t("COMMON.COUNTY"),
        value: data?.Address?.County || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.TYPE_OF_ADDRESS"),
        value: data?.Address?.TypeOfAddress || "-",
        class: "border-b pb-4"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE"),
        value: data?.Source || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE_DOCUMENT_GUID"),
        value: data?.SourceDocumentGuid || "-"
      }
    ]
  };
};

export const prepareFeinData = (data: FederalEmployerIDRecord) => {
  return {
    title: i18n.global.t("COMMON.FEIN"),
    data: [
      {
        label: i18n.global.t("COMMON.STREET"),
        value: data?.BusinessInfo?.Address?.Street || "-"
      },
      {
        label: i18n.global.t("COMMON.CITY"),
        value: data?.BusinessInfo?.Address?.City || "-"
      },
      {
        label: i18n.global.t("COMMON.STATE"),
        value: data?.BusinessInfo?.Address?.State || "-"
      },
      {
        label: i18n.global.t("COMMON.ZIP"),
        value: data?.BusinessInfo?.Address?.ZipCode || "-",
        class: "border-b pb-4"
      },
      {
        label: i18n.global.t("COMMON.FEIN"),
        value: data?.BusinessInfo?.FederalEmpID || "-"
      },
      {
        label: i18n.global.t("COMMON.BUSINESS_NAME"),
        value: data?.BusinessInfo?.BusinessName || "-",
        class: "border-b pb-4"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE"),
        value: data?.Source || "-"
      },
      {
        label: i18n.global.t("DEALS.CLEAR_KYB.SOURCE_DOCUMENT_GUID"),
        value: data?.SourceDocumentGuid || "-"
      }
    ]
  };
};

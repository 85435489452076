<template>
  <div v-if="content" :class="{ 'shadow-sm': isShowLessButtonVisible }">
    <div
      ref="textContainerRef"
      class="border rounded-t-md border-divider p-4 relative whitespace-pre-wrap"
      :class="[
        isLoadMoreButtonVisible ? 'max-h-30 pb-0 overflow-hidden' : 'pb-4',
        { 'rounded-b-md shadow-sm': !isShowLessButtonVisible }
      ]"
    >
      <div
        class="text-headline break-word"
        data-cy="email-content"
        v-html="content"
      />
      <div
        v-if="isLoadMoreButtonVisible"
        class="bg-show-more-white-gradient absolute bottom-0 left-0 w-full flex justify-center items-center pt-4 pb-2 text-primary space-x-2 cursor-pointer"
        @click="handleShouldLoadMoreButtonClick"
      >
        <icon-base
          :icon="IconAdd"
          width="20"
          height="20"
          class="transform scale-75"
        />
        <span>{{ $t("COMMON.SHOW_MORE") }}</span>
        <icon-base
          :icon="IconAdd"
          width="20"
          height="20"
          class="transform scale-75"
        />
      </div>
    </div>
    <div
      v-if="isShowLessButtonVisible"
      class="border-t-0 border rounded-b-md w-full flex justify-center items-center p-2 text-primary space-x-2 cursor-pointer"
      @click="handleShouldShowLoadLessButtonClick"
    >
      <icon-base
        :icon="IconAdd"
        width="20"
        height="20"
        class="transform scale-75"
      />
      <span>{{ $t("COMMON.SHOW_LESS") }}</span>
      <icon-base
        :icon="IconAdd"
        width="20"
        height="20"
        class="transform scale-75"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import IconAdd from "@/components/icons/deals/IconAdd.vue";

defineProps<{ content: string }>();

const textContainerRef = ref<HTMLDivElement | null>(null);
const shouldShowLoadMoreButton = ref(true);
const shouldShowLoadLessButton = ref(false);

const isContentTooLong = computed(() => {
  const textContent = textContainerRef.value;
  const initialMaxBlockHeight = 110;

  return textContent && textContent.scrollHeight > initialMaxBlockHeight;
});

const isLoadMoreButtonVisible = computed(() => {
  return isContentTooLong.value && shouldShowLoadMoreButton.value;
});

const isShowLessButtonVisible = computed(() => {
  return isContentTooLong.value && shouldShowLoadLessButton.value;
});

const handleShouldShowLoadLessButtonClick = () => {
  shouldShowLoadMoreButton.value = true;
  shouldShowLoadLessButton.value = false;
};

const handleShouldLoadMoreButtonClick = () => {
  shouldShowLoadMoreButton.value = false;
  shouldShowLoadLessButton.value = true;
};
</script>

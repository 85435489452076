<template>
  <div>
    <div class="relative">
      <circle-progress
        :size="size"
        :border-width="borderWidth"
        :border-bg-width="borderBgWidth"
        :percent="percentLoaded"
        :fill-color="reversedColor ? reversedFillColor : fillColor"
      />
      <span
        v-if="showValue"
        class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        :class="[
          { 'text-xxs': textSmall },
          { 'top-1/2': !textStyles.includes('top-') },
          textStyles
        ]"
      >
        {{ customValue || value }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import CircleProgress from "vue3-circle-progress";

const props = defineProps({
  value: {
    type: Number,
    required: true
  },
  maxValue: {
    type: Number,
    default: 100
  },
  size: {
    type: Number,
    default: 80
  },
  borderWidth: {
    type: Number,
    default: 5
  },
  borderBgWidth: {
    type: Number,
    default: 5
  },
  textSmall: {
    type: Boolean,
    default: false
  },
  reversedColor: {
    type: Boolean,
    default: false
  },
  textStyles: {
    type: String,
    default: ""
  },
  showValue: {
    type: Boolean,
    default: true
  },
  customColor: {
    type: String,
    default: ""
  },
  customValue: {
    type: String,
    default: ""
  }
});

const percentLoaded = computed(() =>
  Math.round((props.value / props.maxValue) * 100)
);
const fillColor = computed(() => {
  if (props.customColor) {
    return props.customColor;
  }
  const val = percentLoaded.value;
  if (val >= 75) {
    return "#ff0000"; // red
  }
  if (val >= 50) {
    return "#ffcc00"; // yellow
  }
  if (val < 50) {
    return "#15ca39"; // green
  }
  return "";
});

const reversedFillColor = computed(() => {
  const val = percentLoaded.value;
  if (val < 50) {
    return "#ff0000"; // red
  }
  if (val >= 75) {
    return "#15ca39"; // green
  }
  if (val >= 50) {
    return "#ffcc00"; // yellow
  }
  return "";
});
</script>

<template>
  <lf-tag>
    {{ status }}
  </lf-tag>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed } from "vue";
import type { DataOrchestrationStatus } from "@/models/orchestration";
import { useI18n } from "vue-i18n";
import LfTag from "@/components/ui/text/LfTag.vue";

const props = defineProps({
  value: {
    type: Number,
    required: true
  },
  statuses: {
    type: Object as PropType<DataOrchestrationStatus>,
    required: true
  }
});

const { t } = useI18n();

const status = computed(
  () => props.statuses[props.value] ?? t("COMMON.DRAFT").toUpperCase()
);
</script>

<template>
  <div class="relative">
    <component
      :is="$slots.trigger ? 'button' : IconButton"
      v-on-click-outside="() => (showMenu = false)"
      :disabled="disabled"
      data-cy="action-menu-button"
      :icon="iconType === 'vertical' ? 'vertical-dots' : 'horizontal-dots'"
      @click.stop.prevent="toggleMenu"
    >
      <slot v-if="$slots.trigger" name="trigger" :show-menu="showMenu" />
      <icon-base
        v-else
        class="place-self-center my-1"
        height="24"
        width="24"
        :icon="iconType === 'vertical' ? 'vertical-dots' : 'horizontal-dots'"
      />
    </component>
    <ul
      v-if="teleportMenu && showMenu"
      ref="teleportedDropdown"
      class="dropdown z-100"
      data-cy="action-menu-list"
    >
      <slot />
    </ul>
    <ul
      v-else
      v-show="showMenu"
      class="dropdown z-100"
      data-cy="action-menu-list"
    >
      <slot />
    </ul>
  </div>
</template>

<script lang="ts">
// The reason behind duplicating the ActionMenu into this component is because
// the ActionMenu relied on the legacy way of teleporting an element and tracking
// the position of it's parent. ActionMenu is used widely in the app and it relies
// on the "top" and "left" props, also some other params which have effect on the
// teleported element. This component doesn't (in case of teleport). To avoid
// having breaking changes, it was easier to have a component that sticks to the
// new teleport system and slowly migrate away from the legacy one.
import { ref, type Ref, type PropType } from "vue";
import { useTeleportV2 } from "@/hooks/elements";
import type { Offset } from "@/models/common";
import IconButton from "@/components/ui/buttons/IconButton.vue";
</script>

<script setup lang="ts">
const props = defineProps({
  iconType: {
    type: String,
    default: "vertical"
  },
  teleportMenu: {
    type: Boolean,
    default: false
  },
  offset: {
    type: Object as PropType<Offset>,
    default: () => ({})
  },
  disabled: {
    default: false
  },
  checkForOverflow: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  "menu:toggle": [boolean];
}>();

const teleportedDropdown: Ref<HTMLUListElement | null> = ref(null);
const showMenu = ref(false);

const toggleMenu = () => {
  if (!showMenu.value) {
    document.body.click(); // close any open menus
  }
  showMenu.value = !showMenu.value;
  emit("menu:toggle", showMenu.value);
};

if (props.teleportMenu) {
  useTeleportV2({
    target: teleportedDropdown,
    to: "#menus",
    offset: props.offset,
    checkForOverflow: props.checkForOverflow
  });
}
</script>

<style scoped>
.dropdown {
  @apply z-100 absolute bg-white shadow-md rounded-md text-sm text-gray-500 focus:outline-none sm:text-sm w-max p-2 overscroll-none;
}
</style>

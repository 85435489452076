<template>
  <action-menu-wrapper :items="options" :offset="offset" teleport />
  <confirm-modal
    v-if="showDeleteModal"
    :title="
      $t('ORGANIZATION.COMMUNICATION_TEMPLATES.DELETE_TITLE', {
        type: typeMap[type].title
      })
    "
    :description="
      $t('ORGANIZATION.COMMUNICATION_TEMPLATES.DELETE_DESCRIPTION', {
        type: typeMap[type].description
      })
    "
    :confirm-button="$t('COMMON.DELETE')"
    confirm-button-type="error"
    :close="() => (showDeleteModal = false)"
    @confirm="handleDelete"
  />
</template>

<script setup lang="ts">
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useClients } from "@/hooks/clients";
import { usePromiseWrapper } from "@/hooks/common";
import customizationApiService from "@/services/modules/customization";
import ActionMenuWrapper from "@/components/ui/ActionMenuWrapperV2.vue";
import {
  ROUTE_EMAIL_TEMPLATES_EDIT,
  ROUTE_SMS_TEMPLATES_EDIT
} from "@/router/routes";
import { useAuth } from "@/hooks/auth";
import { EmailTemplateVisibility } from "@/enums/emailCustomization";
import { CommunicationType } from "@/enums/communicationLogs";
import type { CommunicationTemplate } from "@/models/clients";
import type { MenuItem } from "@/components/ui/ActionMenuWrapperV2.vue";
import pick from "lodash/pick";

interface Props {
  model: CommunicationTemplate;
  type: CommunicationType.email | CommunicationType.sms;
}

const emit = defineEmits<{
  "template:deleted": [];
  "template:duplicated": [];
  "template:updated": [];
}>();

const { model, type } = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();
const { isClientAdmin, isAdmin } = useAuth();
const { activeClient } = useClients();
const showDeleteModal = ref(false);

const typeMap = {
  [CommunicationType.email]: {
    duplicate: customizationApiService.duplicateEmailTemplate,
    delete: customizationApiService.deleteEmailTemplate,
    update: customizationApiService.updateEmailTemplate,
    route: ROUTE_EMAIL_TEMPLATES_EDIT,
    title: t("ORGANIZATION.COMMUNICATION_TEMPLATES.EMAIL_CUSTOMIZATION"),
    description: t("ORGANIZATION.COMMUNICATION_TEMPLATES.EMAIL.TITLE")
  },
  [CommunicationType.sms]: {
    duplicate: customizationApiService.duplicateSmsTemplate,
    delete: customizationApiService.deleteSmsTemplate,
    update: customizationApiService.updateSmsTemplate,
    route: ROUTE_SMS_TEMPLATES_EDIT,
    title: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SMS.CUSTOMIZATION"),
    description: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SMS.TITLE")
  }
};

const typeMapKey = computed(() => typeMap[type]);
const widthOffset = computed(() =>
  model?.visibility === EmailTemplateVisibility.Organizational ? -118 : -154
);
const offset = computed(() => ({ left: widthOffset.value, top: 43 }));

const options = computed<Array<MenuItem>>(() => [
  { label: t("COMMON.EDIT"), handler: handleEdit },
  { label: t("COMMON.DUPLICATE"), handler: handleDuplicate },
  ...getVisibilityOptions(),
  { label: t("COMMON.DELETE"), handler: () => (showDeleteModal.value = true) }
]);

async function handleEdit() {
  const clientId = activeClient.value?.id;
  if (!model?.id) {
    return;
  }
  const pushPayload = {
    name: typeMapKey.value.route,
    params: {
      id: clientId,
      templateId: model.id
    }
  };

  await router.push(pushPayload);
}

const getVisibilityOptions = () => {
  const isAdminRole = isClientAdmin || isAdmin;

  const options: MenuItem[] = [];

  if (!isAdminRole) {
    return options;
  }

  const organizationalOption = {
    label: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SET_AS_ORGANIZATIONAL"),
    handler: () =>
      handleUpdate({ visibility: EmailTemplateVisibility.Organizational })
  };

  const globalOption = {
    label: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SET_AS_GLOBAL"),
    handler: () => handleUpdate({ visibility: EmailTemplateVisibility.Global })
  };

  const personalOption = {
    label: t("ORGANIZATION.COMMUNICATION_TEMPLATES.SET_AS_PERSONAL"),
    handler: () =>
      handleUpdate({ visibility: EmailTemplateVisibility.Personal })
  };

  if (model.visibility !== EmailTemplateVisibility.Personal) {
    options.push(personalOption);
  }

  if (model.visibility !== EmailTemplateVisibility.Organizational) {
    options.push(organizationalOption);
  }

  if (model.visibility !== EmailTemplateVisibility.Global && isAdmin) {
    options.push(globalOption);
  }

  return options;
};

const { fetchWrapper: handleDuplicate } = usePromiseWrapper(async () => {
  if (!model.id) {
    return;
  }
  await typeMapKey.value.duplicate(model.id);
  emit("template:duplicated");
});

const { fetchWrapper: handleDelete } = usePromiseWrapper(async () => {
  if (!model.id) {
    return;
  }
  await typeMapKey.value.delete(model.id);
  emit("template:deleted");
});

const { fetchWrapper: handleUpdate } = usePromiseWrapper(
  async (updateValues: Partial<CommunicationTemplate>) => {
    if (!model.id) {
      return;
    }

    const payload = {
      ...pick(model, ["id", "name", "content"]),
      visiblity: updateValues.visibility
    };
    await typeMapKey.value.update(payload);
    emit("template:updated");
  }
);
</script>

<template>
  <lf-checkbox-dropdown
    ref="checkboxDropdown"
    :get-paginated-options="getUsersByRole"
    :options="users"
    :placeholder="$t(placeholderKey, assigneeIdsValue?.length || 1)"
    api-query-param="search"
    class="min-w-full h-6-5 leading-none activity-hub-filter-dropdown z-[21]"
    dropdown-width-full
    enable-search
    name="assignee_ids"
    width-full
    no-margin
    sort
    :sorting-function="sortUsers"
    :model-value="selected || []"
    :render-component="UserOption"
    :render-component-options="renderComponentOptions"
    @menu:closed="selected = getArrayQueryValue(queryKey)"
    @update:model-value="handleModelValueUpdate"
    @loaded-more="updateAssigneeOptions($event)"
    @options-search="updateAssigneeOptions($event, true)"
  >
    <template #pre-search>
      <div class="flex items-center justify-between px-4 py-2">
        <lf-h4><slot></slot></lf-h4>
        <button class="text-error" @click="handleModelValueUpdate([])">
          {{ $t("COMMON.CLEAR") }}
        </button>
      </div>
    </template>
    <template #footer>
      <div class="border-t p-4">
        <button
          class="w-full apply-filters-button text-center py-[10px] rounded text-primary font-semibold"
          @click.stop="handleApplyFilter"
        >
          {{ $t("COMMON.APPLY") }}
        </button>
      </div>
    </template>
  </lf-checkbox-dropdown>
</template>
<script lang="ts">
import { computed, ref, watch } from "vue";
import { useAuth } from "@/hooks/auth";
import { useCommunicationLogs } from "@/hooks/communicationLogs";
import { useRouteQuery } from "@vueuse/router";
import { useRoute } from "vue-router";
import usersApiService from "@/services/modules/users";
import emailService from "@/services/modules/emails";

import type { IUser } from "@/models/users";
import { convertToArray } from "@/helpers/common";
import { usePromiseWrapper } from "@/hooks/common";

type UserType = "user" | "email_recipient";
</script>

<script setup lang="ts">
import LfCheckboxDropdown from "@/components/ui/inputs/LfCheckboxDropdown.vue";
import UserOption from "@/components/communicationLogs/filters/UserOption.vue";

const props = withDefaults(
  defineProps<{
    queryKey?: string;
    placeholderKey?: string;
    type?: UserType;
  }>(),
  {
    queryKey: "assignee_ids",
    placeholderKey: "ACTIVITY.FILTERS.ASSIGNEE.PLACEHOLDER",
    type: "user"
  }
);

const auth = useAuth();
const { handleDropdownValueUpdate, handleFiltering, getArrayQueryValue } =
  useCommunicationLogs();
const route = useRoute();

const assigneeIdsValue = useRouteQuery(props.queryKey);

const users = ref<Record<string, string>>({});
const selected = ref<string[]>(useRouteQuery(props.queryKey, []).value);
const checkboxDropdown = ref<InstanceType<typeof LfCheckboxDropdown> | null>(
  null
);

const checkForValidFilterOptionsBasedOnQuery = async (
  assigneeIds: string[] | undefined
) => {
  const missingAssignees = assigneeIds?.filter(
    (id) => !Object.keys(users.value).includes(id)
  );
  if (!missingAssignees?.length) {
    return;
  }
  const addedUsers = await getUsersById(missingAssignees);
  if (addedUsers?.length) {
    updateAssigneeOptions(addedUsers);
  }
};

const sortUsers = (a: string, b: string) => {
  return a.localeCompare(b);
};

const assigneeIdsArray = computed(() => {
  const assigneeIds = convertToArray(assigneeIdsValue.value);
  return assigneeIds.length ? assigneeIds : undefined;
});

const renderComponentOptions = computed(() => ({
  selected: assigneeIdsArray.value
}));

const { fetchWrapper: getClientUsers } = usePromiseWrapper(
  async (params: Record<string, string | number> = {}, id: string) =>
    usersApiService.getClientUsers(id, {
      ...params,
      with_preset: 0
    })
);

const { fetchWrapper: getEmployees } = usePromiseWrapper(
  async (params: Record<string, string | number> = {}) =>
    usersApiService.getEmployees({ ...params, with_preset: 0 })
);

const { fetchWrapper: getUsersById } = usePromiseWrapper(
  async (ids: string[] | number[]) => {
    if (!ids.length) {
      return [];
    }
    const params = new URLSearchParams(
      ids.map((id) => ["ids[]", id as string])
    );
    return await usersApiService.getUsers(params);
  }
);

const { fetchWrapper: getClientEmailRecipients } = usePromiseWrapper(
  async (params: Record<string, string | number> = {}, id: string) =>
    usersApiService.getClientEmailRecipients(id, params)
);

const { fetchWrapper: getLendflowEmailRecipients } = usePromiseWrapper(
  async (params: Record<string, string | number> = {}) =>
    emailService.getEmailRecipients(params)
);

const userRequestMap = {
  client: {
    user: getClientUsers,
    email_recipient: getClientEmailRecipients
  },
  lendflow: {
    user: getEmployees,
    email_recipient: getLendflowEmailRecipients
  }
};

const getUsersByRole = (params: Record<string, string | number> = {}) => {
  if (auth.isClient && auth.currentUser?.client_id) {
    return userRequestMap.client[props.type](
      params,
      auth.currentUser.client_id
    );
  }
  return userRequestMap.lendflow[props.type](params);
};

const updateAssigneeOptions = (data: unknown[], search = false) => {
  const newPartnersOptions = (data as IUser[]).reduce<Record<string, string>>(
    (acc, { id, first_name, last_name }) => {
      if (id && first_name && last_name) {
        acc[id] = `${first_name} ${last_name}`;
      }
      return acc;
    },
    {}
  );

  if (search) {
    users.value = newPartnersOptions;
    return;
  }
  users.value = Object.assign({}, users.value, newPartnersOptions);
  checkForValidFilterOptionsBasedOnQuery(assigneeIdsArray.value);
};

const handleApplyFilter = async () => {
  await handleFiltering(selected.value, props.queryKey, true);
  if (checkboxDropdown.value) {
    checkboxDropdown.value.showMenu = false;
  }
};

const handleModelValueUpdate = async (value: unknown[]) => {
  const result = await handleDropdownValueUpdate(
    value,
    props.queryKey,
    true,
    selected.value
  );
  selected.value = result;
};

watch(
  () => route.query[props.queryKey],
  (updatedValue) => {
    if (!updatedValue) {
      selected.value = [];
      return;
    }
    selected.value = convertToArray(updatedValue) as string[];
  },
  { immediate: true }
);
</script>

<style scoped>
svg {
  margin-bottom: 0;
}
</style>
<style scoped>
.activity-hub-filter-dropdown :deep() {
  .dropdown-wrapper {
    @apply min-w-100;
  }
}
</style>

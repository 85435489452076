import Echo from "laravel-echo";
import Pusher from "pusher-js/with-encryption";

import config from "@/config";
import { axiosClient } from "@/services/client";
import ENDPOINTS from "@/services/endpoints";

type WindowWithEchoAndPusher = Window &
  typeof globalThis & {
    Pusher: typeof Pusher;
    Echo: Echo<"pusher">;
  };

export const windowWithEcho = window as WindowWithEchoAndPusher;

export const establishWebSocketConnection = (): void => {
  windowWithEcho.Pusher = Pusher;

  windowWithEcho.Echo = new Echo({
    broadcaster: config.ws.broadcaster as "pusher",
    key: config.ws.key,
    wsHost: config.ws.host,
    wsPort: config.ws.port,
    wssPort: config.ws.port,
    enableStats: false,
    encrypted: true,
    forceTLS: true,
    activityTimeout: 10000,
    pongTimeout: 10000,
    enabledTransports: ["ws", "wss"],
    cluster: config.ws.cluster,
    authorizer: ({ name }: { name: string }) => ({
      authorize: async (
        socket_id: string,
        callback: (bool: boolean, data: string) => void
      ) => {
        try {
          const response = await axiosClient.post(ENDPOINTS.WEBSOCKET_AUTH, {
            socket_id,
            channel_name: name
          });
          callback(false, response.data);
        } catch (error) {
          callback(true, error as string);
        }
      }
    })
  });
};

<template>
  <div class="flex flex-wrap justify-evenly">
    <div
      v-for="priority in priorities"
      :key="priority.value"
      v-tooltip="capitalize(priority.value)"
      :data-cy="`priority-${priority.value}`"
      class="bg-grey-hover h-10 w-10 rounded-full cursor-pointer flex items-center justify-center mr-2 mb-1"
      :class="[
        {
          'border-primary border': priority.value === modelValue
        },
        isActivityHubPage ? 'w-10 h-10' : 'w-8 h-8'
      ]"
      @click="setPriority(priority.value)"
    >
      <div>
        <icon-base
          height="15"
          width="15"
          :view-box="priority.viewBox"
          :class="priority.class"
          :icon="priority.icon"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface Props {
  modelValue: string;
}
</script>

<script setup lang="ts">
import { priorities } from "@/helpers/constants/communicationLogs";
import { useCommunicationLogs } from "@/hooks/communicationLogs";
import capitalize from "lodash/capitalize";

const emit = defineEmits<{
  "update:model-value": [value: string];
}>();
defineProps<Props>();

const { isActivityHubPage } = useCommunicationLogs();

const setPriority = (priority: string) => {
  emit("update:model-value", priority);
};
</script>

import { formatString } from "@/helpers/formatting";
import type {
  IIntegration,
  ProductSetting,
  ProductSettingPayload
} from "@/models/authentications";
import type {
  SingleWhitelabelAliasPayload,
  ExperianSubcodes,
  IClient,
  IClientEmbedReportData,
  IClientUpdatePayload,
  IDnsRecord,
  IEmailCustomization,
  IPasswordExpiryPayload,
  IUpdatePiisPayload,
  IWhitelabel,
  WhitelabelAlias,
  GetWhitelabelAliasPayload,
  StoreWhitelabelAliasesPayload
} from "@/models/clients";
import type { IUserNotification, IPaginatedResponse } from "@/models/common";
import { axiosClient } from "../client";
import endpoints from "../endpoints";
import type {
  WidgetClientIntegrationPayload,
  BorrowerPlatformClientIntegrationPayload
} from "@/models/integrations";
import type {
  IntegrationFunder,
  ServicePayload
} from "@/models/clientCredentials";
import omit from "lodash/omit";

class ClientsApiService {
  async getClients(params: Record<string, unknown> = {}) {
    const response = await axiosClient.get<IPaginatedResponse<IClient>>(
      endpoints.CLIENTS.ALL,
      {
        params
      }
    );
    return response.data;
  }

  async getClient(clientId: string) {
    const response = await axiosClient.get<{ data: IClient }>(
      formatString(endpoints.CLIENTS.SINGLE, clientId)
    );
    return response.data.data;
  }

  async createClient(data: Partial<IClient>) {
    const response = await axiosClient.post<{ data: IClient }>(
      endpoints.CLIENTS.ALL,
      data
    );
    return response.data.data;
  }

  async updateClient(data: IClientUpdatePayload, clientId: string) {
    const response = await axiosClient.patch<{ data: IClient }>(
      formatString(endpoints.CLIENTS.SINGLE, clientId),
      data
    );
    return response.data.data;
  }

  async deleteClient(clientId: string) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.CLIENTS.SINGLE, clientId)
    );
    return response;
  }

  async updateClientPiis(clientId: string, data: IUpdatePiisPayload) {
    const response = await axiosClient.put<{ data: IClient }>(
      formatString(endpoints.CLIENTS.UPDATE_CLIENT_PIIS, clientId),
      data
    );
    return response.data.data;
  }

  async getPlaidLinkToken(clientId: string) {
    const response = await axiosClient.get<{
      data: { plaid_link_token: string };
    }>(formatString(endpoints.CLIENTS.GET_PLAID_LINK_TOKEN, clientId));
    return response.data.data;
  }

  async storePlaidPublicToken(clientId: string, plaid_token: string) {
    await axiosClient.put(
      formatString(endpoints.CLIENTS.SET_PLAID_TOKEN, clientId),
      { plaid_token }
    );
  }

  async uploadClientFile(
    clientId: string,
    payload: {
      data: File;
      type: string;
      metadata?: Record<string, string>;
    }
  ) {
    const formData = new FormData();
    formData.append("file_type", payload.type);
    if (payload.metadata) {
      Object.entries(payload.metadata).forEach(([key, value]) => {
        formData.append(`metadata[${key}]`, value);
      });
    }
    formData.append("file", payload.data);

    await axiosClient.post(
      formatString(endpoints.CLIENTS.UPLOAD_FILES, clientId),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        }
      }
    );
  }

  async updateClientNotifications(
    clientId: string,
    data: Record<string, number[]>
  ) {
    const response = await axiosClient.put<{ data: IUserNotification[] }>(
      formatString(endpoints.CLIENTS.NOTIFICATIONS, clientId),
      data
    );
    return response.data.data;
  }

  async updateCloseApiKey(clientId: string, data: { api_key: string }) {
    const response = await axiosClient.put<{ data: IClient }>(
      formatString(endpoints.CLIENTS.UPDATE_CLOSE_IO, clientId),
      data
    );
    return response.data.data;
  }

  async setPasswordExpiry(clientId: string, data: IPasswordExpiryPayload) {
    const response = await axiosClient.put<{ data: IClient }>(
      formatString(endpoints.CLIENTS.SINGLE, clientId),
      data
    );
    return response.data.data;
  }

  async listEmailCustomisationTemplates(clientId: string) {
    const reponse = await axiosClient.get<{ data: IEmailCustomization[] }>(
      formatString(endpoints.CLIENTS.EMAIL.BRANDING.EMAIL_TEMPLATES, clientId)
    );
    return reponse.data.data;
  }

  async setActiveEmailTemplate(clientId: string, template: number) {
    return await axiosClient.put(
      formatString(
        endpoints.CLIENTS.EMAIL.BRANDING.SET_ACTIVE_TEMPLATE,
        clientId,
        template
      )
    );
  }

  async getEmailCustomisationDetails(clientId: string, template: number) {
    const response = await axiosClient.get<{ data: IEmailCustomization }>(
      formatString(
        endpoints.CLIENTS.EMAIL.BRANDING.CUSTOMISATION_DETAILS,
        clientId,
        template
      )
    );
    return response.data.data;
  }

  async createEmailCustomisationTemplate(clientId: string, data: FormData) {
    const response = await axiosClient.post<{ data: IEmailCustomization }>(
      formatString(endpoints.CLIENTS.EMAIL.BRANDING.EMAIL_TEMPLATES, clientId),
      data
    );
    return response.data.data;
  }

  async updateEmailCustomisationTemplate(
    clientId: string,
    templateId: number,
    data: FormData
  ) {
    return await axiosClient.put(
      formatString(
        endpoints.CLIENTS.EMAIL.BRANDING.CUSTOMISATION_DETAILS,
        clientId,
        templateId
      ),
      data
    );
  }

  async uploadEmailTemplateLogo(
    clientId: string,
    templateId: number,
    image: FormData
  ) {
    return await axiosClient.post(
      formatString(
        endpoints.CLIENTS.EMAIL.BRANDING.IMAGE,
        clientId,
        templateId
      ),
      image,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  }

  async removeImage(clientId: string, templateId: number, type: string) {
    const endpoint =
      type === "logo"
        ? endpoints.CLIENTS.EMAIL.BRANDING.LOGO
        : endpoints.CLIENTS.EMAIL.BRANDING.HEADER_IMAGE;

    return await axiosClient.delete(
      formatString(endpoint, clientId, templateId)
    );
  }

  async deleteEmailCustomisationTemplate(clientId: string, template: number) {
    const response = await axiosClient.delete<{ data: { success: boolean } }>(
      formatString(
        endpoints.CLIENTS.EMAIL.BRANDING.CUSTOMISATION_DETAILS,
        clientId,
        template
      )
    );
    return response;
  }

  async addWhiteLabel(clientId: string, data: Partial<IWhitelabel>) {
    const response = await axiosClient.put<{ data: IWhitelabel }>(
      formatString(endpoints.CLIENTS.WHITELABEL, clientId),
      omit(data, "id")
    );
    return response.data.data;
  }

  async updateWhiteLabel(clientId: string, data: Partial<IWhitelabel>) {
    if (!data.id) {
      return;
    }
    const response = await axiosClient.put<{ data: IWhitelabel }>(
      formatString(endpoints.CLIENTS.WHITELABEL_SINGLE, clientId, data.id),
      data
    );
    return response.data.data;
  }

  async deleteDns(clientId: string, id: string | number) {
    await axiosClient.delete(
      formatString(endpoints.CLIENTS.WHITELABEL_SINGLE, clientId, id)
    );
  }

  async getDnsRecords(clientId: string, whitelabelId: string) {
    const response = await axiosClient.get<{ data: IDnsRecord[] }>(
      formatString(endpoints.CLIENTS.DNS_RECORDS, clientId, whitelabelId)
    );
    return response.data.data;
  }

  async getDomainStatus(clientId: string, whitelabelId: string) {
    const response = await axiosClient.get<{ data: IWhitelabel }>(
      formatString(endpoints.CLIENTS.DOMAIN_STATUS, clientId, whitelabelId)
    );
    return response.data.data;
  }

  async sendClientTestEmails(
    clientId: string,
    data: { emails: string[]; template_id: number }
  ) {
    const response = await axiosClient.post<{ data: string }>(
      formatString(endpoints.CLIENTS.TEST_EMAIL, clientId),
      data
    );
    return response.data.data;
  }

  async storeLinkedFunder(clientId: string, funder_id: number) {
    const response = await axiosClient.put<{ data: IClient }>(
      formatString(endpoints.CLIENTS.FUNDER_LINK, clientId),
      { funder_id }
    );
    return response.data.data;
  }

  async getClientIntegration(clientId: string) {
    const response = await axiosClient.get<{ data: IIntegration }>(
      formatString(endpoints.CLIENTS.INTEGRATION, clientId)
    );
    return response.data.data;
  }

  async getClientWidgetIntegration(
    clientId: string,
    payload: WidgetClientIntegrationPayload,
    signal?: AbortSignal
  ) {
    const response = await axiosClient.post<{
      data: {
        url: string;
        script: string;
        script_url: string;
        query: Record<string, string>;
      };
    }>(formatString(endpoints.CLIENTS.INTEGRATION_WIDGET, clientId), payload, {
      signal
    });
    return response.data;
  }

  async getClientBorrowerPlatformIntegration(
    clientId: IClient["id"],
    payload: BorrowerPlatformClientIntegrationPayload
  ) {
    const response = await axiosClient.post<{
      data: { url: string; script: string; script_url: string };
    }>(
      `${formatString(
        endpoints.CLIENTS.INTEGRATION_BORROWER_PLATFORM,
        clientId
      )}`,
      payload
    );
    return response.data;
  }

  async updateClientExperianSubcodes(clientId: string, data: ExperianSubcodes) {
    const response = await axiosClient.put<{ data: ExperianSubcodes }>(
      formatString(endpoints.CLIENTS.EXPERIAN_SUBCODES, clientId),
      data
    );
    return response.data.data;
  }

  async getClientCredentialsRules(clientId: string) {
    const response = await axiosClient.get<{ data: Record<string, unknown> }>(
      formatString(endpoints.CLIENTS.CREDENTIALS_RULES, clientId)
    );
    return response.data;
  }

  async getClientCredentialsValues(clientId: string) {
    const response = await axiosClient.get<{ data: Record<string, unknown> }>(
      formatString(endpoints.CLIENTS.CREDENTIALS, clientId)
    );
    return response.data;
  }

  async updateClientCredentials(clientId: string, data: ServicePayload) {
    const headers = {
      "content-type":
        data.services[0].toLowerCase().includes("transunion") ||
        data.services[0] === "docusign"
          ? "multipart/form-data"
          : "application/json"
    };
    const response = await axiosClient.post<{ data: Record<string, string>[] }>(
      formatString(endpoints.CLIENTS.CREDENTIALS, clientId),
      data,
      { headers }
    );
    return response.data.data;
  }

  async getClientDocusignConsentUrl(clientId: string) {
    const response = await axiosClient.get<{
      consent_url: string;
    }>(formatString(endpoints.CLIENTS.CREDENTIALS_DOCUSIGN_CONSENT, clientId));
    return response.data;
  }

  async getClientFunderIntegrations(clientId: string) {
    const response = await axiosClient.get<{
      data: IntegrationFunder[];
    }>(formatString(endpoints.CLIENTS.FUNDER_INTEGRATIONS, clientId));
    return response.data.data;
  }

  async updateClientFunderIntegration(
    clientId: string,
    funderId: string,
    data: Partial<IntegrationFunder>
  ) {
    const response = await axiosClient.put<{ data: IntegrationFunder }>(
      formatString(
        endpoints.CLIENTS.FUNDER_INTEGRATION_SINGLE,
        clientId,
        funderId
      ),
      data
    );
    return response.data.data;
  }

  async updateCustomRequiredFields(
    clientId: string,
    data: Record<string, ProductSettingPayload[]>
  ) {
    const response = await axiosClient.put<{ data: ProductSetting[] }>(
      formatString(endpoints.CLIENTS.SINGLE, clientId),
      data
    );
    return response.data.data;
  }

  async saveColumnPreferences(data: Record<string, boolean>) {
    const response = await axiosClient.put<Record<string, string>>(
      endpoints.USERS.COLUMN_PREFERENCES,
      {
        key: "hidden-report-columns",
        value: data
      }
    );
    return response.data;
  }

  async updateClientTestMode(clientId: string, test_mode: boolean) {
    const response = await axiosClient.put<Record<string, string>>(
      formatString(endpoints.CLIENTS.TEST_MODE, clientId),
      { in_test_mode: test_mode }
    );
    return response.data.data;
  }

  async updateClientPiiMask(clientId: string, data: number[]) {
    const response = await axiosClient.put<Record<string, string>>(
      formatString(endpoints.CLIENTS.MASKED_PIIS, clientId),
      { masked_pii_ids: data }
    );
    return response.data.data;
  }

  async getClientEmbedReportToken(clientId: string, report = "1") {
    const response = await axiosClient.get<{
      data: IClientEmbedReportData;
    }>(
      `${formatString(
        endpoints.CLIENTS.EMBED_REPORT_TOKEN,
        clientId
      )}?report=${report}`
    );
    return response.data.data;
  }

  async getPii(clientId: string, piiId: number) {
    const response = await axiosClient.get<{
      data: string;
    }>(formatString(endpoints.CLIENTS.PIIS, clientId, piiId));
    return response.data.data;
  }

  async getAllClientsTrackingToken(params: Record<string, unknown> = {}) {
    const response = await axiosClient.get(
      formatString(endpoints.CLIENTS.TRACKING_TOKENS),
      {
        params
      }
    );
    return response.data;
  }

  async getAdditionalTrackingTokens(params: Record<string, unknown> = {}) {
    const response = await axiosClient.get(
      formatString(endpoints.CLIENTS.ADDITIONAL_TRACKING_TOKENS),
      {
        params
      }
    );
    return response.data;
  }

  async getWhitelabelAliases({
    clientId,
    whitelabelId
  }: GetWhitelabelAliasPayload) {
    if (!whitelabelId || !clientId) {
      return;
    }
    const response = await axiosClient.get<{ data: WhitelabelAlias[] }>(
      formatString(
        endpoints.CLIENTS.WHITELABEL_ALIASES.ALL,
        clientId,
        whitelabelId
      )
    );
    return response.data.data;
  }

  async storeWhitelabelAliases({
    clientId,
    whitelabelId,
    userIds
  }: StoreWhitelabelAliasesPayload) {
    if (!clientId || !whitelabelId) {
      return;
    }
    const response = await axiosClient.post<{ data: IWhitelabel }>(
      formatString(
        endpoints.CLIENTS.WHITELABEL_ALIASES.ALL,
        clientId,
        whitelabelId
      ),
      { user_ids: userIds }
    );
    return response.data.data;
  }

  async getWhitelabelAlias({
    clientId,
    whitelabelId,
    aliasId
  }: SingleWhitelabelAliasPayload) {
    if (!whitelabelId || !aliasId || !clientId) {
      return;
    }
    const response = await axiosClient.get<{ data: WhitelabelAlias }>(
      formatString(
        endpoints.CLIENTS.WHITELABEL_ALIASES.SINGLE,
        clientId,
        whitelabelId,
        aliasId
      )
    );
    return response.data.data;
  }

  async updateWhitelabelAlias({
    clientId,
    whitelabelId,
    aliasId
  }: SingleWhitelabelAliasPayload) {
    if (!whitelabelId || !aliasId || !clientId) {
      return;
    }
    const response = await axiosClient.put<{ data: WhitelabelAlias }>(
      formatString(
        endpoints.CLIENTS.WHITELABEL_ALIASES.SINGLE,
        clientId,
        whitelabelId,
        aliasId
      )
    );
    return response.data.data;
  }

  async deleteWhitelabelAlias({
    clientId,
    whitelabelId,
    aliasId
  }: SingleWhitelabelAliasPayload) {
    if (!whitelabelId || !aliasId || !clientId) {
      return;
    }
    const response = await axiosClient.delete<{ data: WhitelabelAlias }>(
      formatString(
        endpoints.CLIENTS.WHITELABEL_ALIASES.SINGLE,
        clientId,
        whitelabelId,
        aliasId
      )
    );
    return response.data.data;
  }
}

const clientsApiService = new ClientsApiService();
export default clientsApiService;

export const getApprovedClients = (params: Record<string, unknown> = {}) =>
  clientsApiService.getClients({ ...params, approved: 1 });

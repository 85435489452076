<template>
  <div class="flex relative">
    <lf-pill
      :colorIndex="colorIndex"
      class="uppercase min-w-20 font-bold"
      :class="{
        'cursor-pointer': canChangeStatus
      }"
      v-on-click-outside="closeMenu"
      role="statusPill"
      @click.stop="toggleMenu"
    >
      <div
        class="flex items-center whitespace-nowrap space-x-2"
        ref="menuDropdown"
      >
        <div>
          <component :is="statusIcon" />
        </div>
        <div>
          {{ statusDescription }}
        </div>
        <span v-if="canChangeStatus" class="transform -translate-y-0-5">
          <icon-base height="6" width="10" icon="dropdown-arrow" />
        </span>
      </div>
    </lf-pill>
    <teleport to="#menus">
      <div
        v-if="canChangeStatus && showMenu"
        class="absolute mt-7"
        :style="{
          top: `${top - 25}px`,
          left: `${left}px`
        }"
      >
        <ul
          class="z-50 min-w-20 w-max absolute h-auto overflow-auto bg-white shadow-lg rounded-md py-2 text-sm text-gray-500"
        >
          <li
            v-for="(statusItem, key) in statuses"
            :key="statusItem"
            class="py-2 pr-2 hover:bg-gray-200 whitespace-nowrap cursor-pointer uppercase text-xs font-semibold"
            @click.stop="handleStatusChange(key)"
            :data-cy="`listItem-${statusItem}`"
          >
            <span class="ml-3 cursor-pointer">
              {{ statusItem }}
            </span>
          </li>
        </ul>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
type Model = {
  status?: keyof typeof TEMPLATE_STATUSES;
  active_status?: keyof typeof TEMPLATE_STATUSES;
  is_active?: boolean;
  id?: string;
};

type ModelStatus = Pick<Model, "status" | "active_status">;
</script>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref } from "vue";
import { useAuth } from "@/hooks/auth";
import { useTeleport } from "@/hooks/elements";
import IconStatusFail from "@/components/icons/status/IconStatusFail.vue";
import IconStatusWarning from "@/components/icons/status/IconStatusWarning.vue";
import IconStatusSuccess from "@/components/icons/status/IconStatusSuccess.vue";
import {
  TEMPLATE_STATUSES,
  WORKFLOW_STATUS_COLOR_CODES
} from "@/helpers/constants";
import { getObjectKeys } from "@/helpers/common";

const props = defineProps({
  model: {
    type: Object as PropType<Model>,
    default: null
  },
  value: {
    type: [String, Number],
    default: null
  },
  handler: {
    type: Function as PropType<
      (status: keyof typeof TEMPLATE_STATUSES, id: string | undefined) => void
    >,
    required: true
  },
  statusField: {
    type: String as PropType<keyof ModelStatus>,
    default: "status"
  },
  options: {
    type: Object as PropType<typeof TEMPLATE_STATUSES>,
    default: TEMPLATE_STATUSES
  },
  colorCodes: {
    type: Object as PropType<typeof WORKFLOW_STATUS_COLOR_CODES>,
    default: WORKFLOW_STATUS_COLOR_CODES
  },
  statusIcons: {
    type: Array as PropType<(typeof STATUS_ICONS)[]>
  },
  adminStatuses: {
    type: Array as PropType<string[]>,
    default: () => ["2"]
  },
  approveStatus: {
    type: String,
    default: "1"
  }
});

const menuDropdown = ref(null);
const { top, left } = useTeleport(
  menuDropdown,
  "clientMenuButtonParent",
  "menu",
  null,
  "workflowViewTable"
);

const EMPTY_PLACEHOLDER = "-";

const { isLendflowUser, isAdmin } = useAuth();
const showMenu = ref(false);

const statuses = computed(() => {
  let keys = getObjectKeys(props.options);
  if (!isAdmin) {
    keys = keys.filter(
      (key) => props.adminStatuses.indexOf(key.toString()) === -1
    );
  }
  return keys.reduce(
    (acc, key) => {
      acc[key] = props.options[key];
      return acc;
    },
    {} as Record<keyof typeof TEMPLATE_STATUSES, string>
  );
});

const status = computed(() => props.model?.[props.statusField] ?? null);
const statusDescription = computed(() =>
  status.value === null ? EMPTY_PLACEHOLDER : props.options[status.value]
);

const STATUS_ICONS = [
  IconStatusSuccess,
  IconStatusWarning,
  IconStatusSuccess,
  IconStatusFail
];

const statusIcon = computed(() => {
  const statusIcons = props.statusIcons || STATUS_ICONS;
  return status.value === null ? statusIcons[0] : statusIcons[status.value];
});

const templateNotActiveNotApprovedCanChangeStatus = computed(() => {
  const trueCondition = !(
    status.value?.toString() !== props.approveStatus && props.model.is_active
  );
  if (!isAdmin) {
    return (
      props.adminStatuses.length < Object.keys(props.options).length &&
      trueCondition
    );
  }
  return trueCondition;
});

const canChangeStatus = computed(
  () => isLendflowUser && templateNotActiveNotApprovedCanChangeStatus.value
);

const colorIndex = computed(() =>
  status.value === null ? props.colorCodes[0] : props.colorCodes[status.value]
);

const closeMenu = () => {
  showMenu.value = false;
};

const toggleMenu = () => {
  if (!showMenu.value) {
    document.body.click();
  }
  showMenu.value = !showMenu.value;
};

const handleStatusChange = (status: keyof typeof props.options) => {
  props.handler(status, props.model?.id);
  closeMenu();
};
</script>

import { defineStore } from "pinia";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type {
  TemplateViewParams,
  EmailTemplate,
  SmsTemplate
} from "@/models/clients";
import customizationApiService from "@/services/modules/customization";
import { DEFAULT_PER_PAGE } from "@/helpers/constants";

interface UseCustomizationState {
  emailTemplates: IPaginatedResponse<EmailTemplate>;
  activeEmailTemplate: EmailTemplate | null;
  smsTemplates: IPaginatedResponse<SmsTemplate>;
  activeSmsTemplate: SmsTemplate | null;
}

const getDefaultState = (): UseCustomizationState => ({
  emailTemplates: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeEmailTemplate: null,
  smsTemplates: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeSmsTemplate: null
});

const useCustomizationStore = defineStore("customization", {
  state: getDefaultState,
  actions: {
    async getEmailTemplates(payload: TemplateViewParams) {
      const dataPayload: TemplateViewParams = {
        page: payload.page ?? 1,
        per_page: payload.per_page ?? 100
      };

      if (payload.search) {
        dataPayload.search = payload.search;
      }
      const data = await customizationApiService.getEmailTemplates(dataPayload);
      this.emailTemplates = data;
      return data;
    },
    async getEmailTemplate(id: string) {
      const data = await customizationApiService.getEmailTemplate(id);
      this.activeEmailTemplate = data;
    },
    async updateEmailTemplate(payload: {
      id: string;
      data: Partial<EmailTemplate>;
    }) {
      const data = await customizationApiService.updateEmailTemplate(payload);
      if (data) {
        this.activeEmailTemplate = data;
      }
    },
    async createEmailTemplate(payload: Partial<EmailTemplate>) {
      const data = await customizationApiService.createEmailTemplate(payload);
      this.activeEmailTemplate = data;
    },
    unsetEmailTemplates() {
      this.emailTemplates = getDefaultState().emailTemplates;
    },
    async getSmsTemplates(payload: TemplateViewParams) {
      const dataPayload: TemplateViewParams = {
        page: payload.page ?? 1,
        per_page: payload.per_page ?? DEFAULT_PER_PAGE,
        search: payload.search
      };

      const data = await customizationApiService.getSmsTemplates(dataPayload);
      this.smsTemplates = data;
    },
    async getSmsTemplate(id: string) {
      const data = await customizationApiService.getSmsTemplate(id);
      this.activeSmsTemplate = data;
    },
    async updateSmsTemplate(payload: {
      id: string;
      data: Partial<SmsTemplate>;
    }) {
      const data = await customizationApiService.updateSmsTemplate(payload);
      if (data) {
        this.activeSmsTemplate = data;
      }
    },
    async createSmsTemplate(payload: Partial<SmsTemplate>) {
      const data = await customizationApiService.createSmsTemplate(payload);
      this.activeSmsTemplate = data;
    },
    unsetSmsTemplates() {
      this.smsTemplates = getDefaultState().smsTemplates;
    }
  }
});

export default useCustomizationStore;

<template>
  <email-filter
    :options="options"
    :title="$t('COMMON.STATUS')"
    placeholder-key="ACTIVITY.FILTERS.STATUS"
    :render-component="OptionBase"
    query-key="task_statuses"
    is-multiple
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TaskStatuses } from "@/enums/communicationLogs";
import startCase from "lodash/startCase";
import EmailFilter from "@/components/communicationLogs/filters/emailFilters/EmailFilter.vue";
import OptionBase from "@/components/communicationLogs/filters/OptionBase.vue";

const options = computed(() => {
  return Object.entries(TaskStatuses).reduce(
    (prev, [key, value]) => {
      prev[value] = startCase(key);
      return prev;
    },
    {} as Record<string, string>
  );
});
</script>

<style scoped>
.status-dropdown :deep() {
  > button {
    @apply bg-grey-hover border-grey-hover;
    & > span {
      @apply text-headline ml-0;
    }
    & svg.rotate-180 {
      @apply text-primary;
    }
  }
  ul {
    @apply space-y-1 px-2;
    li {
      @apply rounded;
    }
    li.selected {
      @apply bg-primary-background-active;
    }
    .checkbox-field-wrapper {
      @apply w-full;
    }
    .checkbox-field {
      @apply w-full;

      label {
        @apply w-full;
      }
    }
  }
}
</style>

<template>
  <div
    v-on-click-outside="handleBlur"
    class="rounded-md relative"
    :class="{ 'border border-gray-300': inputsVisible }"
    @keyup.tab="handleBlur"
  >
    <note-type-selector
      :note-type="noteType"
      :disabled="!!inputId || disableTypeSelector"
      :note-to-edit="noteToEdit"
      class="absolute top-[1px] left-[1px] rounded-tl-md pl-3 py-2 w-[calc(100%-17px)] bg-white"
      @type:update="$emit('type:update', $event)"
    />
    <autoresizing-text-area
      id="note-input"
      v-model="inputText"
      class="w-full rounded-md overflow-y-auto -mb-3 text-gray-700 text-sm pt-9 max-h-100"
      :class="
        inputsVisible
          ? 'border-transparent border-0 focus:outline-none -mb-7 focus:ring-0 focus:border-transparent focus-within:outline-none'
          : 'border-gray-300 border'
      "
      placeholder="Add note"
      :focused="inputsVisible"
      :rows="3"
      data-cy="deal-note-input"
      @focus="handleFocus"
      @change="syncSessionStorage"
      @keydown.enter="addNote"
    />
    <div
      class="border-t border-gray-300 mt-1 p-1 bg-gray-100 relative"
      :class="inputsVisible ? 'flex justify-between' : 'hidden'"
    >
      <div class="w-36">
        <notes-dropdown
          v-if="noteType === NOTES_TYPES.APPLICATION_NOTE"
          v-model="selectedStatus"
          :options="dropdownOptions.options"
          :colors-for-options="dropdownOptions.colors"
          :active-flow-index="activeFlowIndex"
        />
        <notes-dropdown
          v-if="noteType === NOTES_TYPES.CALL_NOTE"
          v-model="selectedDisposition"
          :options="callStatuses"
          :colors-for-options="COLORS_BY_DISPOSITION_NOTE_STATUS"
          :placeholder="$t('ACTIVITY.SELECT_CALL_DISPOSITION')"
          class="min-w-50"
        />
      </div>

      <div
        class="flex items-center h-max"
        :class="{
          'w-full justify-end p-1': noteType === NOTES_TYPES.BUSINESS_NOTE
        }"
      >
        <button
          v-if="noteType === NOTES_TYPES.APPLICATION_NOTE"
          v-tooltip="$t('DEALS.DEAL_NOTES.VISIBLE_IN_DEALS_LIST')"
          data-cy="visible-in-deals-list"
          class="p-1 py-3 flex justify-center items-center w-10 rounded-md mr-2 cursor-pointer h-[36px]"
          :class="{ 'bg-primary': visibleInDealsList }"
          @click.prevent.stop="toggleVisibleInDealsList"
        >
          <icon-base
            :icon="IconListView"
            height="20"
            width="20"
            view-box="0 0 24 24"
            class="mx-1 cursor-pointer"
            :class="visibleInDealsList ? 'text-white' : 'text-gray-400'"
            @click.stop="toggleVisibleInDealsList"
          />
        </button>
        <note-visibility-button
          v-if="noteType !== NoteType.Business"
          :value="visibilityScope"
          @change-scope="setVisibilityScope"
        />
        <business-notes-visibility-button
          v-else
          :visibility-scope="visibilityScope"
          :inputs-visible="inputsVisible"
          @change-scope="setVisibilityScope"
        />
        <button
          class="p-1 flex justify-center items-center w-10 rounded-md h-[36px]"
          :class="
            inputText
              ? 'bg-primary cursor-pointer'
              : ' bg-gray-300 cursor-not-allowed'
          "
          @click="addNote()"
        >
          <icon-base
            height="20"
            width="20"
            :icon="IconSubmit"
            icon-color="white"
            class="ml-1"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { STATUS_COLOR_CODES } from "@/helpers/constants";
import { COLORS_BY_DISPOSITION_NOTE_STATUS } from "@/helpers/constants/colors";
import { useAuth } from "@/hooks/auth";
import { useDealsBase } from "@/hooks/deals";
import useNotesStore from "@/stores/notes";
import { WORKFLOW_TO_APP_STATUS } from "@/helpers/constants/workflow";
import type { ApplicationStatus } from "@/models/applications";
import type { NoteVisibilityScope } from "@/models/common";
import type { IFlowRecord, IWorkflowStage } from "@/models/workflows";
import type { Note, NotePayload } from "@/models/notes";
import { NoteType } from "@/enums/notes";
import { NOTES_TYPES } from "@/helpers/constants";

import AutoresizingTextArea from "@/views/deals/components/dealNotes/AutoresizingTextArea.vue";
import NotesDropdown, { type Option } from "@/components/ui/NotesDropdown.vue";
import NoteVisibilityButton from "@/views/deals/components/dealNotes/NoteVisibilityButton.vue";
import BusinessNotesVisibilityButton from "@/views/deals/components/dealNotes/BusinessNotesVisibilityButton.vue";
import NoteTypeSelector from "@/components/deals/NoteTypeSelector.vue";
import IconSubmit from "@/components/icons/IconSubmit.vue";
import IconListView from "@/components/icons/IconListView.vue";
import IconBase from "@/components/ui/IconBase.vue";

const props = withDefaults(
  defineProps<{
    noteToEdit?: Note | null;
    noteType?: NoteType;
    disableTypeSelector?: boolean;
  }>(),
  {
    noteType: NoteType.Application
  }
);

const emit = defineEmits<{
  "edit-note:unset": [];
  "note:edited": [];
  "type:update": [NoteType];
}>();

const { getters } = useStore();
const { isLendflowUser } = useAuth();
const { activeDeal } = useDealsBase();
const notesStore = useNotesStore();

const flows = computed<IFlowRecord[]>(
  () => getters["workflows/activeTemplateFlow"]
);

const stages = computed<IWorkflowStage[]>(
  () => getters["workflows/activeTemplateStages"]
);

const callDispositions = computed<Record<string, string>>(
  () => getters["options/callDispositions"]
);

const callStatuses = computed(() =>
  Object.entries(callDispositions.value).map(([id, value]) => ({
    id,
    name: value
  }))
);

const activeFlowIndex = computed<number>(() => {
  return flows.value.findIndex(
    (flow) => flow.stage_id === activeStage.value.id
  );
});

const activeStage = computed<IWorkflowStage>(
  () => getters["workflows/activeStage"]
);

interface DropdownOptions {
  options: Option[];
  colors: Record<string, number>;
}

const dropdownOptions = computed(() =>
  flows.value.reduce<DropdownOptions>(
    (final, { id, name, stage_id }) => {
      const stageType = stages.value.find((stage) => stage.id === stage_id)
        ?.type as keyof typeof WORKFLOW_TO_APP_STATUS;
      const statusCode = WORKFLOW_TO_APP_STATUS[stageType] || 0;
      const color = STATUS_COLOR_CODES[statusCode as ApplicationStatus];

      final.options.push({ id, name });
      final.colors[id] = color;
      return final;
    },
    { options: [], colors: {} }
  )
);

const defaultVisibility = computed(() =>
  isLendflowUser && props.noteType !== "business" ? 2 : 1
);

const noteTypeAndDealId = computed(() =>
  props.noteType && activeDeal.value.id
    ? `${props.noteType}-${activeDeal.value.id}`
    : ""
);

const inputText = ref(
  window.sessionStorage.getItem(noteTypeAndDealId.value) || ""
);
const inputsVisible = ref(false);
const visibilityScope = ref<NoteVisibilityScope>(defaultVisibility.value);
const visibleInDealsList = ref(
  props.noteToEdit?.visible_in_deal_list === undefined ||
    props.noteToEdit?.visible_in_deal_list
);
const selectedStatus = ref(props.noteToEdit?.stage);
const selectedDisposition = ref(props.noteToEdit?.call_disposition);

const inputId = computed(() => props.noteToEdit?.id);

const handleFocus = () => {
  inputsVisible.value = true;
};

const setVisibilityScope = (visibility: NoteVisibilityScope) => {
  visibilityScope.value = visibility;
};

const toggleVisibleInDealsList = () => {
  visibleInDealsList.value = !visibleInDealsList.value;
};

const handleBlur = (evt: KeyboardEvent | MouseEvent) => {
  const target = evt.target as HTMLElement;
  const targetClass = target ? target.getAttribute("class") || "" : "";
  if (
    inputText.value.length > 0 ||
    props.noteToEdit ||
    targetClass.startsWith("resizable") ||
    targetClass.indexOf("drag-el") > -1
  ) {
    return;
  }
  inputsVisible.value = false;
};

const syncSessionStorage = (event?: Event) => {
  const value = (event?.target as HTMLTextAreaElement)?.value;
  if (value) {
    window.sessionStorage.setItem(noteTypeAndDealId.value, value);
    return;
  }
  window.sessionStorage.removeItem(noteTypeAndDealId.value);
};

const addNote = async (event?: KeyboardEvent) => {
  const isMacOs = navigator.userAgent.toLowerCase().indexOf("mac os x") !== -1;
  const metaPressed = isMacOs ? event?.metaKey : event?.ctrlKey;
  const keyComboAccepted = !event || metaPressed;
  if (!keyComboAccepted || !inputText.value.length) {
    return;
  }
  event?.preventDefault();

  const payload: NotePayload = {
    notable_type: props.noteType,
    notable_id: getNotableId(props.noteType),
    comment: inputText.value,
    visibility_scope: visibilityScope.value
  };
  if (props.noteType === NOTES_TYPES.APPLICATION_NOTE) {
    payload.stage = selectedStatus.value;
    payload.visible_in_deal_list = visibleInDealsList.value;
  }
  if (props.noteType === NOTES_TYPES.CALL_NOTE) {
    payload.call_disposition = selectedDisposition.value;
  }

  if (inputId.value) {
    payload.id = Number(inputId.value);
    emit("edit-note:unset");
  }
  inputId.value
    ? await notesStore.updateNote(payload)
    : await notesStore.createNote(payload);
  inputText.value = "";
  visibilityScope.value = defaultVisibility.value;
  inputsVisible.value = false;
  window.sessionStorage.removeItem(noteTypeAndDealId.value);
  emit("note:edited");
};

const getNotableId = (noteType: NoteType) => {
  switch (noteType) {
    case NOTES_TYPES.BUSINESS_NOTE:
      return activeDeal.value.business.id;
    case NOTES_TYPES.CALL_NOTE:
      return props.noteToEdit?.notable_id || "";
    default:
      return activeDeal.value.id;
  }
};

watch(
  () => props.noteToEdit,
  () => {
    if (props.noteToEdit) {
      inputText.value = props.noteToEdit?.comment;
      visibilityScope.value =
        props.noteToEdit?.visibility_scope || defaultVisibility.value;
      inputsVisible.value = true;
      selectedStatus.value = props.noteToEdit.stage;
      selectedDisposition.value = props.noteToEdit.call_disposition;
      visibleInDealsList.value =
        props.noteToEdit.visible_in_deal_list === undefined ||
        props.noteToEdit.visible_in_deal_list;
      return;
    }
    inputText.value = "";
    visibilityScope.value = defaultVisibility.value;
    selectedStatus.value = undefined;
    selectedDisposition.value = undefined;
    visibleInDealsList.value = true;
  }
);

watch([flows, activeFlowIndex], ([newFlows, newFlowIndex]) => {
  if (newFlows && dropdownOptions.value?.options.length) {
    selectedStatus.value = dropdownOptions.value.options[newFlowIndex].id;
  }
});
</script>

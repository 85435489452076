import { CommunicationType } from "@/enums/communicationLogs";
import { convertToArray } from "@/helpers/common";
import { ROUTE_ACTIVITY_HUB } from "@/router/routes";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export const useCommunicationLogs = () => {
  const route = useRoute();
  const router = useRouter();

  const isActivityHubPage = computed(() => route.name === ROUTE_ACTIVITY_HUB);

  const currentTab = computed(() => route.query.type?.toString() ?? "");

  const isCurrentTabEmail = computed(
    () => currentTab.value === CommunicationType.email
  );

  const isCurrentTabSms = computed(
    () => currentTab.value === CommunicationType.sms
  );

  const isCurrentTabCalls = computed(
    () => currentTab.value === CommunicationType.call
  );

  const handleDropdownValueUpdate = async (
    value: unknown[],
    queryKey: string,
    isMultiple?: boolean,
    selected?: string[]
  ) => {
    if (selected?.length && !value.length) {
      await handleFiltering(value as string[], queryKey, isMultiple);
    }
    if (!value.length) {
      return [];
    }
    const inputValue = value as string[];
    return isMultiple ? inputValue : [inputValue[inputValue.length - 1]];
  };

  const handleFiltering = async (
    value: string[],
    queryKey: string,
    isMultiple?: boolean
  ) => {
    await router.push({
      query: {
        ...route.query,
        [queryKey]: isMultiple ? value : value[0]
      }
    });
  };

  const getArrayQueryValue = (queryKey: string) => {
    const value = route.query[queryKey];
    return (Array.isArray(value) ? value : convertToArray(value)) as string[];
  };

  return {
    isActivityHubPage,
    currentTab,
    isCurrentTabEmail,
    isCurrentTabSms,
    isCurrentTabCalls,
    handleDropdownValueUpdate,
    handleFiltering,
    getArrayQueryValue
  };
};

import type { TemplateMode } from "@/enums/contractBuilder";

export const TEMPLATE_MODES: Record<keyof typeof TemplateMode, TemplateMode> = {
  draft: 1,
  published: 2
};

export const CONTRACT_BUILDER_SCHEMA_VERSION = "2.7.0" as const;

export const CUSTOM_ATTRIBUTE = "custom_attribute" as const;

<template>
  <div class="flex w-full justify-between items-center">
    <div class="flex items-center space-x-2">
      <icon-base :icon="iconInfo.icon" :class="iconInfo.class" />
      <span class="text-headline">
        {{ smsActivityTypeOptions[modelValue] }}
      </span>
    </div>
    <icon-base v-if="isSelected" :icon="IconSent" class="text-blue-500" />
  </div>
</template>
<script setup lang="ts">
import IconIncomingArrow from "@/components/icons/IconIncomingArrow.vue";
import IconSent from "@/components/icons/email/IconSent.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed, type Component } from "vue";
import { smsActivityTypeOptions } from "@/helpers/constants/sms";

interface Props {
  modelValue: "incoming" | "outgoing";
  selected: string[];
}

const options: Record<Props["modelValue"], { icon: Component; class: string }> =
  {
    incoming: {
      icon: IconIncomingArrow,
      class: "text-turquoise"
    },
    outgoing: {
      icon: IconIncomingArrow,
      class: "text-primary transform rotate-180"
    }
  };

const props = defineProps<Props>();

const isSelected = computed(() => !!props.selected?.includes(props.modelValue));

const iconInfo = computed(() => options[props.modelValue]);
</script>

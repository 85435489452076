<template>
  <div v-if="formattedDateTimeArray.length === 2" class="flex flex-col">
    <div class="whitespace-nowrap" data-cy="date">
      {{ formattedDateTimeArray[0] }}
    </div>
    <div class="text-gray-600 font-medium" data-cy="time">
      {{ formattedDateTimeArray[1] }}
    </div>
  </div>
  <div v-else data-cy="placeholder">-</div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { formatDateCustom } from "@/helpers/formatting";

const props = defineProps({
  value: {
    type: String,
    required: true
  }
});

const formattedDateTimeArray = computed(() => {
  const formattedDateTime = formatDateCustom(
    props.value,
    "MMMM dd, yyyy|hh:mm a",
    true
  );
  return formattedDateTime.split("|");
});
</script>

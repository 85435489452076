<template>
  <vue-resizable
    v-slot="{ style }"
    drag-selector=".cursor-grab"
    v-bind="activeLocalStorageOptions"
    :min-width="callWidthAndHeight.minWidth"
    :min-height="callWidthAndHeight.minHeight"
    @mount="updatePosition"
    @resize:end="updatePosition"
    @drag:end="updatePosition"
  >
    <div
      ref="resizableElement"
      :style="style"
      class="bg-white call-log-dialog rounded-md"
      :class="isMinimized ? 'max-w-55' : 'h-full flex flex-col'"
      data-cy="call-log-dialog"
    >
      <div
        class="bg-dark p-4 rounded-t-md flex justify-between space-x-2"
        :class="{ 'rounded-b-md max-w-55': isMinimized }"
        data-cy="call-log-dialog-header"
      >
        <div class="flex items-center space-x-4">
          <span
            :title="value.data.call_sessionable.business.business_legal_name"
            class="heading text-white cursor-grab grow text-ellipsis whitespace-nowrap overflow-hidden"
            data-cy="call-dialog-title"
            @dblclick="toggleMinimize"
          >
            {{ value.data.call_sessionable.business.business_legal_name }}
          </span>
          <a v-if="dealRoute" :href="dealRoute" target="_blank">
            <icon-base :icon="IconVisit" class="hover:text-white" />
          </a>
        </div>
        <div class="flex space-x-2">
          <icon-base
            v-if="isMinimized"
            :icon="IconMaximize"
            icon-name="Maximize"
            class="text-white cursor-pointer"
            data-cy="maximize-call-log-dialog"
            @click="toggleMinimize"
          />
          <icon-base
            v-else
            :icon="IconSubtract"
            view-box="0 -5 16 16"
            icon-name="Minimize"
            class="text-white cursor-pointer"
            data-cy="minimize-call-log-dialog"
            @click="toggleMinimize"
          />
          <icon-base
            icon="close"
            icon-name="Close"
            class="cursor-pointer"
            @click="emit('close-dialog')"
          />
        </div>
      </div>
      <create-call-note-form
        v-show="!isMinimized"
        class="border-0 rounded-none flex flex-col flex-1"
        :call-log="value"
        data-cy="create-call-note-form"
        @call-note:create="emit('close-dialog')"
      >
        <template #supplemental>
          <div
            class="bg-grey-hover flex items-center justify-between px-3 pt-1 text-xs"
          >
            <span class="line-clamp-1 shrink" data-cy="client-name">
              {{ value.data.call_sessionable.client_name }}
            </span>
            <span class="line-clamp-1 shrink" data-cy="workflow-name">
              {{ workflowName }}
            </span>
          </div>
        </template>
      </create-call-note-form>
    </div>
  </vue-resizable>
</template>

<script setup lang="ts">
import type { CallLog } from "@/models/communicationLogs";
import { ref, computed, onMounted } from "vue";
import CreateCallNoteForm from "@/components/communicationLogs/forms/CreateCallNoteForm.vue";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconMaximize from "@/components/icons/IconMaximize.vue";
import IconVisit from "@/components/icons/IconVisit.vue";
import VueResizable from "vue-resizable";
import { useLocalStorageSetting } from "@/hooks/options";
import { DEFAULT_CALL_MODAL_OPTIONS } from "@/helpers/constants/deals";
import type { ResizableEventValues } from "@/models/common";
import { CALL_DIALOG } from "@/helpers/constants/resizable";

const emit = defineEmits<{
  "close-dialog": [];
}>();

const props = withDefaults(
  defineProps<{
    value: CallLog;
    initialValues: { x: number; y: number };
    dealId?: string;
    workflowName?: string;
  }>(),
  {
    initialValues: () => ({ x: 0, y: 0 }),
    dealId: "",
    workflowName: ""
  }
);

const activeLocalStorageOptions = useLocalStorageSetting(
  "callModalOptions",
  DEFAULT_CALL_MODAL_OPTIONS
);

const callWidthAndHeight = ref({
  width: DEFAULT_CALL_MODAL_OPTIONS.width,
  height: DEFAULT_CALL_MODAL_OPTIONS.height,
  minWidth: CALL_DIALOG.minWidth,
  minHeight: CALL_DIALOG.minHeight,
  minimizedWidth: CALL_DIALOG.minimizedWidth,
  minimizedHeight: CALL_DIALOG.minimizedHeight
});

const isMinimized = ref(false);
const resizableElement = ref<HTMLDivElement | null>(null);

const dealRoute = computed(() => {
  if (!props.dealId) {
    return "";
  }
  return `/deals/${props.dealId}/progress`;
});

const updatePosition = (
  positionAndEvent: ResizableEventValues,
  init = false
) => {
  const initOffset = init ? Number(resizableElement.value?.clientWidth) : 0;
  const { left, top, width, height } = positionAndEvent;
  const updatedOptions = {
    left: left - initOffset,
    top: Math.max(0, top),
    width,
    height
  };

  activeLocalStorageOptions.value = {
    ...activeLocalStorageOptions.value,
    ...updatedOptions
  };
};

const initPosition = (position: { x: number; y: number }) => {
  const { width, height } = callWidthAndHeight.value;
  const left = position.x;
  const top = position.y;
  updatePosition(
    {
      left,
      top,
      width,
      height
    },
    true
  );
};

const toggleMinimize = () => {
  if (!isMinimized.value) {
    const { width, height } = activeLocalStorageOptions.value;
    activeLocalStorageOptions.value.restoreHeight = height;
    activeLocalStorageOptions.value.restoreWidth = width;
  }
  isMinimized.value = !isMinimized.value;

  const { width, height, minimizedWidth, minimizedHeight } =
    callWidthAndHeight.value;

  const { restoreWidth, restoreHeight, left, top } =
    activeLocalStorageOptions.value;

  const setWidth = isMinimized.value ? minimizedWidth : restoreWidth || width;
  const setHeight = isMinimized.value
    ? minimizedHeight
    : restoreHeight || height;

  updatePosition({
    left,
    top,
    width: setWidth,
    height: setHeight
  });
};

onMounted(() => {
  const { left, top } = activeLocalStorageOptions.value;
  if (!left && !top) {
    initPosition(props.initialValues);
  }
});
</script>

<style>
.call-log-dialog .note-textarea {
  border-radius: 0;
  outline: none;
}
.call-log-dialog .note-textarea:focus {
  box-shadow: none;
}
</style>

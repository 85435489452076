<template>
  <div class="flex w-full justify-between items-center">
    <div class="flex items-center space-x-2">
      <slot name="prefix" />
      <span class="text-headline">
        <slot>
          {{ option }}
        </slot>
      </span>
      <slot name="suffix" />
    </div>
    <icon-base v-if="isSelected" :icon="IconSent" class="text-blue-500" />
  </div>
</template>

<script setup lang="ts">
import IconSent from "@/components/icons/email/IconSent.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed } from "vue";

interface Props {
  modelValue: string;
  selected: string[];
  option: string;
}
const props = defineProps<Props>();

const isSelected = computed(() => props.selected?.includes(props.modelValue));
</script>

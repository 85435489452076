<template>
  <lf-checkbox-dropdown
    ref="checkboxDropdown"
    :options="options"
    :placeholder="$t(placeholderKey, selected?.length || 1)"
    class="min-w-full activity-hub-filter-dropdown h-6-5"
    dropdown-width-full
    :enable-search="enableSearch"
    :render-component="renderComponent"
    :name="title"
    width-full
    no-margin
    :model-value="convertToArray(selected)"
    :render-component-options="renderComponentOptions"
    @update:model-value="handleModelValueUpdate"
    @menu:closed="selected = getArrayQueryValue(queryKey)"
  >
    <template #header>
      <div class="flex items-center justify-between px-4 py-2">
        <lf-h4>{{ title }}</lf-h4>
        <button class="text-error" @click="handleModelValueUpdate([])">
          {{ t("COMMON.CLEAR") }}
        </button>
      </div>
    </template>
    <template #footer>
      <div class="border-t p-4">
        <button
          class="w-full apply-filters-button text-center py-[10px] rounded text-primary font-semibold"
          @click.stop="handleApplyFilter"
        >
          {{ t("COMMON.APPLY") }}
        </button>
      </div>
    </template>
  </lf-checkbox-dropdown>
</template>

<script setup lang="ts">
import LfCheckboxDropdown from "@/components/ui/inputs/LfCheckboxDropdown.vue";
import LfH4 from "@/components/ui/text/LfHeading4.vue";

import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouteQuery } from "@vueuse/router";
import { convertToArray } from "@/helpers/common";
import { useCommunicationLogs } from "@/hooks/communicationLogs";

import type { Component } from "vue";
import { useRoute } from "vue-router";

interface Props {
  options: Record<string, string>;
  title: string;
  placeholderKey: string;
  enableSearch?: boolean;
  queryKey: string;
  renderComponent?: Component;
  isMultiple?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  enableSearch: false,
  isMultiple: false
});

const { t } = useI18n();
const route = useRoute();
const { handleDropdownValueUpdate, handleFiltering, getArrayQueryValue } =
  useCommunicationLogs();

const selected = ref<string[]>(useRouteQuery(props.queryKey, []).value);
const checkboxDropdown = ref<InstanceType<typeof LfCheckboxDropdown> | null>(
  null
);

const renderComponentOptions = computed(() => ({
  selected: selected.value
}));

const handleModelValueUpdate = async (value: unknown[]) => {
  const result = await handleDropdownValueUpdate(
    value,
    props.queryKey,
    props.isMultiple,
    selected.value
  );
  selected.value = result;
};

const handleApplyFilter = async () => {
  await handleFiltering(selected.value, props.queryKey, props.isMultiple);
  if (checkboxDropdown.value) {
    checkboxDropdown.value.showMenu = false;
  }
};

watch(
  () => route.query,
  (updatedValue) => {
    if (!updatedValue[props.queryKey]) {
      selected.value = [];
    }

    if (updatedValue[props.queryKey] && !selected.value.length) {
      selected.value = convertToArray(updatedValue[props.queryKey]) as string[];
    }
  }
);
</script>

<template>
  <lf-table
    select-all
    :columns="headerDateData"
    :data="organizeData"
    row-is-clickable
    @row-clicked="onClickRow"
    @selectedRows="handleSelectedRows"
    :show-head-select-all="false"
    accordion-table
  />
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref, onMounted, watch } from "vue";
import { formatDateCustom } from "@/helpers/formatting";
import isEmpty from "lodash/isEmpty";
import { PROFIT_AND_LOSS_FIXED_TEXT } from "@/helpers/constants";
import { DATE_FORMAT } from "@/helpers/constants/dateTime";
import type { RestructuredResult, Report } from "@/models/commercialData/Codat";
import groupBy from "lodash/groupBy";
import { getObjectKeys } from "@/helpers/common";
import get from "lodash/get";
import LfTable from "@/components/ui/table/LfTable.vue";

const emit = defineEmits<{
  (eventName: "update:data-values", object: RestructuredResult[]): void;
}>();

const organizeData = ref<RestructuredResult[]>([]);

const reportHelper = {
  id: "id",
  category: "category",
  fromDate: "fromDate",
  toDate: "toDate",
  date: "date",
  unit: "unit",
  selector: "selector"
};

const props = defineProps({
  reportsData: {
    type: Object as PropType<Report[]>,
    required: true
  },
  dataFilter: {
    type: Array as PropType<string[]>,
    required: true
  },
  chartFilter: {
    type: Array as PropType<string[]>,
    required: true
  }
});

const reports = computed(() => props.reportsData);
const headerDateData = computed(() => {
  return [
    { key: reportHelper.category, label: "" },
    ...reports.value.map((data) => {
      if (data.fromDate) {
        return {
          key: data.fromDate || "", // ts check
          label: `${formatDateCustom(
            data.fromDate,
            DATE_FORMAT
          )} / ${formatDateCustom(data.fromDate, DATE_FORMAT)}`
        };
      }
      return {
        key: data.date || "",
        label: formatDateCustom(data.date)
      };
    })
  ];
});

const reportData = computed(() => reports.value.map((el) => el));

const getItems = () => {
  const children: RestructuredResult[] = [];
  const parents: RestructuredResult[] = [];

  reportData.value.forEach((reportItem) => {
    const reportItemKeys = getObjectKeys(reportItem);
    reportItemKeys.forEach((reportItemKey) => {
      const reportItemValue = reportItem[reportItemKey];
      const date = reportItem.fromDate || reportItem.date;

      const { fromDate, toDate, date: dateKey } = reportHelper;

      const isNotAllowed = [fromDate, toDate, dateKey].includes(reportItemKey);

      if (isNotAllowed) {
        return;
      }

      if (typeof reportItemValue === "object" && reportItemValue.value) {
        const items = reportItemValue.items;
        for (const item of items) {
          children.push({
            date,
            item: item.value,
            selector: item.name as keyof typeof PROFIT_AND_LOSS_FIXED_TEXT,
            unit: reportItemValue.name,
            id: `${reportItemValue.name}-${item.name.toLowerCase()}`,
            fatherName: reportItemValue.name,
            open: false
          });
        }
        parents.push({
          date,
          unit: reportItemValue.name,
          item: reportItemValue.value,
          selector:
            reportItemValue.name as keyof typeof PROFIT_AND_LOSS_FIXED_TEXT,
          id: reportItemValue.name,
          hasChild: isEmpty(reportItemValue.items) ? false : true,
          childName: reportItemValue?.items[0]?.name,
          open: false
        });
        return;
      }

      parents.push({
        date,
        unit: reportItemKey,
        item: reportItemValue,
        hasChild: false,
        selector: reportItemKey,
        id: reportItemKey,
        open: true
      });
    });
  });
  return parents.concat(children);
};

const restructureData = () => {
  const data = getItems();
  const dataGroupedBySelector = Object.values(
    groupBy(data, reportHelper.selector)
  );

  const dataWithCategories = dataGroupedBySelector.map((el) => {
    return el.map((data) => {
      if (!data.date) {
        return;
      }
      return {
        category: PROFIT_AND_LOSS_FIXED_TEXT[data.selector]
          ? PROFIT_AND_LOSS_FIXED_TEXT[data.selector]
          : data.selector,
        [data.date]: get(data.item, "value", data.item),
        id: data.id,
        unit: data.unit,
        hasChild: data.hasChild,
        childName: data.childName,
        fatherName: data.fatherName,
        open: data.open
      };
    });
  });
  organizeData.value = dataWithCategories.map((el) => Object.assign({}, ...el));

  organizeData.value = Object.values(
    groupBy(organizeData.value, reportHelper.unit)
  ).flat();
  organizeData.value = organizeData.value.filter((el) => el.category);
  emit("update:data-values", organizeData.value);
};
const onClickRow = (row: string) => {
  organizeData.value = organizeData.value.map((el) => {
    return el.unit === row ? { ...el, open: !el.open } : el;
  });
};

const handleSelectedRows = (selectedRows: string[]) => {
  const selected = organizeData.value
    .filter((el) => selectedRows.includes(el.id))
    .flat();
  isEmpty(selected)
    ? emit("update:data-values", organizeData.value)
    : emit("update:data-values", selected);
};

watch(reports, restructureData);

onMounted(restructureData);
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>

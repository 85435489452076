// test against dates of format YYYY-MM-DD, tests days in month as well
export const DATE_REGEX_VALIDATE =
  /^[\d]{4}-(((0[13578]|(10|12))-(0[1-9]|[1-2][\d]|3[0-1]))|(02-(0[1-9]|[1-2][\d]))|((0[469]|11)-(0[1-9]|[1-2][\d]|30)))$/;

export const DATE_FORMAT = "MMM yyyy";

export const yearMonthDayDateFormatter = {
  date: "YYYY-MM-DD",
  month: "MMM"
};

export const defaultDateFormatter = {
  date: "MM/DD/YYYY",
  month: "MMM"
} as const;

// https://date-fns.org/v2.29.3/docs/format
// Example: "12:00 AM 04/29/1453"
export const LONG_LOCALIZED_DATE_TIME = "p P";

// example: "2024-08-01"
export const HYPHENATED_DATE_FORMAT = "yyyy-MM-dd";

<template>
  <div class="flex">
    <icon-tab-button
      class="bg-white"
      :class="{ active: modelValue === VIEW_TYPE_LIST }"
      :icon="IconListView"
      role="list-view-select"
      left-end
      :active="modelValue === VIEW_TYPE_LIST"
      @click="handleClick(VIEW_TYPE_LIST)"
    />
    <icon-tab-button
      class="bg-white"
      :class="{ active: modelValue === VIEW_TYPE_TILE }"
      :icon="IconCardView"
      role="card-view-select"
      right-end
      :active="modelValue === VIEW_TYPE_TILE"
      @click="handleClick(VIEW_TYPE_TILE)"
    />
  </div>
</template>

<script setup lang="ts">
import { VIEW_TYPE_TILE, VIEW_TYPE_LIST } from "@/helpers/constants";
import IconTabButton from "./IconTabButton.vue";
import IconListView from "@/components/icons/IconListView.vue";
import IconCardView from "@/components/icons/IconCardView.vue";

const emit = defineEmits(["update:modelValue"]);

defineProps({
  modelValue: {
    type: String,
    required: true
  }
});

const handleClick = (type: typeof VIEW_TYPE_TILE | typeof VIEW_TYPE_LIST) => {
  emit("update:modelValue", type);
};
</script>

export const CALL_DIALOG = {
  width: 570,
  height: 200,
  minWidth: 500,
  minHeight: 200,
  minimizedWidth: 220,
  minimizedHeight: 53,
  top: 0,
  left: 0,
  restoreWidth: 0,
  restoreHeight: 0
};

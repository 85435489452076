import capitalize from "lodash/capitalize";
import i18n from "@/i18n";

const t = i18n.global.t;

export const EMAIL_ACTIVITY_STATUSES = [
  "sent",
  "delivered",
  "opened",
  "bounced",
  "complaint",
  "links_clicked"
] as const;

export const emailActivityStatusOptions = EMAIL_ACTIVITY_STATUSES.reduce<
  Record<string, string>
>((result, status) => {
  const statusToReadableStatus: Record<string, string> = {
    complaint: t("EMAIL_INTEGRATION.FILTERS.COMPLAINED_ABOUT"),
    links_clicked: t("EMAIL_INTEGRATION.LINKS_CLICKED"),
    sent: t("EMAIL_INTEGRATION.FILTERS.SENT_SUCCESSFULLY")
  };
  result[status] = statusToReadableStatus[status] ?? capitalize(status);
  return result;
}, {});

export const EMAIL_ACTIVITY_TYPES = ["outgoing", "incoming"] as const;

export const emailActivityTypeOptions = EMAIL_ACTIVITY_TYPES.reduce<
  Record<string, string>
>((result, status) => {
  if (status === "outgoing") {
    result[1] = t("EMAIL_INTEGRATION.OUTGOING");
    return result;
  }
  result[0] = t("EMAIL_INTEGRATION.INCOMING");
  return result;
}, {});

export const EMAIL_AUTOMATION_TYPES = ["product", "transactional"] as const;

export const emailAutomationOptions = EMAIL_AUTOMATION_TYPES.reduce<
  Record<string, string>
>((result, status) => {
  if (status === "transactional") {
    result[1] = t("EMAIL_INTEGRATION.AUTOMATION.TRANSACTIONAL");
    return result;
  }
  result[0] = t("EMAIL_INTEGRATION.AUTOMATION.PRODUCT");
  return result;
}, {});

<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.7067 4.79289C16.0973 5.18342 16.0973 5.81658 15.7067 6.20711L9.70674 12.2071C9.31621 12.5976 8.68305 12.5976 8.29252 12.2071L6.99902 10.9141L8.41324 9.49992L8.99963 10.0858L14.2925 4.79289C14.683 4.40237 15.3162 4.40237 15.7067 4.79289Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.7071 4.79289C11.0976 5.18342 11.0976 5.81658 10.7071 6.20711L4.70711 12.2071C4.31658 12.5976 3.68342 12.5976 3.29289 12.2071L0.292893 9.20711C-0.0976311 8.81658 -0.0976311 8.18342 0.292893 7.79289C0.683417 7.40237 1.31658 7.40237 1.70711 7.79289L4 10.0858L9.29289 4.79289C9.68342 4.40237 10.3166 4.40237 10.7071 4.79289Z"
    fill="currentColor"
  />
</template>

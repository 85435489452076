<template>
  <deals-filter-dropdown
    ref="dealsDropdown"
    :app-by-id="applicationById"
    :app-name-map="allOptions"
    :app-ids-value="selected"
    :filter-icon="false"
    :show-clear-filter="false"
    show-top-clear
    :render-component="renderComponent"
    class="h-6-5 activity-hub-filter-dropdown"
    @menu:closed="selected = getArrayQueryValue('application_ids')"
    @options:update="updateApplicationOptions"
    @value:update="handleModelValueUpdate"
  >
    <template #footer>
      <div class="border-t p-4">
        <button
          class="w-full apply-filters-button text-center py-[10px] rounded text-primary font-semibold"
          @click.stop="handleApplyFilter"
        >
          {{ $t("COMMON.APPLY") }}
        </button>
      </div>
    </template>
  </deals-filter-dropdown>
</template>
<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import DealsFilterDropdown from "@/components/communicationLogs/filters/DealsFilterDropdown.vue";
import DealOption from "@/components/communicationLogs/filters/DealOption.vue";
import { useFilterByDeals } from "@/hooks/deals";
import { useCommunicationLogs } from "@/hooks/communicationLogs";

const {
  applicationById,
  applicationsIdToLegalNameMap,
  updateApplicationOptions
} = useFilterByDeals();
const route = useRoute();
const { handleDropdownValueUpdate, handleFiltering, getArrayQueryValue } =
  useCommunicationLogs();

const selected = ref<string[]>(
  (route.query["application_ids"] as string[]) || []
);
const dealsDropdown = ref<InstanceType<typeof DealsFilterDropdown> | null>(
  null
);

const renderComponent = {
  instance: DealOption,
  props: {
    selected: selected.value
  }
};

const allOptions = computed(() => ({
  // @TODO: BE needs to accept this option first
  // "": t("COMMON.UNASSIGNED"),
  ...applicationsIdToLegalNameMap.value
}));

const handleApplyFilter = async () => {
  await handleFiltering(selected.value, "application_ids", true);
  if (dealsDropdown.value) {
    dealsDropdown.value.closeDropdown();
  }
};

const handleModelValueUpdate = async (value: unknown[]) => {
  const result = await handleDropdownValueUpdate(
    value,
    "application_ids",
    true,
    selected.value
  );
  selected.value = result;
};

watch(
  () => route.query,
  (updatedValue) => {
    if (!updatedValue["application_ids"]) {
      selected.value = [];
    }
  }
);
</script>
<style scoped>
.activity-hub-filter-dropdown :deep() {
  .dropdown-wrapper {
    @apply min-w-100;
  }
}
</style>

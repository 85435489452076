<template>
  <span>
    {{
      date
        ? relative
          ? formatRelative(new Date(date), new Date())
          : formatDateCustom(date, f)
        : ""
    }}
  </span>
</template>
<script lang="ts">
import { formatDateCustom } from "@/helpers/formatting";
import { formatRelative } from "date-fns";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    date: {
      type: [String, Date],
      required: true
    },
    f: {
      type: String,
      default: "MM/dd/yyyy"
    },
    relative: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { formatRelative, formatDateCustom };
  }
});
</script>

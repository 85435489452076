<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.7067 4.29289C16.0973 4.68342 16.0973 5.31658 15.7067 5.70711L9.70674 11.7071C9.31621 12.0976 8.68305 12.0976 8.29252 11.7071L6.99902 10.4141L8.41324 8.99992L8.99963 9.58579L14.2925 4.29289C14.683 3.90237 15.3162 3.90237 15.7067 4.29289Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.7071 4.29289C11.0976 4.68342 11.0976 5.31658 10.7071 5.70711L4.70711 11.7071C4.31658 12.0976 3.68342 12.0976 3.29289 11.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289C0.683417 6.90237 1.31658 6.90237 1.70711 7.29289L4 9.58579L9.29289 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289Z"
    fill="currentColor"
  />
</template>

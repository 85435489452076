<template>
  <div class="flex items-baseline space-x-2 justify-between">
    <div class="grow">
      <div class="flex items-center space-x-2">
        <span
          v-tooltip="deal?.business?.business_legal_name"
          class="truncate legal-name"
        >
          {{ deal?.business?.business_legal_name }}
        </span>
        <span
          class="border rounded px-1 cursor-pointer bg-white max-w-26 truncate text-xs"
          @click.stop.prevent="copyToClipboard(String(deal?.id))"
        >
          {{ $t("DEALS.TRACKING.UUID") }}: {{ deal?.id }}
        </span>
      </div>
      <div class="text-sm text-gray-400 space-x-2">
        <template v-if="deal?.partner?.client?.name">
          <span>{{ deal.partner.client.name }}</span>
          <span>•</span>
        </template>
        <lf-date :date="deal?.created_at ?? ''" />
      </div>
    </div>
    <div v-if="isSelected" class="self-center">
      <icon-base icon="checkmark-round-blue" width="16" height="16" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IApplication } from "@/models/applications";
import { useClipboardWithMessage } from "@/hooks/clipboard";
import LfDate from "@/components/ui/LfDate.vue";
import IconBase from "@/components/ui/IconBase.vue";

defineProps<{
  deal: IApplication | null | undefined;
  isSelected?: boolean;
}>();

const copyToClipboard = useClipboardWithMessage();
</script>

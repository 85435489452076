<template>
  <div
    v-on-click-outside="closeMenu"
    class="relative rounded"
    :class="{
      'h-10': !$slots.default && !hasHeight,
      'mb-2': !noMargin,
      'w-56 mr-3': !widthFull && !slots.default,
      'bg-white': !filterIcon
    }"
  >
    <button
      v-if="!filterIcon"
      type="button"
      class="relative bg-white text-gray-600 border hover:border-gray-300 rounded text-left focus:outline-none text-sm dropdown-button"
      :class="{
        'w-full -pl-1 sm:pl-2 h-full': !$slots.default,
        'focus:border-primary focus:border-2': !disabled,
        'border-2 border-primary': toggleFromParent || showMenu
      }"
      :data-cy="`checkbox-dropdown-${name}`"
      @keyup.space.prevent
      @click.stop.prevent="toggleMenu"
    >
      <slot />
      <template v-if="!$slots.default">
        <span
          ref="labelRef"
          v-tooltip="{
            content: noLabelFormat
              ? label
              : wordsFirstLetterToUpper(String(label)),
            onShow: () => isLabelTextTruncated
          }"
          class="mr-5 ml-3 block truncate"
          :class="{
            'text-gray-400': disabled || !selectedOptions.length
          }"
          role="checkbox-dd-label"
          data-cy="checkbox-dropdown-label"
        >
          {{ noLabelFormat ? label : wordsFirstLetterToUpper(String(label)) }}
        </span>
        <span
          class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer dropdown-icon"
        >
          <icon-base
            class="transform transition-all text-gray-400"
            height="6"
            width="10"
            icon="dropdown-arrow"
            :class="{ 'rotate-180': showMenu }"
          />
        </span>
      </template>
    </button>

    <div
      v-show="toggleFromParent || showMenu"
      ref="dropdown"
      class="absolute z-20 bg-white shadow-lg rounded-md text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-hidden min-w-56 dropdown-wrapper"
      :class="[
        flipElement ? 'right-0' : 'left-0',
        {
          'top-3': filterIcon,
          'w-full': dropdownWidthFull
        }
      ]"
      :style="{
        width: minWidth ?? undefined
      }"
      :data-cy="`checkbox-dropdown-wrap-${name}`"
    >
      <slot name="header" />
      <slot name="pre-search" />
      <div
        v-if="enableSearch"
        class="p-3 sticky top-0 left-0 right-0 bg-white w-full"
      >
        <search-input
          v-model="searching"
          :search-term="searchTerm"
          no-margin
          no-padding
        />
      </div>
      <lf-switch
        v-if="isExcludeModeEnabled !== undefined"
        v-model="isExcludeModeEnabled"
        name="exclude_mode"
        class="p-3"
        @toggle-changed="handleExcludeModeChange"
      >
        <div class="flex items-center space-x-2">
          <span class="text-headline">
            {{ $t("SMART_FILTERS.EXCLUDE_MODE") }}
          </span>
          <icon-base
            :icon="IconInfo"
            class="text-label"
            v-tooltip="$t('SMART_FILTERS.EXCLUDE_MODE_DESCRIPTION')"
          />
        </div>
      </lf-switch>
      <loader :is-loading="isLoadingMore || isLoading" />
      <slot
        name="list"
        :inverted-options="invertedOptions"
        :filtered-list="filteredResults"
      >
        <ul
          class="min-w-max overflow-auto overscroll-none"
          data-cy="checkbox-dropdown-options-list"
          :class="[
            optionsListHeightSmall ? 'max-h-26' : 'max-h-40',
            { 'w-26 sm:w-56': !dropdownWidthFull }
          ]"
        >
          <template v-if="!isEmpty(options)">
            <li
              v-for="option in filteredResults"
              :key="option"
              class="px-4 py-2 hover:bg-gray-200 flex justify-start"
              :class="{
                selected: selectedOptions.includes(
                  valueIsOption ? option : invertedOptions[option]
                )
              }"
              role="option"
            >
              <component
                :is="lazyLoadCheckboxes ? LazyLoad : 'div'"
                :options="lazyLoadCheckboxes"
                class="checkbox-field-wrapper"
              >
                <lf-checkbox
                  v-model="checkboxValue"
                  class="checkbox-field"
                  :name="name"
                  :data-cy="`filter-option-${option}`"
                  :option="option"
                  :disabled="
                    !!props.disabledOptions?.length &&
                    isOptionDisabled(option || '')
                  "
                  :value="valueIsOption ? option : invertedOptions[option]"
                  @change.stop="handleCheckboxChange"
                >
                  <component
                    :is="renderComponent"
                    v-if="renderComponent"
                    :model-value="invertedOptions[option]"
                    :option="option"
                    v-bind="renderComponentOptions"
                  />
                  <div
                    v-else
                    class="font-normal text-left truncate"
                    :class="{
                      'max-w-56': !dropdownWidthFull
                    }"
                    data-cy="checkbox-option"
                  >
                    {{ optionFormatter ? optionFormatter(option) : option }}
                  </div>
                </lf-checkbox>
              </component>
            </li>
            <li
              v-if="enableSelectAll"
              class="pl-5 pt-3 pb-1-25 hover:bg-gray-200 flex justify-start border-t"
              role="option"
            >
              <lf-checkbox
                v-model="selectAllOption"
                name="selectAll"
                :value="true"
                @change.stop="toggleSelectAll"
              >
                <span class="font-normal">{{ $t("COMMON.SELECT_ALL") }}</span>
              </lf-checkbox>
            </li>
          </template>
          <template v-else>
            <p class="text-center mb-2">
              {{ customNoDataMessage || $t("COMMON.NO_DATA_AVAILABLE") }}
            </p>
          </template>

          <div
            v-if="hasActionSlot"
            class="bg-white sticky bottom-0 flex items-center justify-center p-0 h-11 border-t"
          >
            <slot name="dropdownAction" />
          </div>
        </ul>
      </slot>
      <div
        v-show="showClearFilter || getPaginatedOptions"
        class="bg-white sticky bottom-0 flex items-center justify-evenly py-0 px-4 h-11 border-t"
        :class="
          showClearFilter && getPaginatedOptions
            ? 'justify-between'
            : 'justify-center'
        "
      >
        <a
          v-if="showClearFilter"
          class="text-primary text-sm cursor-pointer font-medium transform active:scale-95"
          data-cy="clear-filter"
          @click.stop="clearFilter"
        >
          {{ clearText || $t("COMMON.CLEAR_FILTER") }}
        </a>
        <a
          v-if="getPaginatedOptions"
          class="text-sm font-medium"
          data-cy="load-more-options"
          :class="
            paginatedOptionsMeta.current_page === paginatedOptionsMeta.last_page
              ? 'text-gray-500 cursor-not-allowed'
              : 'text-primary cursor-pointer'
          "
          @click.stop="loadMore()"
        >
          {{ $t("COMMON.LOAD_MORE") }}
        </a>
      </div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  useSlots,
  useAttrs,
  onMounted,
  onBeforeUnmount
} from "vue";
import { wordsFirstLetterToUpper } from "@/helpers/formatting";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import invert from "lodash/invert";
import isNull from "lodash/isNull";
import { getDropdownWidth, checkElementIsFullyVisible } from "@/helpers/UI";

import type { Component, PropType } from "vue";
import type {
  IPaginatedResponse,
  PaginatedOptionsMeta,
  LazyLoadOptions
} from "@/models/common";

import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import LazyLoad from "@/components/LazyLoad.vue";
import LfSwitch from "@/components/ui/inputs/LfSwitch.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconInfo from "@/components/icons/IconInfo.vue";

import { useElementStatus } from "@/hooks/elements";

type Options = Record<string, string>;

const MAX_SELECTED_DISPLAYED = 3;

const emit = defineEmits<{
  "update:model-value": [value: unknown[]];
  loadedMore: [data: unknown[]];
  optionsSearch: [data: unknown[]];
  "change-list-visibility": [value: boolean];
  "menu:closed": [void];
  "update:exclude-mode": [value: boolean];
}>();

const props = defineProps({
  modelValue: {
    // added String to type because query-string library type is being parsed wrong
    type: [Array, String] as PropType<unknown[]>,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  options: {
    type: Object as PropType<Options>,
    required: true
  },
  placeholder: String,
  itemLabel: String,
  enableSearch: {
    type: Boolean,
    default: false
  },
  maintainSearchOnClose: {
    type: Boolean,
    default: false
  },
  searchTerm: {
    type: String,
    default: ""
  },
  enableSelectAll: {
    type: Boolean,
    default: false
  },
  renderComponent: {
    type: [String, Object] as PropType<string | Component>
  },
  showClearFilter: {
    type: Boolean,
    default: false
  },
  dropdownWidthFull: {
    type: Boolean,
    default: false
  },
  widthFull: {
    type: Boolean,
    default: false
  },
  noMargin: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  getPaginatedOptions: {
    type: Function,
    default: null
  },
  filterIcon: {
    type: Boolean,
    default: false
  },
  toggleFromParent: {
    type: Boolean,
    default: null
  },
  maxItemsDisplayed: Number,
  apiQueryParam: {
    type: String,
    default: ""
  },
  valueIsOption: {
    type: Boolean,
    default: false
  },
  optionFormatter: {
    type: Function,
    default: null
  },
  noLabelFormat: {
    type: Boolean,
    default: false
  },
  disabledOptions: {
    type: Array as PropType<Array<string> | Array<number>>,
    default: () => []
  },
  clearText: {
    type: String,
    default: ""
  },
  customNoDataMessage: {
    type: String,
    default: ""
  },
  sort: {
    type: Boolean,
    default: false
  },
  sortingFunction: {
    type: Function as PropType<(a: string, b: string) => number>
  },
  optionsListHeightSmall: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  lazyLoad: {
    type: Boolean,
    default: false
  },
  lazyLoadCheckboxes: {
    type: [Boolean, Object] as PropType<boolean | LazyLoadOptions>,
    default: false
  },
  // Provided when the default options don't contain the selected value
  // Example: modelValue = [1, 3, 5] and options are { 1: 'a', 3: 'c' } but we need { 5: 'e' }
  backupOptions: { type: Object as PropType<Options> },
  renderComponentOptions: {
    type: Object as PropType<Record<string, unknown>>,
    default: () => ({})
  }
});

const isExcludeModeEnabled = defineModel<boolean | undefined>(
  "isExcludeModeEnabled",
  { default: undefined }
);

const { isTextTruncated } = useElementStatus();
const slots = useSlots();
const attrs = useAttrs();
const dropdown = ref<HTMLElement | null>(null);
const flipElement = ref(false);
const searching = ref("");
const showMenu = ref(false);
const selectAllOption = ref(false);
const selectedOptions = computed(() => props.modelValue as string[]);
const checkboxValue = ref(props.modelValue);
const isLoadingMore = ref(false);
const minWidth = ref<null | string>(null);
const cachedOptions = ref<Options>({ ...props.options });
const labelRef = ref<HTMLElement | null>(null);
const isLabelTextTruncated = isTextTruncated(labelRef);

const paginatedOptionsMeta = ref<PaginatedOptionsMeta>({
  current_page: 0,
  last_page: null
});
const hasActionSlot = computed(() => !!slots.dropdownAction);
const hasHeight = computed(
  () => attrs.class && !!(attrs.class as string).includes("h-")
);
const optionsWithUniqueValues = computed(() => {
  //add spaces to duplicate values to make them unique
  const optionsWithUniqueValues = {} as Options;
  const valFreqMap = {} as Record<string, number>;
  for (const key in props.options) {
    valFreqMap[props.options[key]]
      ? valFreqMap[props.options[key]]++
      : (valFreqMap[props.options[key]] = 1);
    optionsWithUniqueValues[key] =
      props.options[key] + " ".repeat(valFreqMap[props.options[key]] - 1);
  }
  return optionsWithUniqueValues;
});

const invertedOptions = computed(() => {
  return invert(optionsWithUniqueValues.value);
});

const sortedOptions = computed(() =>
  props.sort
    ? Object.values(optionsWithUniqueValues.value).sort(props.sortingFunction)
    : Object.values(optionsWithUniqueValues.value)
);

const filteredResults = computed(() => {
  if (props.apiQueryParam) {
    return sortedOptions.value;
  }
  return sortedOptions.value.filter((option) =>
    option.toLowerCase().includes(searching.value.toLowerCase())
  );
});

const label = computed(() => {
  const selectedCount = selectedOptions.value.length;

  if (!selectedCount || selectedCount === 0) {
    return props.placeholder;
  }
  const options = {
    ...cachedOptions.value,
    ...props.backupOptions,
    ...props.options
  };
  return selectedCount < (props.maxItemsDisplayed || MAX_SELECTED_DISPLAYED)
    ? selectedOptions.value
        .map((value) => {
          if (props.valueIsOption) {
            return Object.values(options).find((o) => o === value);
          }
          return options[value];
        })
        .join(", ")
    : `${selectedCount} ${props.itemLabel || props.placeholder}`;
});

const isOptionDisabled = (option: string): boolean =>
  props.disabledOptions?.map(String).includes(String(option));

const checkVisibility = (entry: ResizeObserverEntry | Event) => {
  if (!entry.target) {
    return;
  }
  const element = entry.target as HTMLElement;
  if (!checkElementIsFullyVisible(element)) {
    flipElement.value = true;
    return;
  }
  flipElement.value = false;
};

const toggleMenu = () => {
  if (!props.maintainSearchOnClose) {
    searching.value = "";
  }
  if (props.disabled) {
    return;
  }
  lazyLoadOnOpen();
  showMenu.value = !showMenu.value;
  emit("change-list-visibility", showMenu.value);
};

const closeMenu = (e: PointerEvent) => {
  // Supported pointer types in JS are "mouse", "pen" and "touch" at the moment of writing.
  // Certain keyboard keys can trigger PointerEvent here for some reason and in that case the
  // e.pointerType will equal to "", which explains the condition below which prevents state reset
  // pointerType is undefined in Firefox as of v 120 so we have to check for empty string
  if (e.pointerType === "") {
    return;
  }
  handleClose();
};

const handleClose = () => {
  showMenu.value = false;
  if (!props.maintainSearchOnClose) {
    searching.value = "";
  }
  emit("change-list-visibility", showMenu.value);
  emit("menu:closed");
};

const clearFilter = () => {
  if (props.disabled) {
    return;
  }
  emit("update:model-value", []);
  searching.value = "";
  showMenu.value = false;
};

const handleCheckboxChange = () =>
  !props.disabled && emit("update:model-value", checkboxValue.value);

const toggleSelectAll = () => {
  if (props.disabled) {
    return;
  }
  if (selectAllOption.value) {
    emit("update:model-value", Object.keys(props.options));
    return;
  }
  emit("update:model-value", []);
};

const handleExcludeModeChange = (shouldExclude: boolean, event: Event) => {
  event.preventDefault();
  emit("update:exclude-mode", shouldExclude);
};

const loadMore = async (queryParam = "") => {
  if (!props.getPaginatedOptions) {
    return;
  }
  const { current_page, last_page } = paginatedOptionsMeta.value;
  if (current_page === last_page) {
    return;
  }
  try {
    isLoadingMore.value = true;
    const pageToGet = current_page + 1;
    if (queryParam) {
      const response: IPaginatedResponse<unknown> =
        await props.getPaginatedOptions({
          [queryParam]: searching.value
        });
      emit("loadedMore", response.data);
      return;
    }

    const response: IPaginatedResponse<unknown> =
      await props.getPaginatedOptions({
        page: pageToGet
      });
    paginatedOptionsMeta.value.current_page = response.meta.current_page;
    paginatedOptionsMeta.value.last_page = response.meta.last_page;
    emit("loadedMore", response.data);
  } finally {
    isLoadingMore.value = false;
  }
};

watch(
  () => Array.from(new Set([...selectedOptions.value, ...props.modelValue])),
  (newValue) => {
    selectAllOption.value = Object.keys(props.options).every((id) =>
      selectedOptions.value?.map(String).includes(`${id}`)
    );
    checkboxValue.value = newValue;
  }
);

watch(
  () => filteredResults.value,
  () => {
    if (props.dropdownWidthFull) {
      return;
    }
    minWidth.value = getDropdownWidth(filteredResults.value, true);
  },
  { immediate: true }
);

const search = debounce(async () => {
  if (props.apiQueryParam && props.getPaginatedOptions) {
    const response: IPaginatedResponse<unknown> =
      await props.getPaginatedOptions(
        searching.value
          ? {
              [props.apiQueryParam]: searching.value
            }
          : undefined
      );
    paginatedOptionsMeta.value.current_page = response.meta.current_page;
    paginatedOptionsMeta.value.last_page = response.meta.last_page;
    emit("optionsSearch", response.data);
    return;
  }
}, 200);

//update options with new ones if the of existing options is not successful
watch(searching, async () => {
  search();
});

watch(
  () => props.options,
  (newOptions) => {
    cachedOptions.value = { ...cachedOptions.value, ...newOptions };
  }
);

const lazyLoadOnOpen = () => {
  if (props.lazyLoad && !paginatedOptionsMeta.value.current_page) {
    loadMore();
  }
};

watch(() => props.toggleFromParent, lazyLoadOnOpen);

const resizeObserver = new ResizeObserver((entries) => {
  setTimeout(() => {
    const [element] = entries;
    if (element.target) {
      checkVisibility(element);
    }
  }, 0);
});

onMounted(() => {
  if (isNull(dropdown.value)) {
    return;
  }
  resizeObserver.observe(dropdown.value);
  window.addEventListener("resize", handleClose);
});

onBeforeUnmount(() => {
  if (isNull(dropdown.value)) {
    return;
  }
  resizeObserver.disconnect();
  window.removeEventListener("resize", handleClose);
});

onMounted(() => {
  if (props.getPaginatedOptions && !props.lazyLoad) {
    loadMore();
  }
});

defineExpose({
  showMenu
});
</script>

<template>
  <div class="relative" :class="{ 'cursor-pointer': !disabled }">
    <div v-if="avatarOnlyTriggerButton" @click="toggleMenu">
      <lf-avatar v-if="selectedUser" :username="fullName" :size="28" />
      <icon-base
        v-else
        :icon="IconUserSmall"
        height="24"
        width="24"
        class="text-gray-500"
      />
    </div>
    <div
      v-else
      class="cursor-pointer p-2"
      :class="{ 'bg-blue-50 rounded': selectedUser }"
      @click="toggleMenu"
    >
      <div v-if="selectedUser" class="flex space-x-2">
        <lf-avatar v-if="selectedUser" :username="fullName" :size="40" />
        <div class="flex items-center justify-between w-4/5">
          <p class="text-headline">{{ fullName }}</p>
          <p class="text-primary text-xs">{{ $t("COMMON.CHANGE") }}</p>
        </div>
      </div>
      <div v-else class="flex items-center space-x-4">
        <icon-base
          class="place-self-center"
          height="35"
          width="35"
          view-box="0 0 48 48"
          :icon="IconProfile"
        />
        <div class="text-primary font-semibold">
          {{ placeholder }}
        </div>
      </div>
    </div>
    <div
      v-if="showMenu"
      ref="dropdown"
      class="cursor-pointer space-y-2 bg-white z-[101] absolute shadow-lg rounded-md overflow-hidden"
      :class="avatarOnlyTriggerButton ? 'w-64' : 'max-w-[500px] w-full'"
    >
      <div class="p-2 space-y-2 w-full">
        <p class="text-headline font-semibold text-md">
          {{ $t("COMMON.ASSIGNEE") }}
        </p>
        <search-input
          :model-value="search"
          no-padding
          no-margin
          @update:model-value="handleSearchChange"
        />
      </div>
      <ul
        v-if="Object.keys(userOptions).length"
        :data-cy="`dropdown-${name}`"
        class="text-sm text-gray-500 max-h-44 overflow-y-auto z-50 w-full"
      >
        <loader :is-loading="loading" />
        <li
          v-for="user in userOptions"
          :key="user.id"
          class="hover:bg-gray-200 p-2 justify-start overflow-auto w-full cursor-pointer space-x-2 flex items-center"
          @click="handleUserChange(user)"
        >
          <lf-avatar :username="formatUserFullName(user)" :size="40" />
          <div class="flex flex-col">
            <p class="text-headline font-semibold">
              {{ formatUserFullName(user) }}
            </p>
            <div class="overflow-hidden text-ellipsis">{{ user.email }}</div>
          </div>
        </li>
        <div v-if="shouldShowLoadMore" class="text-center">
          <lf-link @click="getUsers">
            {{ $t("COMMON.LOAD_MORE") }}
          </lf-link>
        </div>
      </ul>
      <p v-else class="text-center pb-2">
        {{ $t("COMMON.NO_DATA_AVAILABLE") }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, watch } from "vue";
import type { IPaginatedResponse } from "@/models/common";
import type { IUser } from "@/models/users";
import { useField } from "vee-validate";
import { usePromiseWrapper } from "@/hooks/common";
import { useTeleportV2 } from "@/hooks/elements";
import { onClickOutside } from "@vueuse/core";
import IconUserSmall from "@/components/icons/IconUserSmall.vue";
import type { ContactUser } from "@/models/communicationLogs";

interface Props {
  value?: string | number | boolean | ContactUser;
  initialUserSelection?: IUser | ContactUser;
  placeholder?: string;
  valueType?: "id" | "user";
  name: string;
  searchTerm?: string;
  getPaginatedOptions: (
    ...params: Record<string, string | number>[]
  ) => Promise<IPaginatedResponse<IUser>>;
  avatarOnlyTriggerButton?: boolean;
  disabled?: boolean;
}
</script>
<script setup lang="ts">
import IconProfile from "@/components/icons/sidebar/IconProfile.vue";
import { formatUserFullName } from "@/helpers/formatting";

const props = defineProps<Props>();

const { value: inputValue } = useField(props.name, undefined, {
  initialValue: props.value
});

const dropdown = ref<HTMLDivElement | null>(null);
const search = ref("");
const showMenu = ref(false);
const paginatedData = ref<IUser[]>([]);
const selectedUser = ref<IUser | ContactUser | null>(
  props.initialUserSelection || null
);
const paginatedOptionsMeta = ref<{
  current_page: number;
  last_page: number | null;
}>({
  current_page: 0,
  last_page: null
});

const userOptions = computed(() => {
  const options: Record<string, IUser> = {};

  paginatedData.value.forEach((user) => {
    const id = user.id.toString();
    options[id] = user;
  });

  return options;
});

const shouldShowLoadMore = computed(() => {
  return (
    paginatedOptionsMeta.value.current_page !==
    paginatedOptionsMeta.value.last_page
  );
});

const fullName = computed(() => formatUserFullName(selectedUser.value));

useTeleportV2({
  target: dropdown,
  checkForOverflow: true,
  to: "#menus",
  offset: { top: 49 }
});

const handleSearchChange = (value: string, load = true) => {
  if (value === search.value) {
    return;
  }
  search.value = value;
  resetOptions();
  if (load) {
    getUsers();
  }
};

const handleUserChange = (user: IUser) => {
  selectedUser.value = user;
  if (props.valueType === "user") {
    inputValue.value = user;
  } else {
    inputValue.value = user.id;
  }
  showMenu.value = false;
  handleSearchChange("", false);
};

const toggleMenu = () => {
  if (props.disabled) {
    return;
  }
  showMenu.value = !showMenu.value;
  if (!paginatedOptionsMeta.value.current_page) {
    getUsers();
  }
};

const resetOptions = () => {
  paginatedData.value = [];
  paginatedOptionsMeta.value = {
    current_page: 0,
    last_page: null
  };
};

const { fetchWrapper: getUsers, loading } = usePromiseWrapper(async () => {
  if (!props.getPaginatedOptions) {
    return;
  }
  const { current_page, last_page } = paginatedOptionsMeta.value;
  if (current_page === last_page) {
    return;
  }

  const pageToGet = current_page + 1;
  const response: IPaginatedResponse<IUser> = await props.getPaginatedOptions({
    page: pageToGet,
    search: search.value || ""
  });

  paginatedData.value.push(...response.data);
  paginatedOptionsMeta.value.current_page = response.meta.current_page;
  paginatedOptionsMeta.value.last_page = response.meta.last_page;
  return response.data;
});

onClickOutside(dropdown, (event) => {
  if (!showMenu.value) {
    return;
  }
  event.stopPropagation();
  showMenu.value = false;
});
watch(inputValue, () => {
  if (!inputValue.value) {
    selectedUser.value = null;
  }
  if (
    (typeof inputValue.value === "number" &&
      inputValue.value === props.initialUserSelection?.id) ||
    (typeof inputValue.value === "object" &&
      inputValue.value?.id === props.initialUserSelection?.id)
  ) {
    selectedUser.value = props.initialUserSelection;
  }
});
</script>

import type {
  ContractBuilderViewParams,
  ContractTemplate,
  ContractTemplateV2,
  GetContractTemplateParams
} from "@/models/contractBuilder";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import { formatString } from "@/helpers/formatting";
import type { FileBase, IPaginatedResponse } from "@/models/common";

class ContractBuilderService {
  async getPlaceholders(): Promise<ContractTemplate[]> {
    const response = await axiosClient.get<{ data: ContractTemplate[] }>(
      endpoints.CONTRACTS.BASE
    );
    return response.data.data;
  }

  async deleteContract(id: ContractTemplate["id"]): Promise<ContractTemplate> {
    const response = await axiosClient.delete<{ data: ContractTemplate }>(
      formatString(endpoints.CONTRACTS.SINGLE, id)
    );
    return response.data.data;
  }

  async createContract(contract: Partial<ContractTemplate>) {
    const response = await axiosClient.post<{ data: ContractTemplate }>(
      formatString(endpoints.CONTRACTS.BASE),
      contract
    );
    return response.data.data;
  }

  async updateContract(contract: Partial<ContractTemplate>) {
    if (!contract.id) {
      return;
    }
    const response = await axiosClient.patch<{ data: ContractTemplate }>(
      formatString(endpoints.CONTRACTS.SINGLE, contract.id),
      contract
    );
    return response.data.data;
  }

  async duplicateContract(id: ContractTemplateV2["id"]) {
    const response = await axiosClient.post<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.DUPLICATE, id)
    );
    return response.data.data;
  }

  async getContractTemplates(payload: ContractBuilderViewParams) {
    const response = await axiosClient.get<
      IPaginatedResponse<ContractTemplateV2>
    >(formatString(endpoints.CONTRACT_TEMPLATES.BASE), { params: payload });
    return response.data;
  }

  async getContractTemplate(
    id: ContractTemplateV2["id"],
    params: GetContractTemplateParams
  ): Promise<ContractTemplateV2> {
    const response = await axiosClient.get<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.SINGLE, id),
      {
        params
      }
    );
    return response.data.data;
  }

  async deleteContractTemplate(
    id: ContractTemplateV2["id"]
  ): Promise<ContractTemplateV2> {
    const response = await axiosClient.delete<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.SINGLE, id)
    );
    return response.data.data;
  }

  async createContractTemplate(
    payload: Partial<ContractTemplateV2>
  ): Promise<ContractTemplateV2> {
    const response = await axiosClient.post<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.BASE),
      payload
    );

    return response.data.data;
  }

  async updateContractTemplate(payload: Partial<ContractTemplateV2>) {
    if (!payload.id) {
      return;
    }
    const response = await axiosClient.patch<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.SINGLE, payload.id),
      payload
    );
    return response.data.data;
  }

  async uploadContractTemplateFile(
    id: ContractTemplateV2["id"],
    file: File,
    additionalConfig: Record<string, unknown> = {}
  ): Promise<FileBase> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosClient.post<{ data: FileBase }>(
      formatString(endpoints.CONTRACT_TEMPLATES.UPLOAD_DOCUMENT, id),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },
        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async deleteContractTemplateFile(
    id: ContractTemplateV2["id"],
    fileId: number
  ): Promise<void> {
    if (!fileId) {
      return Promise.reject(new Error("File id is required"));
    }
    await axiosClient.delete<{ data: ContractTemplateV2 }>(
      formatString(endpoints.CONTRACT_TEMPLATES.DELETE_DOCUMENT, id, fileId)
    );
  }
}

const contractBuilderService = new ContractBuilderService();
export default contractBuilderService;

<template>
  <g clip-path="url(#clip0_7150_85767)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 6.78872C0 3.03941 2.98477 0 6.66667 0H9.33333C13.0152 0 16 3.03941 16 6.78872C16 10.538 13.0152 13.5774 9.33333 13.5774H6.66667C6.45422 13.5774 6.24409 13.5673 6.03672 13.5475L3.09992 15.94C2.92532 16.0822 2.66667 15.9556 2.66667 15.7279V12.2202C1.0474 10.9817 0 9.00974 0 6.78872ZM10.6667 5.43098C10.6667 6.18085 11.2637 6.78873 12.0001 6.78873C12.7364 6.78873 13.3334 6.18085 13.3334 5.43098C13.3334 4.68112 12.7364 4.07323 12.0001 4.07323C11.2637 4.07323 10.6667 4.68112 10.6667 5.43098ZM6.66684 5.43105C6.66684 6.18091 7.2638 6.78879 8.00018 6.78879C8.73656 6.78879 9.33352 6.18091 9.33352 5.43105C9.33352 4.68118 8.73656 4.0733 8.00018 4.0733C7.2638 4.0733 6.66684 4.68118 6.66684 5.43105ZM2.66667 5.43105C2.66667 6.18091 3.26362 6.78879 4 6.78879C4.73638 6.78879 5.33334 6.18091 5.33334 5.43105C5.33334 4.68118 4.73638 4.0733 4 4.0733C3.26362 4.0733 2.66667 4.68118 2.66667 5.43105Z"
      fill="currentColor"
    />
  </g>
  <defs>
    <clipPath id="clip0_7150_85767">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>

<template>
  <div
    class="border-white/10 relative space-y-2"
    :class="sidebarExpanded ? 'justify-left p-2 pb-3' : 'justify-center'"
  >
    <router-link
      :to="{ name: ROUTE_ACCOUNT }"
      :class="
        alreadyImpersonatingSomeone
          ? 'bg-[#9EC6F2] text-login-bg'
          : 'bg-login-bg text-surface-user-blue'
      "
      v-tooltip="{
        content: $t('SIDEBAR.PROFILE.TITLE'),
        onShow: () => !sidebarExpanded
      }"
      class="flex cursor-pointer relative focus:outline-none max-w-full group/sidebar py-2 overflow-hidden justify-center rounded"
      data-cy="impersonate-block-icon"
    >
      <icon-base
        :class="[
          sidebarExpanded ? 'ml-2' : '',
          !alreadyImpersonatingSomeone ? 'opacity-50' : ''
        ]"
        class="place-self-center shrink-0 basis-6"
        height="24"
        width="24"
        view-box="0 0 48 48"
        icon="profile"
      />

      <div
        v-if="sidebarExpanded"
        data-cy="impersonate-block-data"
        class="grow whitespace-nowrap overflow-hidden px-4 place-self-cente text-sm transition-colors duration-200"
      >
        <div class="flex shrink flex-col justify-between space-y-1 font-medium">
          <span
            v-tooltip="fullUserName"
            :class="!alreadyImpersonatingSomeone ? 'opacity-80' : ''"
            class="truncate"
            data-cy="impersonate-block-name"
          >
            {{ fullUserName }}
          </span>
          <span
            v-tooltip="{
              content: userRoles,
              onShow: () => !!user.roles.length
            }"
            class="text-xs font-normal truncate opacity-55"
            data-cy="impersonate-block-role"
          >
            {{ userRoles }}
          </span>
        </div>
      </div>
    </router-link>
    <div
      v-if="impersonateModalOpen"
      class="relative"
      v-on-click-outside="toggleModal"
    >
      <impersonate-modal
        class="absolute bottom-4"
        @impersonated="impersonateUser"
      />
    </div>
    <div
      v-if="sidebarExpanded && (isAdmin || alreadyImpersonatingSomeone)"
      :class="[
        alreadyImpersonatingSomeone
          ? 'bg-surface-user-blue/25'
          : 'bg-[#9EC6F2]',
        impersonateModalOpen ? 'text-login-bg' : 'bg-opacity-10 text-[#9DC4F0]'
      ]"
      class="text-xs text-center uppercase cursor-pointer rounded-5 font-semibold p-1"
    >
      <div
        v-if="alreadyImpersonatingSomeone"
        class="text-surface-user-blue"
        data-cy="impersonate-block-exit-button"
        @click="stopImpersonating"
      >
        {{ $t("COMMON.EXIT") }}
      </div>
      <div
        v-else
        data-cy="impersonate-block-select-button"
        @click.self="toggleModal"
      >
        {{ t("COMMON.IMPERSONATE") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import type { IUser } from "@/models/users";
import { formatUserFullName, getUserRoles } from "@/helpers/formatting";
import { ROUTE_ACCOUNT } from "@/router/routes";
import { SUPER_ADMIN, ADMIN } from "@/helpers/constants";
import { useImpersonate } from "@/hooks/auth";
import startCase from "lodash/startCase";

import ImpersonateModal from "@/components/sidebar/ImpersonateModal.vue";

const { sidebarExpanded = false } = defineProps<{
  sidebarExpanded: boolean;
}>();

const { getters, dispatch, commit } = useStore();
const { t } = useI18n();
const { alreadyImpersonatingSomeone, setImpersonateMessage } = useImpersonate();

const impersonateModalOpen = ref(false);

const user = computed<IUser>(() => getters["auth/user"]);

const userRoles = computed(() =>
  startCase(getUserRoles(user.value.roles)?.join(", "))
);

const isAdmin = computed(
  () =>
    user.value?.roles.includes(ADMIN) || user.value?.roles.includes(SUPER_ADMIN)
);

const fullUserName = computed(() => formatUserFullName(user.value));

const toggleModal = () => {
  impersonateModalOpen.value = !impersonateModalOpen.value;
};
const stopImpersonating = async () => {
  commit("setPreventGlobalMessage", true);
  await dispatch("auth/stopImpersonating", { canRedirect: false });
  setImpersonateMessage();
  window.location.assign(window.location.pathname);
  commit("resetHistoryStack", null, { root: true });
};

const impersonateUser = () => {
  setImpersonateMessage();
  impersonateModalOpen.value = false;
  window.location.assign(window.location.pathname);
};
</script>

<template>
  <lf-date-picker
    v-model="datesArray"
    class="date-filter h-6-5"
    :formatter="defaultDateFormatter"
    :date-placeholder="placeholder"
    hide-second-month
    @update:model-value="dateValue = $event"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouteQuery } from "@vueuse/router";
import { defaultDateFormatter } from "@/helpers/constants/dateTime";
import LfDatePicker from "@/components/ui/inputs/LfDatePicker.vue";
const props = withDefaults(
  defineProps<{
    queryKey?: string;
    placeholder: string;
  }>(),
  {
    queryKey: "task_due_date"
  }
);
const dateValue = useRouteQuery(props.queryKey);

const datesArray = computed(() => {
  if (!dateValue.value?.length) {
    return [];
  }
  if (Array.isArray(dateValue.value)) {
    return dateValue.value.map((date) => String(date));
  }
  return [String(dateValue.value)];
});
</script>

<style scoped>
.date-filter :deep() {
  #litepie {
    & > button {
      @apply bg-grey-hover border-grey-hover text-headline pl-2 h-6-5 py-0 leading-[24px];
    }
    & > div {
      @apply transform scale-[0.8] bottom-0 -left-15;
    }
    & .top-3-4 {
      @apply top-1-5 right-1-5 transform scale-[0.75];
    }
  }
}
</style>

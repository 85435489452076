<template>
  <lf-badge v-if="organizationDisplay">{{ organizationDisplay }}</lf-badge>
</template>

<script setup lang="ts">
import { type PropType, computed } from "vue";
import type { EmailTemplate } from "@/models/clients";
import { EmailTemplateVisibility } from "@/enums/emailCustomization";

const props = defineProps({
  model: {
    type: Object as PropType<EmailTemplate>,
    required: true
  }
});

const LENDFLOW = "Lendflow" as const;

const organizationDisplay = computed(() =>
  props.model?.visibility === EmailTemplateVisibility.Organizational &&
  !props.model?.client?.id &&
  props.model?.is_lendflow_template
    ? LENDFLOW
    : props.model?.client?.name || ""
);
</script>

<template>
  <div class="flex w-full justify-between items-center">
    <div class="flex items-center space-x-2">
      <icon-base
        :icon="iconInfo.icon"
        :class="iconInfo.color"
        :view-box="iconInfo.viewBox"
      />
      <span class="text-headline">
        {{ emailActivityStatusOptions[modelValue] }}
      </span>
    </div>
    <icon-base v-if="isSelected" :icon="IconSent" class="text-blue-500" />
  </div>
</template>
<script setup lang="ts">
import type { EMAIL_ACTIVITY_STATUSES } from "@/helpers/constants/emails";

import IconComplainedAt from "@/components/icons/email/IconComplainedAt.vue";
import IconBounced from "@/components/icons/email/IconBounced.vue";
import IconOpened from "@/components/icons/email/IconOpened.vue";
import IconSent from "@/components/icons/email/IconSent.vue";
import IconDelivered from "@/components/icons/email/IconDelivered.vue";
import IconLink from "@/components/icons/IconLink.vue";
import IconBase from "@/components/ui/IconBase.vue";

import { computed } from "vue";
import { emailActivityStatusOptions } from "@/helpers/constants/emails";
import type { Component } from "vue";

interface Props {
  modelValue: (typeof EMAIL_ACTIVITY_STATUSES)[number];
  selected: string[];
}

const options: Record<
  (typeof EMAIL_ACTIVITY_STATUSES)[number],
  { icon: Component; color?: string; viewBox?: string }
> = {
  complaint: {
    icon: IconComplainedAt,
    color: "text-red-500"
  },
  bounced: {
    icon: IconBounced,
    color: "text-yellow-500"
  },
  opened: {
    icon: IconOpened,
    color: "text-green-500"
  },
  delivered: {
    icon: IconDelivered,
    color: "text-blue-500"
  },
  sent: {
    icon: IconSent
  },
  links_clicked: {
    icon: IconLink,
    color: "text-primary",
    viewBox: "2 4 20 20"
  }
};

const props = defineProps<Props>();

const isSelected = computed(() => !!props.selected?.includes(props.modelValue));

const iconInfo = computed(() => options[props.modelValue]);
</script>

<template>
  <lf-dropdown
    class="priority-dropdown"
    :options="options"
    :icons="optionsIcons"
    name="task-priority-level"
    :placeholder="$t('COMMON.ALL')"
    :value="priorityValue"
    :custom-button-classnames="{ heightClassname: 'h-6-5' }"
    icons-use-base
    full-width
    hide-placeholder-when-value
    @change="priorityValue = $event"
  />
</template>

<script setup lang="ts">
import { computed, type Component } from "vue";
import { TaskPriorityLevels } from "@/enums/communicationLogs";
import { priorities } from "@/helpers/constants/communicationLogs";

import startCase from "lodash/startCase";
import { useRouteQuery } from "@vueuse/router";
import IconBullet from "@/components/icons/IconBullet.vue";

type OptionIcon = Record<
  string,
  { icon: string | Component; viewBox: string; class?: string }
>;

type Option = Record<string | TaskPriorityLevels, string>;

const priorityValue = useRouteQuery("task_priority_level");

const options = computed(() =>
  Object.entries(TaskPriorityLevels).reduce<Option>(
    (prev, [key, value]) => {
      prev[value] = startCase(key);
      return prev;
    },
    { "": "All" }
  )
);

const optionsIcons = computed(() => {
  const icons = priorities.reduce<OptionIcon>(
    (prev, curr) => {
      prev[curr.value] = {
        icon: curr.icon,
        viewBox: curr.viewBox,
        class: curr.class
      };
      return prev;
    },
    { "": { icon: IconBullet, viewBox: "-8 -8 25 25" } }
  );
  return icons;
});
</script>

<style scoped>
.priority-dropdown :deep() {
  > button {
    @apply bg-grey-hover border-grey-hover text-headline;
    & svg.rotate-180 {
      @apply text-primary;
    }
  }
  #exp_elem_list li {
    @apply flex items-center justify-center px-0 cursor-pointer;
    &:hover,
    &.bg-gray-100 {
      @apply bg-white !important;
    }
    span:not(.icon) {
      @apply hidden;
    }
    span.icon {
      @apply flex items-center justify-center w-6 h-6 rounded-full bg-grey-hover;
    }
  }
}
.priority-dropdown.open :deep(.absolute.bg-white) {
  @apply w-[226px];
}
.priority-dropdown.open :deep(#exp_elem_list) {
  @apply flex items-center space-x-1 w-[226px];
}
</style>

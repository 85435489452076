<template>
  <div
    v-show="filtersVisible"
    class="rounded shadow-main bg-white p-2 gap-2 flex items-stretch flex-wrap activity-hub-filters"
  >
    <div v-if="isActivityHubPage" class="w-1/5 max-w-36">
      <filter-by-deals data-cy="deals-filter" />
    </div>
    <div v-if="!showMessageableFilters" class="w-1/5 max-w-40">
      <filter-by-assignee
        data-cy="users-filter"
        placeholder-key="ACTIVITY.FILTERS.ASSIGNEE.PLACEHOLDER"
      >
        {{ $t("ACTIVITY.FILTERS.ASSIGNEE.TITLE") }}
      </filter-by-assignee>
    </div>
    <div v-if="showTasksFilters" class="grow max-w-[250px]">
      <filter-by-date
        :placeholder="$t('ACTIVITY.FILTERS.DUE_DATE')"
        data-cy="date-filter"
      />
    </div>
    <div v-if="showCallsFilters" class="grow max-w-[250px]">
      <filter-by-date
        data-cy="call-date-filter"
        :placeholder="$t('ACTIVITY.FILTERS.CALLS.DATE')"
        query-key="call_session_created_at"
      />
    </div>
    <div
      v-if="!showMessageableFilters && !isCurrentTabCalls"
      class="grow max-w-[200px]"
    >
      <filter-by-priority data-cy="priority-filter" />
    </div>
    <div v-if="showCallsFilters" class="filter-item">
      <filter-by-assignee
        data-cy="callers-filter"
        query-key="call_session_caller_ids"
        placeholder-key="ACTIVITY.FILTERS.CALLS.PLACEHOLDER"
      >
        {{ $t("ACTIVITY.FILTERS.CALLS.CALLER") }}
      </filter-by-assignee>
    </div>
    <div v-if="showTasksFilters" class="w-1/6 xl:grow max-w-[150px]">
      <filter-by-status data-cy="status-filter" />
    </div>
    <div v-if="showMessageableFilters" class="filter-item">
      <filter-by-assignee
        data-cy="sender-filter"
        query-key="sender_ids"
        placeholder-key="ACTIVITY.FILTERS.EMAILS.SENDER_PLACEHOLDER"
      >
        {{ $t("ACTIVITY.FILTERS.EMAILS.SENDER") }}
      </filter-by-assignee>
    </div>
    <template v-if="isCurrentTabEmail">
      <div class="filter-item">
        <filter-by-assignee
          data-cy="recipient-filter"
          query-key="recipient_ids"
          type="email_recipient"
          placeholder-key="ACTIVITY.FILTERS.EMAILS.RECIPIENT_PLACEHOLDER"
        >
          {{ $t("ACTIVITY.FILTERS.EMAILS.RECIPIENT") }}
        </filter-by-assignee>
      </div>
      <div class="grow max-w-[250px]">
        <filter-by-date
          :placeholder="$t('ACTIVITY.FILTERS.EMAILS.DATE')"
          data-cy="email-date-filter"
          query-key="created_at"
        />
      </div>
      <filter-by-messageable-status class="filter-email-item" mode="email" />
      <filter-by-email-type class="filter-email-item" />
    </template>
    <template v-if="isCurrentTabSms">
      <div class="grow max-w-[250px]">
        <filter-by-date
          :placeholder="$t('ACTIVITY.FILTERS.SMS.DATE')"
          data-cy="sms-date-filter"
          query-key="sms_created_at"
        />
      </div>
      <filter-by-messageable-status class="filter-sms-item" mode="sms" />
      <filter-by-sms-type class="filter-sms-item" />
    </template>
    <filter-by-email-automation
      v-if="!currentTab && auth.isLendflowUser"
      class="filter-item"
      data-cy="automation-filter"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useAuth } from "@/hooks/auth";
import { useCommunicationLogs } from "@/hooks/communicationLogs";
import { CommunicationType } from "@/enums/communicationLogs";

import FilterByDeals from "@/components/communicationLogs/filters/FilterByDeals.vue";
import FilterByAssignee from "@/components/communicationLogs/filters/FilterByAssignee.vue";
import FilterByDate from "@/components/communicationLogs/filters/FilterByDate.vue";
import FilterByStatus from "@/components/communicationLogs/filters/FilterByStatus.vue";
import FilterByPriority from "@/components/communicationLogs/filters/FilterByPriority.vue";
import FilterByMessageableStatus from "@/components/communicationLogs/filters/messageableFilters/FilterByMessageableStatus.vue";
import FilterByEmailType from "@/components/communicationLogs/filters/emailFilters/FilterByEmailType.vue";
import FilterByEmailAutomation from "@/components/communicationLogs/filters/emailFilters/FilterByEmailAutomation.vue";
import FilterBySmsType from "@/components/communicationLogs/filters/smsFilters/FilterBySmsType.vue";

defineProps({
  filtersVisible: {
    type: Boolean,
    default: true
  }
});

const route = useRoute();
const auth = useAuth();
const {
  isActivityHubPage,
  isCurrentTabEmail,
  isCurrentTabSms,
  isCurrentTabCalls
} = useCommunicationLogs();

const currentTab = computed(() => route.query?.type);

const showTasksFilters = computed(
  () => !currentTab.value || currentTab.value === CommunicationType.task
);
const showCallsFilters = computed(
  () => !currentTab.value || currentTab.value === CommunicationType.call
);

const showMessageableFilters = computed(
  () => isCurrentTabEmail.value || isCurrentTabSms.value
);
</script>

<style scoped>
@import "@/components/communicationLogs/filters/filtersStyles.css";
.filter-item {
  @apply grow w-1/6 min-w-[190px] max-w-[210px];
}

.filter-email-item,
.filter-sms-item {
  @apply w-1/6 min-w-[220px] max-w-[250px];
}
</style>

import type { FinancialProduct, FinProductNum } from "@/models/common";
import type {
  ApplicationStage,
  BusinessTransportationEntry,
  EquipmentDetailsEntry,
  FinProduct,
  IWorkflowStatus,
  StageCategoryType
} from "@/models/workflows";
import {
  PHASE_APPLICATION,
  PHASE_CLOSING,
  PHASE_FUNDED,
  PHASE_UNDERWRITING,
  PRODUCT_TYPE,
  PRODUCT_TYPE_ARLOC,
  PRODUCT_TYPE_EQUIPMENT_FINANCING,
  PRODUCT_TYPE_LINE_OF_CREDIT,
  PRODUCT_TYPE_RECEIVABLE_PURCHASE,
  PRODUCT_TYPE_TERM_LOAN,
  PRODUCT_TYPE_ERC
} from "@/helpers/constants";
import toLower from "lodash/toLower";

export const WORKFLOW_SCHEMA_VERSION = "2.53.0" as const;

export const WORKFLOW_BLOCKS_IDS = {
  discovery_questions: "discovery",
  prequalification: "prequal",
  personal_info: "personal",
  business_info: "business",
  bonding_agent: "bonding_agent",
  insurance: "insurance",
  business_notes: "business_notes",
  additional_business_info: "additional_business",
  funding: "funding",
  company_officers: "company_officers",
  guarantor: "guarantor",
  affiliated_additional_businesses:
    "affiliated_businesses_additional_information",
  affiliated_businesses: "affiliated_businesses",
  additinal_owner: "additional_owner",
  accounts_payable: "accounts_payable",
  signed_application: "signed_application",
  bank_data: "bank_data_plaid",
  affiliated_individual: "affiliated_individuals",
  affiliated_individual_additional:
    "affiliated_individuals_additional_information",
  company_debts: "company_debts",
  trade_references: "trade_references",
  stips: "stips",
  customer_information: "customer_information",
  custom_attributes: "custom_attributes",
  bank_data_moneythumb: "bank_data_moneythumb",
  bank_data_plaid: "bank_data_plaid",
  bank_data_ocrolus: "bank_data_ocrolus",
  equipment_details: "equipment_details",
  business_transportation_information: "business_transportation_information"
} as const;

export const WORKFLOW_STAGE_TYPES = {
  UNDERWRITING: "underwriting"
} as const;

export const BUSINESS_INFO_FIELD_IDS = {
  legal_name: "business_legal_name",
  dba: "business_dba",
  stated_monthly_revenue: "business_stated_monthly_revenue",
  entity_type: "business_entity_type",
  start_date: "business_start_date",
  state_of_incorporation: "business_state_of_incorporation",
  ein: "business_ein",
  bn: "business_bn",
  address: "business_addresses",
  phone_numbers: "business_phone_numbers"
} as const;

export const PERSONAL_INFO_FIELD_IDS = {
  address: "personal_addresses",
  credit_permission: "personal_credit_permission",
  drivers_license_number: "personal_drivers_license_number",
  drivers_license_State: "personal_drivers_license_state",
  ein: "personal_ein",
  email: "personal_email_address",
  first_name: "personal_first_name",
  last_name: "personal_last_name",
  ownership_percentage: "personal_ownership_percentage",
  ssn_itin: "personal_ssn_itin",
  sin: "personal_sin",
  telephone: "personal_telephone",
  birth_date: "personal_date_of_birth"
} as const;

export const PREQUAL_INFO_FIELD_IDS = {
  requested_amount: "prequal_requested_amount",
  avg_revenue: "prequal_monthly_avg_revenue",
  time_in_business: "prequal_time_in_business",
  prequal_range: "prequal_prequalification_range",
  credit_score: "prequal_credit_score"
} as const;

export const STAGE_CATEGORY_TYPES: Record<ApplicationStage, StageCategoryType> =
  {
    application: "Collect Data",
    underwriting: "Data Enrichment",
    scorecards: "Scorecards",
    placement: "Placement",
    offer: "Offer",
    closing: "Closing",
    funded: "Complete"
  };

export const INSURANCE_FIELD_IDS = {
  insurance_certificate: "insurance_certificate",
  insurance_address: "insurance_address",
  insurance_type: "insurance_type",
  insurance_company: "insurance_company",
  insurance_email_address: "insurance_email_address",
  insurance_first_name: "insurance_first_name",
  insurance_last_name: "insurance_last_name",
  insurance_has_rental_protection: "insurance_has_rental_protection",
  insurance_telephone: "insurance_telephone"
} as const;

export const BONDING_AGENT_FIELD_IDS = {
  agent_address: "bonding_agent_address",
  agent_email: "bonding_agent_email_address",
  agent_first_name: "bonding_agent_first_name",
  agent_last_name: "bonding_agent_last_name",
  agent_company: "bonding_agent_company_name",
  agent_phone: "bonding_agent_telephone"
} as const;

export const ADDITIONAL_BUSINESS_FIELD_IDS = {
  NAICS_code: "additional_business_NAICS_code",
  experian_bin: "additional_business_experian_bin",
  DUNS_number: "additional_business_duns_number",
  SIC_code: "additional_business_SIC_code",
  business_management_software:
    "additional_business_business_management_software",
  fiscal_year_end: "additional_business_fiscal_year_end",
  industry: "additional_business_industry",
  number_of_employees: "additional_business_number_of_employees",
  sub_industry: "additional_business_sub_industry",
  website: "additional_business_website",
  industry_details: "additional_business_industry_other",
  w2_number_of_employees_2020:
    "additional_business_w2_number_of_employees_2020",
  w2_number_of_employees_2021:
    "additional_business_w2_number_of_employees_2021",
  current_business_debt: "additional_business_current_business_debt",
  custom_field: "additional_business_custom_field",
  stated_average_balance: "additional_business_stated_average_balance",
  external_naics_description: "additional_business_external_NAICS_description",
  external_sic_description: "additional_business_external_SIC_description",
  dot_number: "additional_business_dot_number",
  operating_expense: "additional_business_operating_expense",
  stated_annual_revenue: "additional_business_stated_annual_revenue",
  business_description: "additional_business_business_description",
  business_experience: "additional_business_business_experience",
  incomplete_email: "additional_business_incomplete_email",
  incomplete_phone: "additional_business_incomplete_phone",
  stated_monthly_revenue: "additional_business_stated_monthly_revenue",
  use_of_funds: "additional_business_use_of_funds",
  use_of_funds_description: "additional_business_use_of_funds_description",
  stated_industry: "additional_business_stated_industry",
  written_business_plan: "additional_business_written_business_plan",
  financial_projections: "additional_business_financial_projections",
  generating_revenue: "additional_business_generating_revenue",
  veteran: "additional_business_veteran",
  franchise: "additional_business_franchise",
  stated_number_of_employees: "additional_business_stated_number_of_employees",
  involved_in_bankruptcy: "additional_business_involved_in_bankruptcy"
} as const;

export const FUNDING_INFO_FIELD_IDS = {
  annual_revenue: "funding_estimated_annual_revenue",
  average_monthly_revenue: "funding_actual_average_monthly_revenue",
  when_funds_needed: "funding_when_funds_needed",
  amount_requested: "funding_amount_requested",
  use_of_funds: "funding_use_of_funds"
} as const;

export const COMPANY_OFFICERS_FIELD_IDS = {
  address: "company_officers_address",
  country_of_citizenship: "company_officers_country_of_citizenship",
  dob: "company_officers_dob",
  email: "company_officers_email",
  first_name: "company_officers_first_name",
  last_name: "company_officers_last_name",
  officer_title: "company_officers_officer_title",
  ssn: "company_officers_ssn",
  telephone: "company_officers_phone_number"
} as const;

export const GUARANTOR_FIELD_IDS = {
  first_name: "guarantor_first_name",
  last_name: "guarantor_last_name",
  dob: "guarantor_dob",
  ssn: "guarantor_ssn",
  email_address: "guarantor_email_address",
  telephone: "guarantor_telephone",
  address: "guarantor_address"
} as const;

export const AFFILIATED_ADDITIONAL_BUSINESS_INFO_FIELDS_IDS = {
  fiscal_year_end:
    "affiliated_businesses_additional_information_fiscal_year_end",
  industry_type_id: "affiliated_businesses_additional_information_industry",
  sub_industry_type_id:
    "affiliated_businesses_additional_information_sub_industry",
  sic_code: "affiliated_businesses_additional_information_SIC_code",
  naics_code: "affiliated_businesses_additional_information_NAICS_code",
  experian_bin: "affiliated_businesses_additional_information_experian_bin",
  duns_number: "affiliated_businesses_additional_information_duns_number",
  website_address: "affiliated_businesses_additional_information_website",
  number_of_employees_id:
    "affiliated_businesses_additional_information_number_of_employees",
  business_management_software:
    "affiliated_businesses_additional_information_business_management_software",
  industry_details:
    "affiliated_businesses_additional_information_industry_other",
  w2_number_of_employees_2020:
    "affiliated_businesses_additional_information_w2_number_of_employees_2020",
  w2_number_of_employees_2021:
    "affiliated_businesses_additional_information_w2_number_of_employees_2021",
  current_business_debt:
    "affiliated_businesses_additional_information_current_business_debt",
  custom_field: "affiliated_businesses_additional_information_custom_field",
  dot_number: "affiliated_businesses_additional_information_dot_number"
} as const;

export const AFFILITED_BUSINESS_INFO_FIELD_IDS = {
  legal_name: "affiliated_businesses_legal_name",
  dba: "affiliated_businesses_dba",
  stated_monthly_revenue: "affiliated_businesses_stated_monthly_revenue",
  entity_type: "affiliated_businesses_entity_type",
  start_date: "affiliated_businesses_start_date",
  state_of_incorporation: "affiliated_businesses_state_of_incorporation",
  ein: "affiliated_businesses_ein",
  address: "affiliated_businesses_addresses",
  phone_numbers: "affiliated_businesses_phone_numbers"
} as const;

export const WORKFLOW_TO_APP_STATUS = {
  application: 0,
  underwriting: 1,
  placement: 2,
  offer: 3,
  closing: 4,
  funded: 5,
  scorecards: 9
};
export const ADDITIONAL_OWNER_INFO_FIELD_IDS = {
  actual_credit_score: "additional_owner_actual_credit_score",
  credit_limit: "additional_owner_credit_limit",
  credit_permission: "additional_owner_credit_permission",
  credit_utilization: "additional_owner_credit_utilization",
  inquiries_6_months: "additional_owner_inquiries_6_months",
  legal_immigration_status: "additional_owner_legal_immigration_status",
  personal_income: "additional_owner_personal_income",
  primary_language_spoken: "additional_owner_primary_language_spoken",
  stated_credit_score: "additional_owner_stated_credit_score",
  total_debt: "additional_owner_total_debt",
  country_of_citizenship: "additional_owner_country_of_citizenship"
} as const;

export const ACCOUNTS_PAYABLE_FIELD_IDS = {
  email_address: "accounts_payable_email_address",
  first_name: "accounts_payable_first_name",
  last_name: "accounts_payable_last_name",
  payment_type: "accounts_payable_payment_type",
  phone_number: "accounts_payable_phone_number",
  preferred_contact_method: "accounts_payable_preferred_contact_method",
  preferred_invoicing_method: "accounts_payable_preferred_invoicing_method",
  tax_exempt: "accounts_payable_tax_exempt"
} as const;

export const HIDDEN_WFB_FIELDS = ["personal_is_primary"];

export const STAGE_GROUP_TYPE = {
  WITH_BLOCKS: "with_blocks",
  WITHOUT_BLOCKS: "without_blocks"
};

export const PRODUCT_TYPE_MAPPING: Record<FinProductNum, FinProduct> = {
  1: "arloc",
  2: "line_of_credit",
  3: "receivables_purchase",
  4: "term_loan",
  5: "equipment_financing",
  9: "erc"
};

export const PRODUCT_TYPE_REVERSE_MAPPING: Record<FinProduct, FinProductNum> = {
  arloc: PRODUCT_TYPE_ARLOC,
  line_of_credit: PRODUCT_TYPE_LINE_OF_CREDIT,
  receivables_purchase: PRODUCT_TYPE_RECEIVABLE_PURCHASE,
  term_loan: PRODUCT_TYPE_TERM_LOAN,
  equipment_financing: PRODUCT_TYPE_EQUIPMENT_FINANCING,
  erc: PRODUCT_TYPE_ERC
};

export const PRODUCT_TYPE_TITLE_MAPPING: Record<FinProduct, FinancialProduct> =
  {
    arloc: PRODUCT_TYPE.ARLOC,
    line_of_credit: PRODUCT_TYPE.LINE_OF_CREDIT,
    receivables_purchase: PRODUCT_TYPE.RECEIVABLES_PURCHASE,
    term_loan: PRODUCT_TYPE.TERM_LOAN,
    equipment_financing: PRODUCT_TYPE.EQUIPMENT_FINANCING,
    erc: PRODUCT_TYPE.ERC
  };

export const PRODUCT_TYPES_IDS: Record<FinProduct, FinProduct> = {
  arloc: "arloc",
  line_of_credit: "line_of_credit",
  receivables_purchase: "receivables_purchase",
  term_loan: "term_loan",
  equipment_financing: "equipment_financing",
  erc: "erc"
} as const;

export const AFFILIATED_INDIVIDUAL_FIELD_IDS = {
  id: "affiliated_individual_id",
  business_id: "affiliated_business_id",
  first_name: "affiliated_individuals_first_name",
  last_name: "affiliated_individuals_last_name",
  drivers_license_number: "affiliated_individuals_drivers_license_number",
  drivers_license_state: "affiliated_individuals_drivers_license_state",
  date_of_birth: "affiliated_individuals_date_of_birth",
  telephone: "affiliated_individuals_telephone",
  email_address: "affiliated_individuals_email_address",
  ssn_itin: "affiliated_individuals_ssn_itin",
  addresses: "affiliated_individuals_addresses"
} as const;

export const AFFILIATED_INDIVIDUAL_ADDITIONAL_FIELD_IDS = {
  id: "affiliated_individuals_additional_information_id",
  credit_permission:
    "affiliated_individuals_additional_information_credit_permission",
  total_debt: "affiliated_individuals_additional_information_total_debt",
  credit_limit: "affiliated_individuals_additional_information_credit_limit",
  stated_credit_score:
    "affiliated_individuals_additional_information_stated_credit_score",
  actual_credit_score:
    "affiliated_individuals_additional_information_actual_credit_score",
  credit_utilization:
    "affiliated_individuals_additional_information_credit_utilization",
  inquiries_6_months:
    "affiliated_individuals_additional_information_inquiries_6_months",
  personal_income:
    "affiliated_individuals_additional_information_personal_income",
  legal_immigration_status:
    "affiliated_individuals_additional_information_legal_immigration_status",
  primary_language_spoken:
    "affiliated_individuals_additional_information_primary_language_spoken"
} as const;

export const TRADE_REFERENCE_FIELD_IDS = {
  id: "trade_reference_id",
  business_name: "trade_references_business_name",
  business_phone: "trade_references_business_telephone",
  business_email: "trade_references_business_email",
  business_address: "trade_references_business_address",
  business_contact_first_name: "trade_references_business_contact_first_name",
  business_contact_last_name: "trade_references_business_contact_last_name",
  credit_term: "trade_references_credit_terms",
  date_account_opened: "trade_references_date_account_opened",
  open_ar_balance: "trade_references_open_ar_balance",
  past_due_balance: "trade_references_past_due_balance",
  highest_previous_balance: "trade_references_highest_previous_balance",
  days_beyond_terms: "trade_references_days_beyond_terms",
  number_of_late_payments: "trade_references_number_of_late_payments",
  credit_limit: "trade_references_credit_limit",
  number_of_credit_transactions:
    "trade_references_number_of_credit_transactions",
  date_of_last_transaction: "trade_references_date_of_last_transaction"
} as const;

export const COMPANY_DEBTS_FIELDS_IDS = {
  id: "debt_id",
  balance_owned: "company_debts_balance_owned",
  lender_limit: "company_debts_lender_limit",
  lender_name: "company_debts_lender_name",
  maturity_date: "company_debts_maturity_date",
  type_of_debt: "company_debts_type_of_debt",
  term_length: "company_debts_term_length"
} as const;

export const CUSTOMER_INFORMATION_FIELDS_IDS = {
  id: "debtor_id",
  business_name: "customer_information_business_name",
  amount: "customer_information_amount",
  payment_term: "customer_information_payment_term"
} as const;

export const STAGE_MANAGEMENT_OPTIONS = {
  1: "Lendflow",
  2: "Client"
} as const;

export const defaultWfbLiveStatuses: IWorkflowStatus[] = [
  {
    name: "Default live status",
    live: true,
    color: "#888e9d"
  }
];

export const defaultWfbDeadStatuses: IWorkflowStatus[] = [
  {
    name: "Default dead status",
    live: false
  }
];

export const defaultWfbStatuses: IWorkflowStatus[] = [
  ...defaultWfbLiveStatuses,
  ...defaultWfbDeadStatuses
];

export const MULTI_RECORD_BLOCKS = [
  "additional_owner",
  "affiliated_businesses",
  "affiliated_businesses_additional_information",
  "affiliated_individuals",
  "affiliated_individuals_additional_information",
  "company_debts",
  "company_officers",
  "customer_information",
  "personal",
  "trade_references"
];

export const PHASES_WITH_TABS = [
  PHASE_APPLICATION,
  PHASE_UNDERWRITING,
  PHASE_CLOSING,
  PHASE_FUNDED
].map(toLower);

export const EQUIPMENT_DETAILS_FIELDS: EquipmentDetailsEntry[] = [
  {
    id: "equipment_details_equipment_location_address",
    titleKey: "DEALS.EQUIPMENT_DETAILS.ADDRESS"
  },
  {
    id: "equipment_details_model",
    titleKey: "DEALS.EQUIPMENT_DETAILS.MODEL"
  },
  {
    id: "equipment_details_serial_number",
    titleKey: "DEALS.EQUIPMENT_DETAILS.SERIAL_NUMBER"
  },
  {
    id: "equipment_details_cost",
    titleKey: "DEALS.EQUIPMENT_DETAILS.COST",
    currency: true
  },
  {
    id: "equipment_details_year",
    titleKey: "DEALS.EQUIPMENT_DETAILS.YEAR"
  },
  {
    id: "equipment_details_miles_hours",
    titleKey: "DEALS.EQUIPMENT_DETAILS.MILES_HOURS"
  },
  {
    id: "equipment_details_seller",
    titleKey: "DEALS.EQUIPMENT_DETAILS.SELLER"
  },
  {
    id: "equipment_details_make",
    titleKey: "DEALS.EQUIPMENT_DETAILS.MAKE"
  },
  {
    id: "equipment_details_vendor",
    titleKey: "DEALS.EQUIPMENT_DETAILS.VENDOR"
  },
  {
    id: "equipment_details_equipment_description",
    titleKey: "DEALS.EQUIPMENT_DETAILS.DESCRIPTION"
  },
  {
    id: "equipment_details_url_link",
    titleKey: "DEALS.EQUIPMENT_DETAILS.LINK"
  },
  {
    id: "equipment_details_total_financing_request",
    titleKey: "DEALS.EQUIPMENT_DETAILS.TOTAL_FINANCING_REQUEST",
    currency: true
  },
  {
    id: "equipment_details_requested_term",
    titleKey: "DEALS.EQUIPMENT_DETAILS.REQUESTED_TERM"
  },
  {
    id: "equipment_details_new_or_used",
    titleKey: "DEALS.EQUIPMENT_DETAILS.NEW_OR_USED"
  }
];

export const BUSINESS_TRANSPORTATION_FIELDS: BusinessTransportationEntry[] = [
  {
    id: "business_transportation_information_authority_status_broker",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.AUTHORITY_STATUS_BROKER"
  },
  {
    id: "business_transportation_information_authority_status_common",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.AUTHORITY_STATUS_COMMON"
  },
  {
    id: "business_transportation_information_usdot_number",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.USDOT_NUMBER"
  },
  {
    id: "business_transportation_information_entity_type",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.ENTITY_TYPE"
  },
  {
    id: "business_transportation_information_insurance_on_file",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.INSURANCE_ON_FILE"
  },
  {
    id: "business_transportation_information_insurance_required",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.INSURANCE_REQUIRED"
  },
  {
    id: "business_transportation_information_power_units",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.POWER_UNITS"
  },
  {
    id: "business_transportation_information_usdot_status",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.USDOT_STATUS"
  },
  {
    id: "business_transportation_information_cargo_carried",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.CARGO_CARRIED",
    dataType: "array"
  },
  {
    id: "business_transportation_information_operation_classification",
    titleKey: "DEALS.BUSINESS_TRANSPORTATION.OPERATION_CLASSIFICATION",
    dataType: "array"
  }
];

<template>
  <div>
    <div
      class="flex items-center space-x-4 text-headline justify-between border-b p-3"
    >
      <div class="flex space-x-4 pl-3">
        <lf-checkbox
          v-if="isMultiple"
          :id="`dsd-option-${index}`"
          :model-value="convertToArray(activeDsdsProxy)"
          :name="detailName"
          :value="detailName"
          :disabled="disabled"
          @update:model-value="activeDsdsProxy = $event"
        />
        <div
          v-else
          class="flex items-center space-x-1-5"
          :class="{ '-ml-5-5': showWarningIcon }"
        >
          <icon-base
            v-if="showWarningIcon"
            :icon="IconWarning"
            class="warning-icon"
            icon-color="orange"
          />
          <input
            :id="`dsd-option-${index}`"
            type="radio"
            name="dsd"
            class="form-radio cursor-pointer"
            :checked="isDetailSelected"
            :disabled="disabled"
            @click="activeDsdsProxy = [detailName]"
          />
        </div>
        <label :for="`dsd-option-${index}`" class="break-all">
          {{ detailName }}
        </label>
      </div>
      <button
        v-if="!needle"
        class="cursor-pointer p-2"
        data-cy="detail-section-button"
        @click="isSectionOpen = !isSectionOpen"
      >
        <icon-base
          :icon="IconChevronUp"
          class="text-gray-500"
          :class="{
            'scale-y-[-1]': !isSectionOpen
          }"
        />
      </button>
    </div>
    <slot
      v-if="isDetailSelected && !needle && isSectionOpen"
      name="additional-content"
    ></slot>
    <div
      v-if="needle || isSectionOpen"
      class="p-5 space-y-6"
      data-cy="dsd-attributes"
    >
      <div class="flex flex-col space-y-3">
        <no-data-found v-if="!filteredItems.length" />
        <lf-checkbox
          v-for="attribute in filteredItems"
          :key="attribute.id"
          class="p-1 hover:bg-gray-100 flex items-center space-x-2"
          label-class="w-full"
          :value="attribute.id"
          :name="attribute.id"
          :disabled="disabled"
          :model-value="selectedAttributesIds"
          @update:model-value="handleSave"
        >
          <highlight-text :content="attribute.name" :needle="needle" />
        </lf-checkbox>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { IAttribute } from "@/models/orchestration";
import { computed, ref, watch } from "vue";
import { useFuse } from "@vueuse/integrations/useFuse";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconWarning from "@/components/icons/IconWarning.vue";
import IconChevronUp from "@/components/icons/deals/IconChevronDown.vue";
import HighlightText from "@/components/HighlightText.vue";
import NoDataFound from "@/views/profile/components/dataOrchestration/configPanel/NoDataFound.vue";
import { FUZZY_SEARCH_THRESHOLD_DEFAULT } from "@/helpers/constants";
import { convertToArray } from "@/helpers/common";

const emit = defineEmits<{
  "update:active-dsds": [string[]];
  "update:selected-attributes": [IAttribute["id"][]];
}>();

const props = defineProps<{
  activeDsds: string[];
  index: string;
  detailName: string;
  isMultiple: boolean;
  disabled: boolean;
  needle: string;
  attributes: IAttribute[];
  selectedAttributesIds: IAttribute["id"][];
}>();

const isSectionOpen = ref(false);

const isDetailSelected = computed(() =>
  props.activeDsds.includes(props.detailName)
);

const activeDsdsProxy = computed({
  get() {
    return props.activeDsds;
  },
  set(value: string[]) {
    emit("update:active-dsds", convertToArray(value));
  }
});

const showWarningIcon = computed(() => {
  if (!props.attributes) {
    return false;
  }
  return [
    "experian_bop_commercial_collections",
    "experian_bop_commercial_collections_premier_attributes",
    "experian_bop_commercial_collections_pdf_report",
    "experian_bop_commercial_lending_to_a_sole_prop",
    "experian_bop_commercial_lending_to_a_sole_prop_premier_attributes",
    "experian_bop_commercial_lending_to_a_sole_prop_pdf_report"
  ].includes(props.attributes[0]?.service_id || "");
});

const { results } = useFuse(
  () => props.needle,
  () => props.attributes,
  {
    fuseOptions: {
      keys: ["name"],
      threshold: FUZZY_SEARCH_THRESHOLD_DEFAULT
    },
    matchAllWhenSearchEmpty: true
  }
);

const filteredItems = computed(() => results.value.map(({ item }) => item));

const handleSave = (attributes: IAttribute["id"][]) => {
  emit("update:selected-attributes", attributes);
};

watch(
  isDetailSelected,
  (value) => {
    isSectionOpen.value = value;
  },
  { immediate: true }
);
</script>

<style scoped>
svg.warning-icon {
  margin-bottom: 0;
}
</style>
